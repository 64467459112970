/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import notFound from '../views/not-found.vue'
//Business
import businessMain from '../views/business.vue'
import businessLexcloud from '../views/business-lexcloud.vue'
import businessCatX from '../views/business-catx.vue'
import businessJL from '../views/business-jl.vue'
import businessBavl from '../views/business-bavl.vue'
import businessTC from '../views/business-tc.vue'
// Technology
import technologyATPE from '../views/technology-atpe.vue'
import technologyNLP from '../views/technology-nlp.vue'
import technologyDev from '../views/technology-dev.vue'
import reportDetail from '../views/report-document.vue'
import reportEditor from '../views/report-editor.vue'
import reportUpdate from '../views/report-update.vue'
// Relations
import relationsStories from '../views/relations-stories.vue'
import relationsPartners from '../views/relations-partners.vue'
import relationsTranslator from '../views/relations-translator.vue'
import relationsRecruit from '../views/relations-recruit.vue'
// Company
import companyAll from '../views/companyAll.vue'
import companyPH from '../views/companyPH.vue'
// Hidden Login
import hiddenLogin from '../views/hidden-login.vue'
//Newsletter
import relationsStoriesDetail from '../views/newsletter-document.vue'
import NewsletterEditor from '../views/newsletter-editor.vue'
import NewsletterEditorUpdate from '../views/newsletter-update.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'HOME'
    }
  },
  {
    path: '/business',
    name: 'businessMain',
    component: businessMain,
    meta: {
      title: 'BUSINESS'
    }
  },
  {
    path: '/business/lex-cloud',
    name: 'businessLexcloud',
    component: businessLexcloud,
    meta: {
      title: 'BUSINESS'
    }
  },
  {
    path: '/business/cat-x',
    name: 'businessCatX',
    component: businessCatX,
    meta: {
      title: 'BUSINESS'
    }
  },
  {
    path: '/business/journallab',
    name: 'businessJL',
    component: businessJL,
    meta: {
      title: 'BUSINESS'
    }
  },
  {
    path: '/business/bavl',
    name: 'businessBavl',
    component: businessBavl,
    meta: {
      title: 'BUSINESS'
    }
  },
  {
    path: '/business/tc',
    name: 'businessTC',
    component: businessTC,
    meta: {
      title: 'BUSINESS'
    }
  },
  {
    path: '/technology/atpe',
    name: 'technologyATPE',
    component: technologyATPE,
    meta: {
      title: 'TECHNOLOGY'
    }
  },
  {
    path: '/technology/deepread',
    name: 'technologyNLP',
    component: technologyNLP,
    meta: {
      title: 'TECHNOLOGY'
    }
  },
  {
    path: '/technology/dev',
    name: 'technologyDev',
    component: technologyDev,
    meta: {
      title: 'TECHNOLOGY'
    }
  },
  {
    path: '/relations/stories',
    name: 'relationsStories',
    component: relationsStories,
    meta: {
      title: 'RELATIONS'
    }
  },
  {
    path: '/relations/stories/:id',
    name: 'relationsStoriesDetail',
    component: relationsStoriesDetail,
    props: true,
    meta: {
      title: 'RELATIONS'
    }
  },
  {
    path: '/relations/stories/newsletter-editor/new',
    name: 'NewsletterEditor',
    component: NewsletterEditor,
    meta: {
      title: 'RELATIONS'
    }
  },
  {
    path: '/relations/stories/newsletter-editor/:id',
    name: 'NewsletterEditorUpdate',
    component: NewsletterEditorUpdate,
    props: true,
    meta: {
      title: 'RELATIONS'
    }
  },
  {
    path: '/relations/partners',
    name: 'relationsPartners',
    component: relationsPartners,
    meta: {
      title: 'RELATIONS'
    }
  },
  {
    path: '/relations/translator',
    name: 'relationsTranslator',
    component: relationsTranslator,
    meta: {
      title: 'RELATIONS'
    }
  },
  {
    path: '/relations/recruit',
    name: 'relationsRecruit',
    component: relationsRecruit,
    meta: {
      title: 'RELATIONS'
    }
  },
  {
    path: '/company',
    name: 'companyAll',
    component: companyAll,
    meta: {
      title: 'COMPANY'
    }
  },
  {
    path: '/companyPH',
    name: 'companyPH',
    component: companyPH,
    meta: {
      title: 'COMPANY Philippines'
    }
  },
  {
    path: '/hidden-login',
    name: 'hiddenLogin',
    component: hiddenLogin,
  },
  {
    path: '/technology/dev/:id',
    name: 'reportDetail',
    component: reportDetail,
    props: true,
    meta: {
      title: 'TECHNOLOGY'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: notFound
  },
  {
    path: '/technology/dev/report-editor/new',
    name: 'reportEditor',
    component: reportEditor,
    meta: {
      title: 'TECHNOLOGY'
    }
  },
  {
    path: '/technology/dev/report-editor/:id',
    name: 'reportUpdate',
    component: reportUpdate,
    props: true,
    meta: {
      title: 'TECHNOLOGY'
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    // if (savedPosition) {
    //   return savedPosition
    // } else if (to.path === '/relations/stories/:id') {
    //   return { top: 0 } // /relations/stories/:id 패스에서 스크롤 오프셋을 0으로 설정
    // } else {
      return { top: 0 }
    // }
  },
})

router.afterEach((to, from) => {
  const title = to.meta.title || 'LEXCODE'
  if(title) document.title = 'LEXCODE | ' + title
});

export default router
