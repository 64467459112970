<script>
export default {
  name: 'technologyNLP'
}
</script>
<template>
    <div class="technology nlp">
      <section class="section1">
      <div class="sec1_bg"></div>
      <div class="container">
        <div class="text_wrap t_white">
          <p class="sub_title">{{$t("tech_nlp.sec1_subTitle")/* 생성형 AI & NLP */}}</p>
          <h1 class="cont_title">{{$t("tech_nlp.sec1_contTitle")/* 언어와 기술 혁신을 통해 더 나은 미래를 지향합니다. */}}</h1>
        </div>
      </div>
    </section>
    <section class="section2">
      <div class="container flex">
        <div class="text_wrap">
          <p class="cont_title">{{$t("tech_nlp.sec2_contTitle")/* 무한한 기술력, 우리는 더 신속하고 */}}<br>{{$t("tech_nlp.sec2_contTitle2")/* 자유롭게 일할 수 있습니다. */}}</p>
        </div>
        <div class="sec2_cont">
          <template v-if="$i18n.locale == 'ko'"><img src="../assets/img/technology_ai_section2_img.png"/></template>
          <template v-else-if="$i18n.locale == 'en'"><img src="../assets/img/technology_ai_section2_en_img.png"/></template>          
        </div>
      </div>
    </section>
    <section class="section3">
      <div class="container">
        <div class="text_wrap">
          <p class="cont_title">{{$t("tech_nlp.sec3_contTitle")/* 다양해지는 산업, 세분화되는 요구, */}}<br>{{$t("tech_nlp.sec3_contTitle2")/* 그 모든 것을 충족할 수 있도록. */}}</p>
          <p class="cont_description mgt">{{$t("tech_nlp.sec3_desc")/* NLP 기술과 로컬리제이션 전문가들의 협업으로 탄생한 DeepRead를 통해 */}}<br>{{$t("tech_nlp.sec3_desc2")/* 다양한 산업 분야에서 프로젝트에 필요한 요구사항을 충족한 맞춤형 솔루션을 지원합니다. */}}</p>
        </div>
      </div>
      <div class="sec3_cont">
        <div class="container flex">
          <div class="circle1">
            <p class="cont_subtitle">
              NLP
            </p>
            <p class="cont_description">{{$t("tech_nlp.sec3_desc3")/* 텍스트 생성 AI */}}</p>
            <p class="cont_description">{{$t("tech_nlp.sec3_desc4")/* 자동번역 */}}</p>
            <p class="cont_description">{{$t("tech_nlp.sec3_desc5")/* 데이터 처리 */}}</p>
          </div>
          <div class="circle2 t_white">
            <p class="cont_subtitle">
              DeepRead
            </p>
            <p class="cont_description">{{$t("tech_nlp.sec3_desc6")/* 업무 지원 */}}</p>
            <p class="cont_description">{{$t("tech_nlp.sec3_desc7")/* 프레임 워크 */}}</p>
          </div>
          <template v-if="$i18n.locale == 'ko'"><img src="../assets/img/technology_ai_section3_img2.png"/></template>
          <template v-else-if="$i18n.locale == 'en'"><img src="../assets/img/technology_ai_section3_en_img2.png"/></template>          
        </div>
      </div>
    </section>
    <section class="section4">
      <div class="container">
        <p class="cont_title">{{$t("tech_nlp.sec4_contTitle")/* 쉽지만 가장 확실한 결과를 */}}<br>{{$t("tech_nlp.sec4_contTitle2")/* 얻기 위해 사용된 기술 */}}</p>
        <div class="flex">
          <div class="sec4_cont">
            <p class="cont_subtitle">{{$t("tech_nlp.sec4_subTitle1")/* 데이터 수집 */}}</p>
            <p class="cont_description">{{$t("tech_nlp.sec4_desc1")/* 대량의 데이터를 보유하고 있음에도, 때에 따라 더 많은 데이터를 수집해야 하는 경우가 있습니다. DeepRead는 효율적이고 정확한 데이터 수집을 위하여 BeautifulSoup, Selenium 등을 활용합니다. 수집된 데이터는 Pandas, Json 등을 통해 저장합니다. */}}</p>
          </div>
          <div class="sec4_cont">
            <p class="cont_subtitle">{{$t("tech_nlp.sec4_subTitle2")/* 자연어 전처리 및 분석 */}}</p>
            <p class="cont_description">{{$t("tech_nlp.sec4_desc2")/* 데이터 품질을 향상시키고 분석 결과의 정확성 및 유효성을 높이기 위해선 기존에 구축해 온 데이터라 할지라도, 데이터 전처리 과정을 권장합니다. DeepRead는 Regex , NLTK, Mecab 등을 활용해 데이터를 전처리 후 정제된 데이터에 대한 텍스트 분석을 하거나 Embedding 하여 컴퓨터가 이해할 수 있게끔 변환합니다. */}}</p>
          </div>
          <div class="sec4_cont">
            <p class="cont_subtitle">{{$t("tech_nlp.sec4_subTitle3")/* 자동 번역 */}}</p>
            <p class="cont_description">{{$t("tech_nlp.sec4_desc3")/* 인공지능 번역의 가장 기본이 되는 Transformer를 시작으로 mBART, T5, GPT 등의 다양한 모델들을 통해 지속적인 연구 및 실험을 진행합니다. 최종적으로 고객사들의 도메인에 맞는 커스텀 번역 엔진 개발을 지원합니다. */}}</p>
          </div>
          <div class="sec4_cont">
            <p class="cont_subtitle">{{$t("tech_nlp.sec4_subTitle4")/* 예측 */}}</p>
            <p class="cont_description">{{$t("tech_nlp.sec4_desc4")/* 렉스코드 통역사업부가 독립 법인으로 출범한 eQQui의 수출상담회·전시회 운영 전문 플랫폼 BIS@T은 다년간 쌓아온 수십만 건의 데이터를 활용하여 주관사, 바이어, 참여기업들이 원활하게 소통하고 비즈니스 인사이트를 얻을 수 있도록 예측 및 분석 모델을 개발하고 있습니다. 여기에 활용되는 패키지는 FastText, Plotly, Konlpy, Stylecloud, Matplotlib, Scikit-Learn 등 입니다. */}}</p>
          </div>
          <div class="sec4_cont">
            <p class="cont_subtitle">{{$t("tech_nlp.sec4_subTitle5")/* 추천 */}}</p>
            <p class="cont_description">{{$t("tech_nlp.sec4_desc5")/* 논문 게재에 도움이 되는 폭넓은 인사이트를 제공하기 위하여 렉스코드의 논문 솔루션 브랜드인 저널랩에서는 맞춤형 게재 저널 추천 기능을 고도화하고 있습니다. Sentence Transformer 패키지를 활용하여 데이터를 Embedding 하고, 데이터 간의 유사도를 비교하며, 추가로 Re-ranking 알고리즘을 적용하는 방식을 채택하였습니다. 또한 Openai의 GPT 모델을 활용한 논문 제목 추천, 요약문 생성 등의 기능을 추가로 제공합니다. */}}</p>
          </div>
          <div class="sec4_cont">
            <p class="cont_subtitle">{{$t("tech_nlp.sec4_subTitle6")/* 자동화 */}}</p>
            <p class="cont_description">{{$t("tech_nlp.sec4_desc6")/* 내부 작업의 효율성과 정확성 향상을 위하여 필요한 자동화는 프로젝트의 요구나 성격에 따라 단순 연산 작업부터 변환, 분류, 분석까지 다양한 범위의 작업으로 활용할 수 있습니다. 이 과정에서 주로 사용한 패키지에는 Openpyxl, win32com, Multiprocessing, Fasttext, Openai 등이 있습니다. */}}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section5">
      <div class="container t_white t_center">
        <p class="cont_title">{{$t("tech_nlp.sec5_contTitle")/* 언어의 가치와 기술 혁신을 통해 */}}<br>{{$t("tech_nlp.sec5_contTitle2")/* 비즈니스 경쟁력을 확보하세요. */}}</p>
      </div>
    </section>
    <div class="header_bg"></div>
  </div>
</template>
