<script>
export default {
  name: 'boardComments',
  inject: ['$axios'], // $axios를 주입받음
  props: {
    window: Object,
    content_id: Number,
    board_id: Number
  },
  data() {
    return {
      login_yn: false,
      nickname: null,
      password: null,
      comment: null,
      getComments: [], // 배열로 초기화
      password_check_yn: [],
      password_check_data: [],
      password_delete: false,
      password_update: false,
      update_comment_area: []
    }
  },
  mounted() {
    const login = localStorage.getItem('login')
    if (login === 'true') {
      this.login_yn = true
    }
    this.fetchComments()
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString)
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      return formattedDate
    },
    async fetchComments() {
      const contentId = this.$route.params.id
      try {
        const response = await this.$axios.post('https://lexcode.co.kr/app/get-comments', null, {
          // const response = await this.$axios.post('https://lexcode.co.kr/dev/get-comments', null, {
          params: {
            board_id: this.board_id,
            content_id: contentId
          }
        })

        if (Array.isArray(response.data)) {
          this.getComments = response.data.map(comment => {
            if (comment.created_at) {
              comment.created_at = this.formatDate(comment.created_at)
            }
            return comment
          })
        } else {
          console.error('Response data is not an array.')
        }
      } catch (error) {
        console.error(error)
      }
    },
    async saveComments() {
      const contentId = this.$route.params.id
      try {
        await this.$axios.post('https://lexcode.co.kr/app/create-comments', null, {
          // await this.$axios.post('https://lexcode.co.kr/dev/create-comments', null, {
          params: {
            board_id: this.board_id,
            content_id: contentId,
            user_display: this.nickname,
            password: this.password,
            content: this.comment,
            user_id: 0 // Todo 조건처리
          }
        })
        // console.log(response)
        // console.log(response.data)
        this.fetchComments()
      } catch (error) {
        console.error(error)
      }
    },
    password_check_delete(index) {
      this.password_check_yn[index] = true
      this.password_delete = true
      this.password_update = false
      this.update_comment_area[index] = false
    },
    password_check_update(index) {
      this.password_check_yn[index] = true
      this.password_update = true
      this.password_delete = false
      this.update_comment_area[index] = true
    },
    async deletePost(commentId, userDisplayCheck, passwordCheck) {
      if (passwordCheck == null) {
        alert('b_comment.alert1')/* 비밀번호를 입력해주세요. */
      }
      try {
        const response = await this.$axios.post('https://lexcode.co.kr/app/delete-comments', null, {
          // const response = await this.$axios.post('https://lexcode.co.kr/dev/delete-comments', null, {
          params: {
            comment_id: commentId,
            user_id: userDisplayCheck,
            password: passwordCheck
          }
        })
        // console.log(response.data)
        if (response.data === false) {
          alert('b_comment.alert2')/* 비밀번호가 틀립니다. */
        } else {
          alert('b_comment.alert3')/* 삭제되었습니다. */
          this.fetchComments()
        }
      } catch (error) {
        console.error(error)
      }
    },
    async updatePost(commentId, userDisplayCheck, passwordCheck, comment) {
      if (passwordCheck == null) {
        alert('b_comment.alert1')/* 비밀번호를 입력해주세요. */
      }
      try {
        const response = await this.$axios.post('https://lexcode.co.kr/app/update-comments', null, {
          // const response = await this.$axios.post('https://lexcode.co.kr/dev/update-comments', null, {
          params: {
            comment_id: commentId,
            user_id: 0,
            password: passwordCheck,
            content: comment
          }
        })
        // console.log(response)
        // console.log(response.data)
        if (response.data === false) {
          alert('b_comment.alert2')/* 비밀번호가 틀립니다. */
        } else {
          alert('b_comment.alert5')/* 수정되었습니다. */
          this.$router.go(0)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<template>
  <div class="full_wrap">
    <div class="create_comment_wrap">
      <div class="count_comment">
        <img src="../assets/img/comment_icon.png" />
        <p class="label">{{ $t("b_comment.title")/* 댓글 */ }}</p>
        <p class="num">{{ getComments.length }}</p>
      </div>
      <div class="input_comment">
        <fieldset>
          <input type="text" v-model="nickname" :placeholder="$t('b_comment.placeholder')" /><!-- /* 닉네임을 입력해주세요. */ -->
          <input type="password" v-model="password"
            :placeholder="$t('b_comment.placeholder2')" /><!-- /* 비밀번호를 입력해주세요. */ -->
        </fieldset>
        <textarea placeholder="" v-model="comment"></textarea>
      </div>
      <div class="btn_wrap">
        <p class="main_btn" @click="saveComments()">{{ $t("b_comment.btn")/* 등록 */ }}</p>
      </div>
    </div>
    <template v-if="getComments.length > 0">
      <div class="comments_wrap">
        <div class="comment_cont_wrap" v-for="(item, index) in getComments" :key="item">
          <div class="info_wrap">
            <div class="user_info">
              <p class="nickname">{{ item.user_display }}</p>
              <p class="create_at">{{ item.created_at }}</p>
            </div>
            <div class="user_func">
              <p @click="password_check_update(index)" class="">
                <img src="../assets/img/modify_icon.png" />
              </p>
              <p @click="password_check_delete(index)">
                <img src="../assets/img/delete_icon.png" />
              </p>
              <div class="password_check" v-if="password_check_yn[index]">
                <input v-model="password_check_data[index]" type="password"
                  placeholder="{{$t('b_comment.alert1')/* 비밀번호를 입력해주세요. */}}" />
                <button v-if="password_delete"
                  @click="deletePost(item.id, item.user_display, password_check_data[index])">{{ $t("b_comment.btn2")/*
                  삭제 */}}</button>
                <button v-if="password_update"
                  @click="updatePost(item.id, item.user_display, password_check_data[index], item.content)">{{
                    $t("b_comment.btn3")/*
                  수정 */}}</button>
              </div>
            </div>
          </div>
          <template v-if="update_comment_area[index]">
            <textarea placeholder="" v-model="item.content"></textarea>
          </template>
          <template v-else>
            <div class="comment_cont">
              {{ item.content }}
            </div>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="comment_empty">
        <img src="../assets/img/comment_icon_empty.png" />
        <p class="label">{{ $t("b_comment.desc")/* 첫 댓글을 남겨보세요! */ }}</p>
      </div>
    </template>
  </div>
</template>
