<script>
/* eslint-disable */
const OFFSET = 80
export default {
  name: 'commonHeader',
  props: {
    window: Object
  },
  data() {
    return {
      showPopup: true,
      hidePopupToday: false,
    }
  },
  mounted() {
    this.checkPopupStatus();
  },
  methods: {
    closePopup() {
      this.showPopup = false;
    },
    hidePopupTodayOption() {
      this.hidePopupToday = true;
      this.closePopup();
      const today = new Date().toISOString().split('T')[0];
      localStorage.setItem('lastClosedDate', today);
      localStorage.setItem('hidePopupToday', true);
    },
    checkPopupStatus() {
      const hidePopupToday = localStorage.getItem('hidePopupToday');
      if (hidePopupToday) {
        const today = new Date().toISOString().split('T')[0];
        const lastClosedDate = localStorage.getItem('lastClosedDate');
        if (lastClosedDate !== today) {
          this.showPopup = true;
          this.hidePopupToday = false;
        } else {
          this.showPopup = false;
          this.hidePopupToday = true;
        }
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="popup" v-if="showPopup && !hidePopupToday">
      <a href="https://lexcloud.ai" target="_blank">
        <template v-if="$i18n.locale == 'ko'">
          <img src="../assets/img/2024_popup.png" />
        </template>
        <template v-else>
          <img src="../assets/img/2024_popup_en.png" />
        </template>
      </a>
      <div class="popup_btn">
        <button @click="hidePopupTodayOption">{{$t("popup.check")/* 오늘 하루 보지 않기 */}}</button>
        <button @click="closePopup">{{$t("popup.btn")/* 닫기 */}}</button>
      </div>
    </div>
  </div>
</template>