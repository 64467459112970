<script>
/* eslint-disable */
const OFFSET = 80
export default {
    name: 'commonHeader',
    props: {
        window: Object,
        metaInfo: Object

    },
    data() {
        return {
            gnbSub: false,
            showNavbar: true,
            colorNavbar: false,
            lastScrollPosition: 0,
            hamburger_active: false,
            arrowRotate1: false,
            arrowRotate2: false,
            arrowRotate3: false,
            arrowRotate4: false,
            arrowRotate5: false,
            lang_popup: false,
        }
    },
    watch: {
        metaInfo: {
            immediate: true,
            deep: true,
            handler(newMeta) {
                const title = newMeta.title ? newMeta.title + " | LEXCODE | RELATIONS" : "LEXCODE | RELATIONS";
                const description = newMeta.description || "기본설명2";
                const keywords = newMeta.keywords || "기본 키워드2";

                document.title = title;
                this.updateMetaTag('description', description);
                this.updateMetaTag('keywords', keywords);
            }
        },
    },
    mounted() {
        this.lastScrollPosition = window.pageYOffset
        window.addEventListener('scroll', this.onScroll)
        window.addEventListener('scroll', this.addGnbClass)
        this.getLanguage()
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll)
        window.removeEventListener('scroll', this.addGnbClass)
    },
    methods: {
        onScroll() {
            if (window.pageYOffset < 0) {
                return
            }
            if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
                return
            }
            this.showNavbar = window.pageYOffset < this.lastScrollPosition
            this.lastScrollPosition = window.pageYOffset
        },
        addGnbClass() {
            if (this.lastScrollPosition < 80) {
                this.colorNavbar = false
            } else if (this.lastScrollPosition > 81) {
                this.colorNavbar = true
            }
        },
        removeClass() {
            if (this.lastScrollPosition < 80 && this.colorNavbar == true) {
                this.colorNavbar = false
            }
        },
        toggleShow1() {
            this.arrowRotate1 = !this.arrowRotate1
        },
        toggleShow2() {
            this.arrowRotate2 = !this.arrowRotate2
        },
        toggleShow3() {
            this.arrowRotate3 = !this.arrowRotate3
        },
        toggleShow4() {
            this.arrowRotate4 = !this.arrowRotate4
        },
        toggleShow5() {
            this.arrowRotate5 = !this.arrowRotate5
        },
        langChange(lang) {
            if (lang == 'ko') {
                localStorage.setItem("language_set", "ko")
                this.$i18n.locale = 'ko'
            } else {
                localStorage.setItem("language_set", "en")
                this.$i18n.locale = 'en'
            }
            this.hamburger_active = false
            this.arrowRotate5 = false
        },
        getLanguage() {
            const language_set = localStorage.getItem('language_set');
            if (language_set == 'ko') {
                this.$i18n.locale = 'ko'
            } else if (language_set == 'en') {
                this.$i18n.locale = 'en'
            }
        },
        updateMetaTag(name, content) {
            let tag = document.head.querySelector(`meta[name="${name}"]`);
            if (!tag) {
                tag = document.createElement('meta');
                tag.setAttribute('name', name);
                document.head.appendChild(tag);
            }
            tag.setAttribute('content', content);
        }
    },
}
</script>

<template>
    <div>
        <template v-if="window.width > 768">
            <header class="header"
                :class="[{ 'hidden-navbar': !showNavbar }, colorNavbar ? 'color_nav' : 'default_nav', { 'border-radius': gnbSub }]"
                @mouseleave="gnbSub = false, removeClass(item)">
                <nav class="container">
                    <a href="/" class="main_logo">
                        <!-- <img alt="lexcode logo" :src="colorNavbar ? '../assets/img/logo_c.png' : '../assets/img/logo_w.png'"> -->
                        <template v-if="colorNavbar == false">
                            <img alt="lexcode logo" src="../assets/img/logo_w.png">
                        </template>
                        <template v-else>
                            <img alt="lexcode logo" src="../assets/img/logo_c.png">
                        </template>
                    </a>
                    <ul class="gnb">
                        <li>
                            <a href="/business" @mouseenter="gnbSub = true, colorNavbar = true">
                                <span>BUSINESS</span>
                            </a>
                            <transition name="fade">
                                <ul v-if="gnbSub == true" class="gnb_sub business">
                                    <li>
                                        <a href="/business/lex-cloud">LEX-Cloud
                                            <span>{{ $t("header.business_1")/* 번역 · ATPE */ }}</span>
                                        </a>
                                        <a href="/business/tc">TransCreation
                                            <span>{{ $t("header.business_2")/* 생성형 AI & NLP 모델 */ }}</span>
                                        </a>
                                        <a href="/business/cat-x">CAT-X
                                            <span>{{ $t("header.business_3")/* 커스텀 자동번역 모델구축 */ }}</span>
                                        </a>
                                        <a href="/business/journallab">Journal Lab
                                            <span>{{ $t("header.business_4")/* GPT 기반 논문 분석 · 에디팅 */ }}</span>
                                        </a>
                                        <a href="/business/bavl">BAVL
                                            <span>{{ $t("header.business_5")/* 자연어 데이터 수집 · 정제 */ }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </transition>
                        </li>
                        <li>
                            <a href="/technology/atpe" @mouseenter="gnbSub = true, colorNavbar = true">
                                <span>TECHNOLOGY</span>
                            </a>
                            <transition name="fade">
                                <ul v-if="gnbSub == true" class="gnb_sub technology">
                                    <li>
                                        <a href="/technology/atpe"
                                            v-html='$t("header.tech_1")'></a><!-- {{/* 자연어 번역 · 감수​ ATPE */}} -->
                                        <a href="/technology/deepread">{{ $t("header.tech_2")/* 생성형 AI · NLP */ }}</a>
                                        <a href="/technology/dev">{{ $t("header.tech_3")/* 연구일지 */ }}</a>
                                    </li>
                                </ul>
                            </transition>
                        </li>
                        <li>
                            <a href="/relations/stories" @mouseenter="gnbSub = true, colorNavbar = true">
                                <span>RELATIONS</span>
                            </a>
                            <transition name="fade">
                                <ul v-if="gnbSub == true" class="gnb_sub relations">
                                    <li>
                                        <a href="/relations/stories">{{ $t("header.relation_1")/* Stories */ }}</a>
                                        <a href="/relations/partners">{{ $t("header.relation_2")/* 파트너 실적 */ }}</a>
                                        <a href="/relations/translator">{{ $t("header.relation_3")/* 번역사 지원 */ }}</a>
                                        <a href="/relations/recruit">{{ $t("header.relation_4")/* 채 */ }}</a>
                                    </li>
                                </ul>
                            </transition>
                        </li>
                        <li>
                            <a href="/company" @mouseenter="gnbSub = true, colorNavbar = true">
                                <span>COMPANY</span>
                            </a>
                            <transition name="fade">
                                <ul v-if="gnbSub == true" class="gnb_sub company">
                                    <li>
                                        <a href="/company">ABOUT LEXCODE</a>
                                        <a href="/company/#visionMission">VISION & MILESTONE</a>
                                        <a href="/company/#contactus">CONTACT US</a>
                                        <a href="/companyPH">LEXCODE Philippines</a>
                                    </li>
                                </ul>
                            </transition>
                        </li>
                    </ul>
                    <div class="gnb2">
                        <li>
                            <a @mouseenter="lang_popup = true">
                                <span style="text-transform: uppercase;">{{ $i18n.locale }}</span>
                            </a>
                            <transition name="fade">
                                <ul v-if="lang_popup" class="lang_list" @mouseleave="lang_popup = false">
                                    <li :class="{ active: $i18n.locale == 'ko' }" @click="langChange('ko')">KO</li>
                                    <li :class="{ active: $i18n.locale == 'en' }" @click="langChange('en')">EN</li>
                                </ul>
                            </transition>
                        </li>
                    </div>
                </nav>
                <transition name="fade">
                    <!-- <div v-if="gnbSub == true" class="subnav_overlay" @mouseleave="gnbSub = false, removeClass(item)" ></div> -->
                    <div v-if="gnbSub == true" class="subnav_overlay" :class="{ en: $i18n.locale == 'en' }"
                        @mouseleave="gnbSub = false, removeClass(item)">
                    </div>
                </transition>
            </header>
        </template>
        <template v-else>
            <header class="header"
                :class="[{ 'hidden-navbar': !showNavbar }, colorNavbar ? 'color_nav' : 'default_nav', { 'border-radius': gnbSub }]">
                <nav class="container">
                    <a href="/" class="main_logo">
                        <!-- <img alt="lexcode logo" :src="colorNavbar ? '../assets/img/logo_c.png' : '../assets/img/logo_w.png'"> -->
                        <template v-if="colorNavbar == false">
                            <img alt="lexcode logo" src="../assets/img/logo_w.png">
                        </template>
                        <template v-else>
                            <img alt="lexcode logo" src="../assets/img/logo_c.png">
                        </template>
                    </a>

                    <div class="hamburger">
                        <div class="lang_divide">
                            <div class="lang_pair" :class="{ active: $i18n.locale == 'ko' }" @click="langChange('ko')">
                                KO</div>
                            <div class="divide_line"></div>
                            <div class="lang_pair" :class="{ active: $i18n.locale == 'en' }" @click="langChange('en')">
                                EN</div>
                        </div>
                        <template v-if="colorNavbar == false">
                            <img alt="hamburger" src="../assets/img/header_hamburger_w.png"
                                @click="hamburger_active = true">
                        </template>
                        <template v-else>
                            <img alt="hamburger" src="../assets/img/header_hamburger_b.png"
                                @click="hamburger_active = true">
                        </template>
                    </div>
                </nav>
            </header>
            <transition name="draw">
                <div class="draw_menu" v-if="hamburger_active" :class="{ active: hamburger_active }">
                    <div class="draw_menu_header flex">
                        <div class="gnb2">
                            <p class="active"></p>
                            <!-- <p>EN</p> -->
                        </div>
                        <p class="close" @click="hamburger_active = false"><img
                                src="../assets/img/header_hamburger_close.png" />
                        </p>
                    </div>
                    <div class="draw_menu_cont_wrap">
                        <div class="draw_menu_cont">
                            <div class="toggle" @click="toggleShow1">
                                <p class="cont_title">BUSINESS</p>
                                <p class="arrow" :class="{ 'active': arrowRotate1 }"><img
                                        src="../assets/img/header_hamburger_arrow.png" /></p>
                            </div>
                            <transition name="submenu-slide">
                                <div class="draw_menu_subcont" v-if="arrowRotate1">
                                    <a href="/business/lex-cloud" class="draw_menu_subcont_title">LEX-Cloud
                                        {{ $t("header.business_1")/* 번역 · ATPE */ }}</a>
                                    <a href="/business/tc" class="draw_menu_subcont_title">TransCreation
                                        {{ $t("header.business_2")/* 생성형 AI & NLP 모델 */ }}</a>
                                    <a href="/business/cat-x" class="draw_menu_subcont_title">CAT-X
                                        {{ $t("header.business_3")/* 커스텀
                                        자동번역 모델구축 */}}</a>
                                    <a href="/business/journallab" class="draw_menu_subcont_title">Journal Lab
                                        {{ $t("header.business_4")/* GPT 기반 논문 분석 · 에디팅 */ }}</a>
                                    <a href="/business/bavl" class="draw_menu_subcont_title">BAVL
                                        {{ $t("header.business_5")/* 자연어
                                        데이터 수집 · 정제 */}}</a>
                                </div>
                            </transition>
                        </div>
                        <div class="draw_menu_cont">
                            <div class="toggle" @click="toggleShow2">
                                <p class="cont_title">TECHNOLOGY</p>
                                <p class="arrow" :class="{ 'active': arrowRotate2 }"><img
                                        src="../assets/img/header_hamburger_arrow.png" /></p>
                            </div>
                            <transition name="submenu-slide">
                                <div class="draw_menu_subcont" v-if="arrowRotate2">
                                    <a href="/technology/atpe" class="draw_menu_subcont_title">{{ $t("header.tech_1")/*
                                        자연어 번역 · 감수
                                        ATPE */}}</a>
                                    <a href="/technology/deepread" class="draw_menu_subcont_title">{{
                                        $t("header.tech_2")/* 생성형 AI ·
                                        NLP */}}</a>
                                    <a href="/technology/dev" class="draw_menu_subcont_title">{{ $t("header.tech_3")/*
                                        연구일지 */}}</a>
                                </div>
                            </transition>
                        </div>
                        <div class="draw_menu_cont">
                            <div class="toggle" @click="toggleShow3">
                                <p class="cont_title">RELATIONS</p>
                                <p class="arrow" :class="{ 'active': arrowRotate3 }"><img
                                        src="../assets/img/header_hamburger_arrow.png" /></p>
                            </div>
                            <transition name="submenu-slide">
                                <div class="draw_menu_subcont" v-if="arrowRotate3">
                                    <a href="/relations/stories" class="draw_menu_subcont_title">{{
                                        $t("header.relation_1")/*
                                        Stories
                                        */}}</a>
                                    <a href="/relations/partners" class="draw_menu_subcont_title">{{
                                        $t("header.relation_2")/* 파트너
                                        실적
                                        */}}</a>
                                    <a href="/relations/translator" class="draw_menu_subcont_title">{{
                                        $t("header.relation_3")/* 번역사
                                        지원 */}}</a>
                                    <a href="/relations/recruit" class="draw_menu_subcont_title">{{
                                        $t("header.relation_4")/* 채
                                        */}}</a>
                                </div>
                            </transition>
                        </div>
                        <div class="draw_menu_cont">
                            <div class="toggle" @click="toggleShow4">
                                <p class="cont_title">COMPANY</p>
                                <p class="arrow" :class="{ 'active': arrowRotate4 }"><img
                                        src="../assets/img/header_hamburger_arrow.png" /></p>
                            </div>
                            <transition name="submenu-slide">
                                <div class="draw_menu_subcont" v-if="arrowRotate4">
                                    <a href="/company" class="draw_menu_subcont_title">ABOUT LEXCODE</a>
                                    <a href="/company/#visionMission" class="draw_menu_subcont_title">VISION &
                                        MILESTONE</a>
                                    <a href="/company/#contactus" class="draw_menu_subcont_title">CONTACT US</a>
                                    <a href="/companyPH" class="draw_menu_subcont_title">LEXCODE Philippines</a>
                                </div>
                            </transition>
                        </div>
                        <!-- <div class="draw_menu_cont">
                            <div class="toggle" @click="toggleShow5">
                                <p class="cont_title"><span style="text-transform: uppercase;">{{ $i18n.locale }}</span>
                                </p>
                                <p class="arrow" :class="{ 'active': arrowRotate5 }"><img
                                        src="../assets/img/header_hamburger_arrow.png" /></p>
                            </div>
                            <transition name="submenu-slide">
                                <div class="draw_menu_subcont" v-if="arrowRotate5">
                                    <a href="javascript:void(0)" class="draw_menu_subcont_title"
                                        @click="langChange('ko')">KO</a>
                                    <a href="javascript:void(0)" class="draw_menu_subcont_title"
                                        @click="langChange('en')">EN</a>
                                </div>
                            </transition>
                        </div> -->
                    </div>
                </div>
            </transition>
        </template>
    </div>
</template>