<script>
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer'
import '@toast-ui/editor/dist/toastui-editor-viewer.css'
import boardComments from '../components/boardComments.vue'

export default {
  name: 'newsletter-document',
  inject: ['$axios'], // $axios를 주입받음
  components: {
    boardComments
  },
  data() {
    return {
      // contentData: null,
      viewer: null,
      likes_active: false,
      selectedItem: {}, // 선택한 항목의 상세 정보 데이터,
      login_yn: false,
    }
  },
  mounted() {
    const login = localStorage.getItem('login')
    if (login === 'lexcode') {
      this.login_yn = true
    }
    // 선택한 항목의 상세 정보를 가져오는 로직
    this.fetchDetail()
    // console.log(this.contentData)
    this.viewer = new Viewer({
      el: document.querySelector('#viewer'),
      customHTMLRenderer: {
        htmlBlock: {
          iframe(node) {
            return [
              { type: 'openTag', tagName: 'iframe', outerNewLine: true, attributes: node.attrs },
              { type: 'html', content: node.childrenHTML },
              { type: 'closeTag', tagName: 'iframe', outerNewLine: true }
            ]
          }
        }
      }
    })
  },
  methods: {
    async fetchDetail() {
      const itemId = this.$route.params.id
      const likedItems = JSON.parse(localStorage.getItem('likedItems')) || []
      // console.log(likedItems, itemId)
      if (likedItems.includes(Number(itemId))) {
        this.$data.likes_active = true
      }
      try {
        const response = await this.$axios.get(`https://lexcode.co.kr/app/get_contents_detail/${itemId}`)
        // const response = await this.$axios.get(`https://lexcode.co.kr/dev/get_contents_detail/${itemId}`)
        this.selectedItem = {
          ...response.data,
          created_at: this.formatDate(response.data.created_at)
        }
        // console.log(this.selectedItem.content)
        this.$data.contentData = this.selectedItem.content
        this.initializeViewer()
        this.$emit('update-meta', {
          title: this.selectedItem.metadata.title, // 타이틀 동적 업데이트
          description: this.selectedItem.metadata.description, // 설명 추가
          keywords: this.selectedItem.metadata.keyword
        })
        // console.log(response.status)
      } catch (error) {
        this.$router.push({ name: 'notFound', params: { pathMatch: this.$route.path.substring(1).split('/') } })
        // console.log('에러입니다')
        // console.error(error.response)
      }
    },
    initializeViewer() {
      this.viewer = new Viewer({
        el: document.querySelector('#viewer'),
        height: '800px',
        initialValue: this.contentData
      })
      // console.log(this.contentData)
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      return formattedDate
    },
    async likeNewsletter() {
      const likedItems = JSON.parse(localStorage.getItem('likedItems')) || []
      if (likedItems.includes(this.selectedItem.id)) {
        alert(this.$t("stories.alert3"))
        return
      }
      try {
        const response = await this.$axios.post('https://lexcode.co.kr/app/increase_like', null, {
          // const response = await this.$axios.post('https://lexcode.co.kr/dev/increase_like', null, {
          params: {
            contents_id: this.selectedItem.id
          },
          headers: {
            Accept: 'application/json'
          }
        })
        const likes = response.data.likes[0][0]
        // console.log(response.data)
        this.selectedItem.likes = likes
        this.$data.likes_active = true
        likedItems.push(this.selectedItem.id)
        localStorage.setItem('likedItems', JSON.stringify(likedItems))
      } catch (error) {
        console.error(error)
      }
    },
    async deletePost() {
      if (confirm(this.$t("stories.alert1"))) {
        try {
          await this.$axios.post('https://lexcode.co.kr/app/delete_post', null, {
            // await this.$axios.post('https://lexcode.co.kr/dev/delete_post', null, {
            params: {
              contents_id: this.selectedItem.id
            },
            headers: {
              Accept: 'application/json'
            }
          })
          // console.log(response)
          this.$router.push({
            name: 'relationsStories'
          })
          alert(this.$t("stories.alert2"))
        } catch (error) {
          console.error('failed:', error)
        }
      } else {
        // 취소 버튼을 눌렀을 때 수행할 작업 추가
      }
    }
  }
}
</script>

<template>
  <div class="newsletter">
    <section class="top">
      <div class="btn_wrap" v-if="login_yn">
        <router-link :to="`/relations/stories/newsletter-editor/${selectedItem.id}`">
          <img src="../assets/img/modify_icon.png" />
        </router-link>
        <p @click="deletePost()">
          <img src="../assets/img/delete_icon.png" />
        </p>
      </div>
      <div class="container">
        <h1>{{ selectedItem.title }}</h1>
        <p class="author">by. {{ selectedItem.author }}</p>
        <div class="sub-info">
          <p>{{ $t("stories.view")/* 조회수 */ }} {{ selectedItem.views }}</p>
          <p>{{ selectedItem.created_at }}</p>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container">
        <!-- <p v-html="selectedItem.content"></p> -->
        <div id="viewer"></div>
      </div>
    </section>
    <section class="likes">
      <div class="container">
        <p class="likes_label">{{ $t("stories.likes_label")/* 이 뉴스레터가 재밌으셨다면 Thumbs Up! */ }}</p>
        <div class="main_btn" :class="{ active: likes_active }" @click="likeNewsletter()">
          <template v-if="likes_active == true">
            <img src="../assets/img/relations_stories_icon_active.png" />
          </template>
          <template v-else>
            <img src="../assets/img/relations_stories_icon.png" />
          </template>
          <p>{{ selectedItem.likes }}</p>
        </div>
      </div>
    </section>
    <section class="comment">
      <div class="container">
        <boardComments :content_id="this.selectedItem.id" :board_id="1"></boardComments>
      </div>
    </section>
    <section class="goBack">
      <div class="container">
        <a class="goBack_btn" href="/relations/stories#newsList">{{ $t("stories.btn_to_list")/* 목록으로 */ }}</a>
      </div>
    </section>
    <div class="header_bg"></div>
  </div>
</template>
