<script>
import { computed, ref, inject, onMounted, watch } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { useI18n } from 'vue-i18n';

export default {
  name: 'relationsStories',
  props: {
    window: Object
  },
  components: {
    Swiper,
    SwiperSlide
  },
  inject: ['$axios'],
  setup() {
    const $axios = inject('$axios') // $axios 주입 받기
    const itemsPerPage = 10 // 페이지 당 표시할 항목 수
    const currentPage = ref(1)
    const byAll = ref([])
    const by_views = ref([])
    const by_date = ref([])
    const response = ref(null) // 초기값으로 null 설정
    const scrollContainer = ref(null) // 초기값으로 null 설정
    const { locale } = useI18n()
    const language_set = ref(locale.value)


    // 검색, 소팅 변수 지정
    const isDropdownOpen = ref(false)
    const selectedOption = ref({ text: 'tech_dev.filter1', value: { sort: 'created_at', ascending: 'DESC' } })
    const options = [
      { text: 'tech_dev.filter1', value: { sort: 'created_at', ascending: 'DESC' } }, /* 최신순 */
      { text: 'tech_dev.filter2', value: { sort: 'views', ascending: 'DESC' } }, /* 조회수 높은순 */
      { text: 'tech_dev.filter3', value: { sort: 'likes', ascending: 'DESC' } }, /* 추천수 높은순 */
      { text: 'tech_dev.filter4', value: { sort: 'title', ascending: 'ASC' } } /* 가나다순 */
    ]
    const keyword = ref('')

    watch(() => locale.value, (newValue) => {
      language_set.value = newValue;
      fetchPosts(selectedOption.value, keyword.value); // locale이 변경될 때마다 fetchPosts를 호출
      get_news_by_view()
      get_news_by_date()
    });

    // 전체 포스트 가져오기 + 검색어 + 소팅값
    async function fetchPosts(selectedOption, keywordValue) {
      try {
        response.value = await $axios.post('https://lexcode.co.kr/app/get_all_newsletter_by_sort', {
          // response.value = await $axios.post('https://lexcode.co.kr/dev/get_all_newsletter_by_sort', {
          sort: selectedOption.value.sort,
          ascending: selectedOption.value.ascending,
          keyword: keywordValue,
          locale: language_set.value
        })
        // console.log(response.data)
        if (Array.isArray(response.value.data)) {
          byAll.value = response.value.data.map(item => {
            return {
              ...item,
              created_at: formatDate(item.created_at)
            }
          })
        } else {
          byAll.value = []
          response.value = response.value.data
        }
      } catch (error) {
        console.error(error)
      }
    }
    // 날짜 변환 함수
    function formatDate(dateString) {
      const date = new Date(dateString)
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      return formattedDate
    }

    // 소팅, 검색 기능
    function toggleDropdown() {
      isDropdownOpen.value = !isDropdownOpen.value
    }

    function selectOption(option) {
      selectedOption.value = option
      isDropdownOpen.value = false
      fetchPosts(option, keyword.value)
    }

    function searchPost() {
      fetchPosts(selectedOption.value, keyword.value)
      // console.log(selectedOption.value, keyword.value)
    }

    function resetKeyword() {
      keyword.value = ''
      fetchPosts(selectedOption.value, keyword.value)
    }

    // 페이지 각각 장수 계산
    const paginatedData = computed(() => {
      const startIndex = (currentPage.value - 1) * itemsPerPage
      const endIndex = startIndex + itemsPerPage
      return byAll.value.slice(startIndex, endIndex)
    })
    // 전체 페이지 계산
    const totalPages = computed(() => Math.ceil(byAll.value.length / itemsPerPage))

    // 현재 페이지 주변에 표시할 페이지 번호 계산
    const visiblePages = computed(() => {
      const pageRange = 1 // 현재 페이지를 기준으로 양쪽에 표시할 페이지 번호 개수
      const totalVisiblePages = 4 // 처음과 마지막을 제외한 표시될 페이지 번호 개수
      const currentPageIndex = currentPage.value - 1
      const visiblePages = []

      if (totalPages.value <= totalVisiblePages + 2) {
        // 페이지 번호가 모두 표시 가능한 경우
        visiblePages.push(...Array.from({ length: totalPages.value }, (_, index) => index + 1))
      } else if (currentPageIndex < totalVisiblePages - pageRange) {
        // 현재 페이지가 처음 페이지 근처에 위치한 경우
        visiblePages.push(...Array.from({ length: totalVisiblePages + 1 }, (_, index) => index + 1))
        visiblePages.push('...')
        visiblePages.push(totalPages.value)
      } else if (currentPageIndex >= totalPages.value - 2 - pageRange) {
        // 현재 페이지가 마지막 페이지 근처에 위치한 경우
        visiblePages.push(1)
        visiblePages.push('...')
        visiblePages.push(...Array.from({ length: totalVisiblePages + 1 }, (_, index) => totalPages.value - totalVisiblePages + index))
      } else {
        // 현재 페이지가 중간에 위치한 경우
        visiblePages.push(1)
        visiblePages.push('...')
        visiblePages.push(...Array.from({ length: pageRange * 2 + 1 }, (_, index) => currentPageIndex - pageRange + index + 1))
        visiblePages.push('...')
        visiblePages.push(totalPages.value)
      }

      return visiblePages
    })

    function changePage(page) {
      currentPage.value = page
      localStorage.setItem('savePage', JSON.stringify(currentPage.value))
      scrollContainer.value.scrollIntoView()
    }

    const get_news_by_view = async () => {
      try {
        const response = await $axios.post('https://lexcode.co.kr/app/get_newsletter_by_views', null, {
          // const response = await $axios.post('https://lexcode.co.kr/dev/get_newsletter_by_views', null, {
          params: {
            locale: language_set.value
          },
        });
        by_views.value = response.data;
        // console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    const get_news_by_date = async () => {
      try {
        const response = await $axios.post('https://lexcode.co.kr/app/get_newsletter_by_date', null, {
          // const response = await $axios.post('https://lexcode.co.kr/dev/get_newsletter_by_date', null, {
          params: {
            locale: language_set.value
          },
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        });
        by_date.value = response.data.map(item => {
          return {
            ...item,
            created_at: formatDate(item.created_at)
          };
        });
      } catch (error) {
        console.error(error);
      }
    }

    const login_yn = ref(false)
    onMounted(() => {
      const savedPage = localStorage.getItem('savePage')
      if (savedPage) {
        currentPage.value = JSON.parse(savedPage)
      }
      const login = localStorage.getItem('login')
      if (login === 'lexcode') {
        login_yn.value = true
      }
      get_news_by_view()
      get_news_by_date()
      fetchPosts(selectedOption.value, keyword.value)
    })

    return {
      currentPage,
      paginatedData,
      totalPages,
      visiblePages,
      scrollContainer,
      changePage,
      itemsPerPage,
      byAll,
      by_views,
      by_date,
      modules: [Autoplay, Navigation, Pagination],
      isDropdownOpen,
      selectedOption,
      options,
      keyword,
      toggleDropdown,
      selectOption,
      searchPost,
      response,
      resetKeyword,
      login_yn
    }
  },

}
</script>
<template>
  <div class="relations stories">
    <section class="section1">
      <template v-if="window.width > 768">
        <div class="container">
          <h1 class="cont_title">Stories</h1>
          <div v-if="login_yn" class="create_post">
            <router-link :to="`/relations/stories/newsletter-editor/new`">{{ $t("stories.sec1_btn")/* 글쓰기 🥕
              */}}</router-link>
          </div>
          <div class="flex">
            <div class="newsletter_recent">
              <h2 class="cont_subtitle">{{ $t("stories.sec1_contTitle")/* 최신글 */ }}</h2>
              <swiper :navigation="true" :autoplay="{ delay: 2500, disableOnInteraction: false }" :pagination="true"
                :modules="modules" class="mySwiper">
                <swiper-slide v-for="(item, index) in by_date" :key="index">
                  <router-link :to="`/relations/stories/${item.id}`">
                    <div class="overlay">
                      <p>{{ item.title }}</p>
                    </div>
                    <img :src="'https://directory.lexcode.co.kr/newsletter-content' + item.thumbnail_file" />
                  </router-link>
                </swiper-slide>
              </swiper>
            </div>
            <div class="newsletter_top">
              <h2 class="cont_subtitle" @click="get_news_by_view()">{{ $t("stories.sec2_contTitle")/* 인기글 */ }}</h2>
              <router-link :to="`/relations/stories/${item.id}`" v-for="(item, index) in by_views" :key="index">
                <p class="num">{{ index + 1 }}</p>
                <p class="title">{{ item.title }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="container">
          <h1 class="cont_title">Stories</h1>
        </div>
        <div v-if="login_yn" class="create_post">
          <router-link :to="`/relations/stories/newsletter-editor/new`">{{ $t("stories.sec1_btn")/* 글쓰기 🥕
            */}}</router-link>
        </div>
        <div class="flex">
          <div class="newsletter_recent">
            <h2 class="cont_subtitle">{{ $t("stories.sec1_contTitle")/* 최신글 */ }}</h2>
            <swiper :navigation="true" :autoplay="{ delay: 2500, disableOnInteraction: false }" :pagination="true"
              :modules="modules" class="mySwiper">
              <swiper-slide v-for="(item, index) in by_date" :key="index">
                <router-link :to="`/relations/stories/${item.id}`">
                  <div class="overlay">
                    <p>{{ item.title }}</p>
                  </div>
                  <img :src="'https://directory.lexcode.co.kr/newsletter-content' + item.thumbnail_file" />
                </router-link>
              </swiper-slide>
            </swiper>
          </div>
          <div class="newsletter_top">
            <h2 class="cont_subtitle" @click="get_news_by_view()">{{ $t("stories.sec2_contTitle")/* 인기글 */ }}</h2>
            <router-link :to="`/relations/stories/${item.id}`" v-for="(item, index) in by_views" :key="index">
              <p class="num">{{ index + 1 }}</p>
              <p class="title">{{ item.title }}</p>
            </router-link>
          </div>
        </div>
      </template>
    </section>
    <section class="section3">
      <template v-if="window.width > 768">
        <div class="container">
          <div class="flex">
            <div class="cont3_wrap">
              <img src="../assets/img/relations_stories_img1.png" />
              <div class="text_wrap">
                <h5>{{ $t("stories.sec3_contTitle")/* 어떤 이야기가 궁금하신가요? */ }}</h5>
                <p class="cont_description">{{ $t("stories.sec3_desc") }}</p>
                <!-- {{/* 번역, 통역, 렉스코드 등 궁금했던 주제가 있다면 언제든지 말씀해주세요. */}} -->
              </div>
            </div>
            <a href="https://forms.gle/tkc9YGK6sSiYTzeT8" class="main_btn" target="_blank">{{ $t("stories.sec3_btn")/*
              의견
              남기기 */}}<img src="../assets/img/relations_stories_arrow.png" /></a>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex">
          <div class="cont3_wrap">
            <img src="../assets/img/relations_stories_img1.png" />
            <div class="text_wrap">
              <h5>{{ $t("stories.sec3_contTitle")/* 어떤 이야기가 궁금하신가요? */ }}</h5>
              <p class="cont_description"> {{ $t("stories.sec3_desc") }}</p>
              <!-- {{/* 번역, 통역, 렉스코드 등 궁금했던 주제가 있다면 언제든지 말씀해주세요. */}}re -->
            </div>
          </div>
          <a href="https://forms.gle/tkc9YGK6sSiYTzeT8" class="main_btn" target="_blank">{{ $t("stories.sec3_btn")/* 의견
            남기기 */}}<img src="../assets/img/relations_stories_arrow.png" /></a>
        </div>
      </template>
    </section>
    <section class="section2" id="newsList" ref="scrollContainer">
      <div class="container">
        <h1 class="cont_title">{{ $t("stories.sec4_contTitle")/* 더 많은 스토리를 확인해 보세요. */ }}</h1>
        <div class="newsletter_cont_wrap">
          <div class="newsletter_function">
            <div class="total_wrap">
              <p class="total">{{ $t('stories.sec4_length', { length: byAll.length })/* 총 n개의 뉴스레터 */ }}</p>
            </div>
            <div class="function_wrap">
              <div class="search_wrap">
                <input type="text" class="search_input" v-model="keyword" @keyup.enter="searchPost(keyword)"
                  :placeholder="$t('stories.sec4_placeholder')" /><!-- 검색어를 입력하고 엔터를 눌러주세요. -->
                <img src="../assets/img/search_icon.png" />
              </div>
              <div class="sort_wrap">
                <div class="custom-select" @click="toggleDropdown">
                  <div class="selected-option">
                    {{ $t(selectedOption.text) }} <img src="../assets/img/sort_arrow.png"
                      :class="{ rotate: isDropdownOpen }" />
                  </div>
                  <ul v-show="isDropdownOpen">
                    <li v-for="(option, index) in options" :key="index" @click="selectOption(option)"
                      @change="toggleDropdown">
                      {{ $t(option.text) }} <span v-if="selectedOption.text == option.text">&#10004;</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <template v-if="window.width > 768">
            <div class="newsletter_header">
              <p class="num">{{ $t("stories.list_th1")/* 번호 */ }}</p>
              <p class="thumbnail">{{ $t("stories.list_th2")/* 썸네일 */ }}</p>
              <p class="title">{{ $t("stories.list_th3")/* 제목 */ }}</p>
              <p class="author">{{ $t("stories.list_th4")/* 작성자 */ }}</p>
              <p class="date">{{ $t("stories.list_th5")/* 작성일 */ }}</p>
              <p class="like">{{ $t("stories.list_th6")/* 추천 */ }}</p>
              <p class="view">{{ $t("stories.list_th7")/* 조회 */ }}</p>
            </div>
            <template v-if="response !== false">
              <div class="newsletter_cont" v-for="(item, index) in paginatedData" :key="index">
                <router-link :to="`/relations/stories/${item.id}`">
                  <p class=num>{{ (index + 1) + (currentPage - 1) * 10 }}</p>
                  <img class="thumbnail"
                    :src="'https://directory.lexcode.co.kr/newsletter-content' + item.thumbnail_file" />
                  <p class="title">{{ item.title }} <span v-if="item.comment_count > 0">({{ item.comment_count
                      }})</span></p>
                  <p class="author">{{ item.author }}</p>
                  <p class="date">{{ item.created_at }}</p>
                  <p class="like">{{ item.likes }}</p>
                  <p class="view">{{ item.views }}</p>
                </router-link>
              </div>
            </template>
            <template v-else>
              <div class="newsletter_cont_empty t_center">
                <img src="../assets/img/search_icon.png" />
                <p class="title">{{ $t('stories.list_noResult', { keyword: keyword })/* keyword에 대한 검색결과가 없습니다. */ }}
                </p>
                <p class="cont_description">{{ $t("stories.list_tryAgain")/* 다시 시도해보세요. */ }}</p>
                <p class="cont_description btn" @click="resetKeyword">{{ $t("stories.list_reset")/* 검색어 초기화 */ }}</p>
              </div>
            </template>
          </template>
          <template v-else>
            <template v-if="response !== false">
              <div class="newsletter_cont" v-for="(item, index) in paginatedData" :key="index">
                <router-link :to="`/relations/stories/${item.id}`">
                  <img class="thumbnail"
                    :src="'https://directory.lexcode.co.kr/newsletter-content' + item.thumbnail_file" />
                  <div class="news_info">
                    <p class="title">{{ item.title }} <span v-if="item.comment_count > 0">({{ item.comment_count
                        }})</span></p>
                    <p class="date">{{ item.created_at }}</p>
                    <div class="lv">
                      <p class="like"><img src="../assets/img/relations_stories_icon.png" />{{ item.likes }}</p>
                      <p class="view">조회수 {{ item.views }}</p>
                    </div>
                  </div>
                </router-link>
              </div>
            </template>
            <template v-else>
              <div class="newsletter_cont_empty t_center">
                <img src="../assets/img/search_icon.png" />
                <p class="title">{{ $t('stories.list_noResult', { keyword: keyword })/* keyword에 대한 검색결과가 없습니다. */ }}
                </p>
                <p class="cont_description">{{ $t("stories.list_tryAgain")/* 다시 시도해보세요. */ }}</p>
                <p class="cont_description btn" @click="resetKeyword">{{ $t("stories.list_reset")/* 검색어 초기화 */ }}</p>
              </div>
            </template>
          </template>
        </div>
        <div class="pagination">
          <button class="page-btn" @click="changePage(currentPage - 1)" :disabled="currentPage === 1"><img
              src="../assets/img/pagination_arrow.png" /></button>
          <template v-for="(page, index) in visiblePages" :key="index">
            <button class="page-btn" v-if="page !== '...'" @click="changePage(page)"
              :class="{ active: page === currentPage }">{{ page }}</button>
            <span v-else>...</span>
          </template>
          <button class="page-btn" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"><img
              class="rotate" src="../assets/img/pagination_arrow.png" /></button>
        </div>
      </div>
    </section>
    <div class="header_bg"></div>
  </div>
</template>
