<script>

export default {
  name: 'hidden-login',
  inject: ['$axios'],
  data() {
    return {
      login_yn: false,
      user_id: null,
      user_pw: null
    }
  },
  mounted() {
    const login = localStorage.getItem('login')
    if (login) {
      this.login_yn = true
    }
  },
  methods: {
    loginConfirm() {
      this.$axios
        .post('https://lexcode.co.kr/app/login', {
          // .post('https://lexcode.co.kr/dev/login', {
          user_id: this.user_id,
          user_pw: this.user_pw
        })
        .then(response => {
          if (response.data === true) {
            // 로그인 성공 시 세션에 저장
            localStorage.setItem('login', this.user_id)
            this.$router.push({
              name: 'relationsStories'
            })
          } else {
            alert('잘못된 데이터입니다.')
          }
        })
    },
    logoutConfirm() {
      localStorage.removeItem('login')
      this.$router.push({
        name: 'relationsStories'
      })
    }
  }
}
</script>

<template>
  <div class="login">
    <template v-if="!login_yn">
      <div>
        <label>ID</label>
        <input type="text" v-model="user_id" @keyup.enter="loginConfirm()" />
      </div>
      <div>
        <label>Password</label>
        <input type="password" v-model="user_pw" @keyup.enter="loginConfirm()" />
      </div>
      <button @click="loginConfirm()">로그인</button>
    </template>
    <template v-else>
      <button @click="logoutConfirm()">로그아웃</button>
    </template>
  </div>
</template>
