<script>
export default {
  name: 'businessJL',
  props: {
    window: Object
  },
  data() {
    return {
      currentTagName: 'aiChat',
      tabList: [
        {
          class: 'aiChat',
          title: 'busi_jl.sec6_title1', // AI 챗봇
          description: 'busi_jl.sec6_desc1-1', // PDF 파일 데이터 및 웹상의 정보를 종합하여 정확한 답변을 제시합니다.
          desc_class: 'block',
          desc_class_en: 'block',
          description2: 'busi_jl.sec6_desc1-2', // 데이터 기반 답변을 제시하기 때문에
          desc_class2: '',
          desc_class2_en: '',
          description3: 'busi_jl.sec6_desc1-3', // 잘못된 정보를 전달하는
          desc_class3: 'bold',
          desc_class3_en: 'bold',
          description4: 'busi_jl.sec6_desc1-4', // 환각(Hallucinations)은 일어나지 않습니다.
          desc_class4: 'bold block',
          desc_class4_en: '',
          style: 'width:601px;',
          video: 'https://cdn.lexcloud.ai/imgs/journal/deepread_sec3_img1.mp4',
          video_en: 'https://cdn.lexcloud.ai/imgs/journal/deepread_sec3_img0_en.mp4',
          label: 'busi_jl.tab1_label' // 자동 번역 완성
        },
        {
          class: 'analyse',
          title: 'busi_jl.sec6_title2', // 논문 분석
          description: 'busi_jl.sec6_desc2-1', // 논문 파일 데이터를 가지고 단계별 인공지능 분석을 통해
          desc_class: 'block',
          desc_class_en: 'block',
          description2: 'busi_jl.sec6_desc2-2', // 핵심 내용 요약, 키워드 추천, 레퍼런스 추천, 연구 제목 추천, 투고 저널 추천
          desc_class2: 'bold',
          desc_class2_en: '',
          description3: 'busi_jl.sec6_desc2-3', // 등 논문 게재에 도움이 되는 다양한 인사이트를 제공합니다.
          desc_class3: '',
          desc_class3_en: 'bold',
          description4: 'busi_jl.sec6_desc2-4',
          desc_class4: '',
          desc_class4_en: '',
          style: 'width:506px;',
          video: 'https://cdn.lexcloud.ai/imgs/journal/deepread_sec3_img2.mp4',
          video_en: 'https://cdn.lexcloud.ai/imgs/journal/deepread_sec3_img1_en.mp4',
          label: 'busi_jl.tab2_label' // 필요 텍스트 자동 생성
        },
        {
          class: 'save',
          title: 'busi_jl.sec6_title3', // 실시간 저장
          description: 'busi_jl.sec6_desc3-1', // DeepRead 2.0을 통해 진행한 논문 분석 결과는 AWS 클라우드 서버에 안전하게 관리됩니다. 
          desc_class: '',
          desc_class_en: '',
          description2: 'busi_jl.sec6_desc3-2', // 실시간으로 데이터가 서버에 자동으로 저장
          desc_class2: 'bold',
          desc_class2_en: 'bold',
          description3: 'busi_jl.sec6_desc3-3', // 되어 따로 저장 버튼을 누르지 않아도 히스토리 추적이 가능합니다.
          desc_class3: '',
          desc_class3_en: '',
          description4: 'busi_jl.sec6_desc3-4',
          desc_class4: '',
          desc_class4_en: '',
          style: 'width:506px;',
          video: 'https://cdn.lexcloud.ai/imgs/journal/deepread_sec3_img3.mp4',
          video_en: 'https://cdn.lexcloud.ai/imgs/journal/deepread_sec3_img2_en.mp4',
          label: 'busi_jl.tab3_label' // 핵심정보 자동 요약
        },
        {
          class: 'library',
          title: 'busi_jl.sec6_title4', // 라이브러리 생성
          description: 'busi_jl.sec6_desc4-1', // 프로젝트 위치를 잊어버리더라도, 쉽게 찾을 수 있도록 
          desc_class: 'block',
          desc_class_en: 'block',
          description2: 'busi_jl.sec6_desc4-2', // 연구 주제나 목적에 맞게 맞춤 라이브러리를 생성
          desc_class2: 'bold',
          desc_class2_en: '',
          description3: 'busi_jl.sec6_desc4-3', // 할 수 있습니다.
          desc_class3: '',
          desc_class3_en: '',
          description4: 'busi_jl.sec6_desc4-4',
          desc_class4: '',
          desc_class4_en: '',
          style: 'width:519px;',
          video: 'https://cdn.lexcloud.ai/imgs/journal/deepread_sec3_img4.mp4',
          video_en: 'https://cdn.lexcloud.ai/imgs/journal/deepread_sec3_img3_en.mp4',
          label: 'busi_jl.tab4_label' // 키워드 자동 추출
        },
        {
          class: 'pdfSave',
          title: 'busi_jl.sec6_title5', // PDF 저장
          description: 'busi_jl.sec6_desc5-1', // 논문 분석 결과를 PDF 파일로 변환하여 제공
          desc_class: 'bold',
          desc_class_en: 'bold',
          description2: 'busi_jl.sec6_desc5-2', // 합니다.
          desc_class2: '',
          desc_class2_en: '',
          description3: 'busi_jl.sec6_desc5-3', // 필요한 결과물은 PDF 파일로 저장한 뒤, 간편하게 확인하고 공유할 수 있습니다.
          desc_class3: 'block',
          desc_class3_en: '',
          description4: 'busi_jl.sec6_desc5-4',
          desc_class4: ' ',
          desc_class4_en: ' ',
          style: 'width:506px;',
          video: 'https://cdn.lexcloud.ai/imgs/journal/deepread_sec3_img5.mp4',
          video_en: 'https://cdn.lexcloud.ai/imgs/journal/deepread_sec3_img4_en.mp4',
          label: 'busi_jl.tab5_label' // 연관성 있는 자료 자동 추출
        }
      ]
    }
  },
  computed: {
    currentList () {
      return this.tabList.filter((el) => el.class === this.currentTagName)
    }
  },
}
</script>
<template>
  <div class="business jl">
    <section class="section1">
      <div class="container flex">
        <div class="left">
          <div class="left_cont">
            <img src="../assets/img/business_jl_section1_img1.png"/>
          </div>
        </div>
        <div class="right">
          <div class="right_cont">
            <div class="flex">
              <p class="business_title">Business</p>
              <p class="business_num"><span>04 </span>— 05</p>
            </div>
            <p class="subtitle">{{$t("busi_jl.sec1_subTitle")/*인공지능 학술논문 분석*/}}</p>
            <h1>{{$t("busi_jl.sec1_contTitle")/*Journal Lab,*/}}<br>{{$t("busi_jl.sec1_contTitle2")/*학술논문 분석 에디팅.*/}}</h1>
            <a href="#" class="main_btn">{{$t("busi_jl.sec1_btn")/*시작하기 →*/}}</a>
          </div>
        </div>
      </div>
    </section>
    <section class="section2">
      <div class="container">
        <div class="text_wrap t_center">
          <p class="sub_text">{{$t("busi_jl.sec2_subTitle")/*We Research Your Research*/}}</p>
          <p class="cont_title">{{$t("busi_jl.sec2_contTitle")/*당신의 연구를 연구합니다*/}}</p>
          <template v-if="window.width > 768">
            <template v-if="$i18n.locale == 'ko'">
              <img src="../assets/img/business_jl_section2_img1.png"/>
            </template>
            <template v-else>
              <img src="../assets/img/business_jl_section2_img1_en.png" alt="">
            </template>
          </template>
          <template v-else>
            <template v-if="$i18n.locale == 'ko'">
              <img src="../assets/img/business_jl_section2_img1_mob.png" alt="">
            </template>
            <template v-else>
              <img src="../assets/img/business_jl_section2_img1_mob_en.png" alt="">
            </template>
          </template>
        </div>
        <div class="flex" :class="$i18n.locale == 'ko' ? '' : 'en'">
          <div>
            <p class="num">01</p>
            <div class="line"></div>
            <p class="sub_title">{{$t("busi_jl.sec2_subTitle1_1")/*수많은 인재와*/}} <strong>{{$t("busi_jl.sec2_subTitle1_2")/*데이터들을 수집*/}}</strong>{{$t("busi_jl.sec2_subTitle1_3")/*하고*/}}</p>
            <ul>
              <li>{{$t("busi_jl.sec2_desc1")/*100만개 이상의 논문 데이터*/}}</li>
              <li>{{$t("busi_jl.sec2_desc2")/*1200명 이상의 전문가*/}}</li>
            </ul>
          </div>
          <div>
            <p class="num">02</p>
            <div class="line"></div>
            <template v-if="$i18n.locale == 'ko'">
              <p class="sub_title"><strong>{{$t("busi_jl.sec2_subTitle2_1")/*독자적인 기술력*/}}</strong>{{$t("busi_jl.sec2_subTitle2_2")/*을 활용하여*/}}</p>
            </template>
            <template v-else>
              <p class="sub_title">{{$t("busi_jl.sec2_subTitle2_1")/*Powered by*/}} <strong>{{$t("busi_jl.sec2_subTitle2_2")/*proprietary technology*/}}</strong></p>
            </template>
            <ul>
              <li>{{$t("busi_jl.sec2_desc3")/*초거대 인공지능 모델*/}}</li>
              <li>{{$t("busi_jl.sec2_desc4")/*자연어 처리 기술(LLM)*/}}</li>
            </ul>
          </div>
          <div>
            <p class="num">03</p>
            <div class="line"></div>
            <template v-if="$i18n.locale == 'ko'">
              <p class="sub_title"><strong>{{$t("busi_jl.sec2_subTitle3_1")/*연구자들이 필요한 서비스*/}}</strong>{{$t("busi_jl.sec2_subTitle3_2")/*를 제공합니다*/}}</p>
            </template>
            <template v-else>
              <p class="sub_title">{{$t("busi_jl.sec2_subTitle3_1")/*Customized*/}} <strong>{{$t("busi_jl.sec2_subTitle3_2")/*services researchers need*/}}</strong></p>
            </template>
            <p>
              <strong class="mr12">{{$t("busi_jl.sec2_desc5")/*DeepRead 2.0*/}}</strong>
              <span>{{$t("busi_jl.sec2_desc6")/*인공지능 논문 분석 챗봇*/}}</span>
            </p>
            <p>
              <strong class="mr12">{{$t("busi_jl.sec2_desc7")/*Xpert Services*/}}</strong>
              <span>{{$t("busi_jl.sec2_desc8")/*연구자 맞춤형 논문 서비스*/}}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="container under">
        <div class="text_wrap t_center">
          <p class="cont_title">{{$t("busi_jl.sec2_contTitle2")/*저널랩은 연구의 새로운 패러다임을 만들어나갑니다*/}}</p>
          <template v-if="window.width > 768">
            <template v-if="$i18n.locale == 'ko'">
              <img src="../assets/img/business_jl_section2_img2.png" alt="">
            </template>
            <template v-else>
              <img src="../assets/img/business_jl_section2_img2_en.png" alt="">
            </template>
          </template>
          <template v-else>
            <template v-if="$i18n.locale == 'ko'">
              <img src="../assets/img/business_jl_section2_img2_mob.png" alt="">
            </template>
            <template v-else>
              <img src="../assets/img/business_jl_section2_img2_mob_en.png" alt="">
            </template>
          </template>
          <p class="cont_description">{{$t("busi_jl.sec2_desc9")/*저널랩은 SCIE 논문 투고의 새로운 패러다임을 제시하며, 더 나아가 학문의 미래를 바꾸는데 앞장서고 있습니다.*/}}</p>
          <strong class="cont_description">{{$t("busi_jl.sec2_desc10")/*저널랩과 함께 논문 작성의 새로운 경험을 만들어 보세요.*/}}</strong>
        </div>
      </div>
    </section>
    <section class="section3" data-aos="fade-down" data-aos-duration="500">
      <div class="container under">
        <div class="text_wrap t_center">
          <template v-if="$i18n.locale == 'ko'">
            <p class="cont_title"><strong>{{$t("busi_jl.sec3_contTitle")/*5단계로*/}}</strong> {{$t("busi_jl.sec3_contTitle2")/*단순화된 솔루션*/}}</p>
          </template>
          <template v-else>
            <p class="cont_title">{{$t("busi_jl.sec3_contTitle")/*Discover our*/}} <strong>{{$t("busi_jl.sec3_contTitle2")/*five-step*/}}</strong> {{$t("busi_jl.sec3_contTitle3")/*simplified solution*/}}</p>
          </template>
          <p class="cont_description pre_line">{{$t("busi_jl.sec3_subTitle")/*Journal Lab 솔루션으로 복잡한 논문 투고 과정을 간편하게*/}}</p>
        </div>
        <div class="process_box opa" :class="$i18n.locale == 'ko' ? '' : 'en'">
          <h3>{{$t("busi_jl.sec3_secTitle1")/*기존 연구 과정*/}}</h3>
          <div class="process_flex">
            <div class="process">{{$t("busi_jl.sec3_secDesc1_1")/*연구 주제 선정*/}}</div>
            <img src="../assets/img/business_jl_section3_arrow.png" width="20" alt="" class="arrow">
            <div class="process">{{$t("busi_jl.sec3_secDesc1_2")/*연구 자료 수집/정리*/}}</div>
            <img src="../assets/img/business_jl_section3_arrow.png" width="20" alt="" class="arrow">
            <div class="process">{{$t("busi_jl.sec3_secDesc1_3")/*연구 수행*/}}</div>
            <img src="../assets/img/business_jl_section3_arrow.png" width="20" alt="" class="arrow">
            <div class="process">{{$t("busi_jl.sec3_secDesc1_4")/*논문 작성*/}}</div>
            <img src="../assets/img/business_jl_section3_arrow.png" width="20" alt="" class="arrow">
            <div class="process">{{$t("busi_jl.sec3_secDesc1_5")/*논문 번역*/}}</div>
            <img src="../assets/img/business_jl_section3_arrow.png" width="20" alt="" class="arrow">
            <div class="process">{{$t("busi_jl.sec3_secDesc1_6")/*논문 에디팅(교정)*/}}</div>
            <img src="../assets/img/business_jl_section3_arrow.png" width="20" alt="" class="arrow">
            <div class="process">{{$t("busi_jl.sec3_secDesc1_7")/*투고 저널 선정*/}}</div>
            <img src="../assets/img/business_jl_section3_arrow.png" width="20" alt="" class="arrow">
            <div class="process">{{$t("busi_jl.sec3_secDesc1_8")/*논문 포매팅*/}}</div>
            <img src="../assets/img/business_jl_section3_arrow.png" width="20" alt="" class="arrow">
            <div class="process active">{{$t("busi_jl.sec3_secDesc1_9")/*SCIE 논문 투고*/}}</div>
          </div>
        </div>
        <div class="process_box">
          <h3>{{$t("busi_jl.sec3_secTitle2")/*저널랩을 사용할 경우*/}}</h3>
          <div class="process_flex withjl">
            <div class="process line">DeepRead 2.0</div>
            <img src="../assets/img/business_jl_section3_arrow.png" width="20" alt="" class="arrow">
            <div class="process">{{$t("busi_jl.sec3_secDesc2_1")/*연구 수행*/}}</div>
            <img src="../assets/img/business_jl_section3_arrow.png" width="20" alt="" class="arrow">
            <div class="process">{{$t("busi_jl.sec3_secDesc2_2")/*논문 작성*/}}</div>
            <img src="../assets/img/business_jl_section3_arrow.png" width="20" alt="" class="arrow">
            <div class="process line">Xpert Services</div>
            <img src="../assets/img/business_jl_section3_arrow.png" width="20" alt="" class="arrow">
            <div class="process active">{{$t("busi_jl.sec3_secDesc2_3")/*SCIE 논문 투고*/}}</div>
          </div>
          <div class="divide_line"></div>
          <div class="flex">
            <div class="service_box">
              <h4>DeepRead 2.0</h4>
              <div class="grid">
                <div class="grid_cont">
                  <img src="../assets/img/business_jl_section3_sec1_1.png" alt="">
                  <p>{{$t("busi_jl.sec3_secDesc3_1")/*AI 챗봇*/}}</p>
                </div>
                <div class="grid_cont">
                  <img src="../assets/img/business_jl_section3_sec1_2.png" alt="">
                  <p>{{$t("busi_jl.sec3_secDesc3_2")/*논문 분석*/}}</p>
                </div>
                <div class="grid_cont">
                  <img src="../assets/img/business_jl_section3_sec1_3.png" alt="">
                  <p>{{$t("busi_jl.sec3_secDesc3_3")/*실시간 저장*/}}</p>
                </div>
                <div class="grid_cont">
                  <img src="../assets/img/business_jl_section3_sec1_4.png" alt="">
                  <p>{{$t("busi_jl.sec3_secDesc3_4")/*라이브러리 생성*/}}</p>
                </div>
              </div>
            </div>
            <div class="service_box">
              <h4>Xpert Services</h4>
              <div class="grid">
                <div class="grid_cont">
                  <img src="../assets/img/business_jl_section3_sec2_1.png" alt="">
                  <p>{{$t("busi_jl.sec3_secDesc3_5")/*번역 패키지*/}}</p>
                </div>
                <div class="grid_cont">
                  <img src="../assets/img/business_jl_section3_sec2_2.png" alt="">
                  <p>{{$t("busi_jl.sec3_secDesc3_6")/*에디팅 패키지*/}}</p>
                </div>
                <div class="grid_cont">
                  <img src="../assets/img/business_jl_section3_sec2_3.png" alt="">
                  <p>{{$t("busi_jl.sec3_secDesc3_7")/*포매팅 패키지*/}}</p>
                </div>
                <div class="grid_cont">
                  <img src="../assets/img/business_jl_section3_sec2_4.png" alt="">
                  <p>{{$t("busi_jl.sec3_secDesc3_8")/*투고 저널 추천*/}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section4">
        <div class="container">
          <div class="text_wrap t_center">
            <p class="sub_text">{{$t("busi_jl.sec4_subText")/*LLM 기반 인공지능 논문 분석 챗봇*/}}</p>
            <p class="cont_title">{{$t("busi_jl.sec2_desc5")/*DeepRead 2.0*/}}</p>
            <p class="cont_description">{{$t("busi_jl.sec4_subTitle")/*한 번의 논문 파일 업로드로 AI 챗봇, 논문 요약, 레퍼런스 추천, 다국어 지원, 핵심 키워드, 논문 분석 결과 다운로드까지*/}}</p>
          </div>
        </div>
        <template v-if="window.width > 768">
          <div class="back_rel">
            <div class="back_line"></div>
            <template v-if="$i18n.locale == 'ko'">
              <img src="../assets/img/business_jl_section4_img1.png" alt="">
            </template>
            <template v-else>
              <img src="../assets/img/business_jl_section4_img1_en.png" alt="">
            </template>
          </div>
        </template>
        <div class="container under">
          <div class="flex">
            <div class="deepread_cont">
              <template v-if="$i18n.locale == 'ko'">
                <img src="../assets/img/business_jl_section4_img2.png"/>
              </template>
              <template v-else>
                <img src="../assets/img/business_jl_section4_img2_en.png"/>
              </template>
              <p>{{$t("busi_jl.sec4_desc1")/*AI 챗봇*/}}</p>
            </div>
            <div class="deepread_cont">
              <img src="../assets/img/business_jl_section4_img3.png"/>
              <p>{{$t("busi_jl.sec4_desc2")/*논문 요약*/}}</p>
            </div>
            <div class="deepread_cont">
              <template v-if="$i18n.locale == 'ko'">
                <img src="../assets/img/business_jl_section4_img4.png"/>
              </template>
              <template v-else>
                <img src="../assets/img/business_jl_section4_img4_en.png"/>
              </template>
              <p>{{$t("busi_jl.sec4_desc3")/*레퍼런스 추천*/}}</p>
            </div>
            <div class="deepread_cont">
              <template v-if="$i18n.locale == 'ko'">
                <img src="../assets/img/business_jl_section4_img5.png"/>
              </template>
              <template v-else>
                <img src="../assets/img/business_jl_section4_img5_en.png"/>
              </template>
              <p>{{$t("busi_jl.sec4_desc4")/*다국어 지원*/}}</p>
            </div>
            <div class="deepread_cont">
              <template v-if="$i18n.locale == 'ko'">
                <img src="../assets/img/business_jl_section4_img6.png"/>
              </template>
              <template v-else>
                <img src="../assets/img/business_jl_section4_img6_en.png"/>
              </template>
              <p>{{$t("busi_jl.sec4_desc5")/*핵심 키워드*/}}</p>
            </div>
            <div class="deepread_cont">
              <img src="../assets/img/business_jl_section4_img7.png"/>
              <p>{{$t("busi_jl.sec4_desc6")/*논문 분석 결과 다운로드*/}}</p>
            </div>
          </div>
          <div class="t_center">
            <a href="https://journallab.com/deepread/deepread.nx" class="t_center flex toggle_btn" :class="(sizeMode === 'mobile' || sizeMode === 'tablet') ? 'mt32' : 'mt60'" onclick="openLog('../deepread/deepread.nx')">
              <span class="toggle_ball">></span>
              <span>{{$t("busi_jl.sec4_btn")/*DeepRead 이용하기*/}}</span>
            </a>
          </div>
        </div>
    </section>
    <section class="section5 t_white">
      <div class="container">
        <div class="text_wrap t_center">
          <p class="cont_title">{{$t("busi_jl.sec5_contTitle")/*기술이 리드하니까,*/}}</p>
          <p class="cont_description t_white"><strong>{{$t("busi_jl.sec5_subTitle1")/*RAG(Retrieval-Augmented Generation) 기술*/}}</strong>{{$t("busi_jl.sec5_subTitle2")/*로*/}}</p>
          <p class="cont_description t_white mb60">{{$t("busi_jl.sec5_subTitle3")/*DeepRead 2.0의 정보 검색과 생성형 AI 모델을 결합하여 질문에 대한 답변을 생성합니다.*/}}</p>
          <div class="flex width_limit">
            <div>
              <div class="sec5_grid left_grid">
                <p>{{$t("busi_jl.sec5_cont1_1")/*유저 논문 업로드*/}}</p>
                <p>{{$t("busi_jl.sec5_cont1_2")/*Chunk 분할*/}}</p>
                <div class="flex">{{$t("busi_jl.sec5_cont1_3")/*PDF 원문*/}}</div>
                <div>
                  <p class="grid_inner">Chunk_1</p>
                  <p class="grid_inner">Chunk_2</p>
                  <p class="grid_inner">Chunk_3</p>
                  <p class="grid_inner">Chunk_4</p>
                  <p><img src="../assets/img/business_jl_section5_img1.png" width="25" alt=""></p>
                  <p class="grid_inner">Chunk_5</p>
                </div>
              </div>
            </div>
            <template v-if="window.width > 1280">
              <img src="../assets/img/business_jl_section5_img2.png" width="80" height="80" alt="" style="align-self: center;">
            </template>
            <template v-else>
              <img src="../assets/img/business_jl_section5_img5.png" width="80" height="80" alt="" style="align-self: center;">
            </template>
            <div class="flex column">
              <div class="sec5_grid right_grid">
                <p class="ttl">{{$t("busi_jl.sec5_cont2_1")/*논문 분석*/}}</p>
                <div class="cont pre_line">{{$t("busi_jl.sec5_cont2_2")/*분석 기준에 맞게 해당 논문의 분석에필요한 부분 추출 후 분석*/}}</div>
              </div>
              <div class="sec5_grid right_grid">
                <p class="ttl">{{$t("busi_jl.sec5_cont2_3")/*인공지능 챗봇*/}}</p>
                <div class="cont pre_line">{{$t("busi_jl.sec5_cont2_4")/*입력과 가장 유사한 문서의 chunk를 추출하여 후보군 생성*/}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container under">
        <div class="text_wrap t_center">
          <p class="cont_description t_white">{{$t("busi_jl.sec5_desc1")/*기존 단일 생성형 AI를 사용하는 것인 아닌 RAG를 접목시켜*/}}</p>
          <p class="cont_description t_white mb60">{{$t("busi_jl.sec5_desc2")/*외부 정보를 효과적으로 활용하여*/}} <strong>{{$t("busi_jl.sec5_desc3")/*답변의 정확성과 다양성을 향상시키고, 사용자 맞춤형 정보를 제공*/}}</strong>{{$t("busi_jl.sec5_desc4")/*할 수 있습니다*/}}</p>
          <div class="flex column">
            <div class="img_wrap">
              <template v-if="window.width > 768">
                <template v-if="$i18n.locale == 'ko'">
                  <img src="../assets/img/business_jl_section5_img3.png"/>
                </template>
                <template v-else>
                  <img src="../assets/img/business_jl_section5_img3_en.png"/>
                </template>
              </template>
              <template v-else>
                <template v-if="$i18n.locale == 'ko'">
                  <img src="../assets/img/business_jl_section5_img3_mob.png"/>
                </template>
                <template v-else>
                  <img src="../assets/img/business_jl_section5_img3_mob_en.png"/>
                </template>
              </template>
            </div>
            <div class="img_wrap">
              <img src="../assets/img/business_jl_section5_img5.png"/>
            </div>
            <div class="flex gap56">
              <p class="sec5_box">{{$t("busi_jl.sec5_box1")/*답변의 정확성 향상​*/}}</p>
              <p class="sec5_box">{{$t("busi_jl.sec5_box2")/*다양한 답변 제공​*/}}</p>
              <p class="sec5_box">{{$t("busi_jl.sec5_box3")/*사용자 맞춤형 정보 제공​*/}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section6">
      <div class="container" data-aos="fade-down" data-aos-duration="500">
        <div class="text_wrap t_center">
          <p class="cont_title">{{$t("busi_jl.sec6_contTitle")/*논문에서 필요한 데이터만 추출하여*/}}</p>
          <p class="cont_title"><strong>{{$t("busi_jl.sec6_contTitle2")/*이 모든 것을 한 번에*/}}</strong></p>
          <p class="cont_description mt24">{{$t("busi_jl.sec6_desc1")/*인공지능 기술로 논문을 조각내어 필요한 데이터만 추출하여 정확한 정보를 제공할 수 있습니다.*/}}</p>
          <p class="cont_description">{{$t("busi_jl.sec6_desc2")/*DeepRead 2.0에서 일일이 논문을 찾고, 용어를 검색하고, 처음부터 끝까지 논문을 읽고 번역기를 돌리는 일은 이제 필요 없습니다*/}}</p>
        </div>
        <template v-if="window.width > 768">
          <div class="sec6_tab_wrap">
              <p class="sec6_tab" v-for="(item, index) in tabList" :key="index"
                :class="{ active: item.class === currentTagName }" @click="currentTagName = item.class;">
                {{$t(item.title)}}
              </p>
          </div>
        </template>
        <template v-else>
          <div class="overflow">
            <div class="sec6_tab_wrap">
              <div class="scroll-container">
                <p class="sec6_tab" v-for="(item, index) in tabList" :key="index"
                  :class="{ active: item.model === currentTagName }" @click="currentTagName = item.model">
                  {{$t(item.title)}}
                </p>
              </div>
            </div>
          </div>
        </template>
        <div class="sec6_cont_wrap" v-for="(item, index) in tabList" :key="index" v-show="currentTagName == item.class">
          <div class="flex">
            <div class="video_wrap">
              <video autoplay muted loop v-show="$i18n.locale == 'ko'">
                <source :src="item.video" type="video/mp4" />
              </video>
              <video autoplay muted loop v-show="$i18n.locale == 'en'">
                <source :src="item.video_en" type="video/mp4" />
              </video>
            </div>
            <div class="text_wrap">
                <p class="cont_title">{{$t(item.title)}}</p>
                <p class="cont_description" :style="window.width > 1280 ? item.style : ''">
                  <template v-if="$i18n.locale == 'ko'">
                    <span :class="item.desc_class">{{$t(item.description)}}</span> <span :class="item.desc_class2">{{$t(item.description2)}}</span> <span :class="item.desc_class3">{{$t(item.description3)}}</span> <span :class="item.desc_class4">{{$t(item.description4)}}</span>
                  </template>
                  <template v-else>
                    <span :class="item.desc_class_en">{{$t(item.description)}}</span> <span :class="item.desc_class2_en">{{$t(item.description2)}}</span> <span :class="item.desc_class3_en">{{$t(item.description3)}}</span> <span :class="item.desc_class4_en">{{$t(item.description4)}}</span>
                  </template>
                </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container under">
        <div class="text_wrap t_center">
          <p class="cont_title">{{$t("busi_jl.sec6_contTitle3")/*논문 의뢰도*/}}</p>
          <p class="cont_title">{{$t("busi_jl.sec6_contTitle4")/*간편하게, Xpert Services*/}}</p>
        </div>
        <div class="flex">
          <div class="flex_cont">
            <div class="img_wrap">
              <img src="../assets/img/business_jl_section7_img1.png" alt="">
            </div>
            <p class="text_wrap">
              <img src="../assets/img/business_jl_section7_num1.png" alt="">
              <span>{{$t("busi_jl.sec6_desc3")/*논문 파일 업로드*/}}</span>
            </p>
          </div>
          <div class="flex_cont">
            <div class="img_wrap">
              <img src="../assets/img/business_jl_section7_img2.png" alt="">
            </div>
            <p class="text_wrap">
              <img src="../assets/img/business_jl_section7_num2.png" alt="">
              <span>{{$t("busi_jl.sec6_desc4")/*실시간 예상 견적 확인*/}}</span>
            </p>
          </div>
          <div class="flex_cont">
            <div class="img_wrap">
              <img src="../assets/img/business_jl_section7_img3.png" alt="">
            </div>
            <p class="text_wrap">
              <img src="../assets/img/business_jl_section7_num3.png" alt="">
              <span>{{$t("busi_jl.sec6_desc5")/*논문 의뢰*/}}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="container under">
        <div class="text_wrap t_center">
          <p class="cont_title">{{$t("busi_jl.sec6_contTitle5")/*Xpert Services는*/}}</p>
          <p class="cont_title">{{$t("busi_jl.sec6_contTitle6")/*1,000명이 넘는 국내 · 외 전문가들과 함께합니다*/}}</p>
        </div>
        <div class="flex stretch">
          <div class="service_wrap">
            <h3 style="color: #0093FF;">{{$t("busi_jl.sec6_subTitle1")/*번역 패키지*/}}</h3>
            <p class="cont_subTitle">{{$t("busi_jl.sec6_subTitle1_en")/*Translation Package*/}}</p>
            <p class="cont_sub_text">{{$t("busi_jl.sec6_subText1")/*전문 번역+감수*/}}</p>
            <p class="cont_description">{{$t("busi_jl.sec6_desc6")/*SCIE 저널의 논문 데이터를 학습한 인공지능과 인간 번역사의 지성을 통해, 저자님의 의도와 메시지를 언어별로 정확하게 전달하세요.*/}}</p>
          </div>
          <div class="service_wrap">
            <h3 style="color: #F99500;">{{$t("busi_jl.sec6_subTitle2")/*에디팅 패키지*/}}</h3>
            <p class="cont_subTitle">{{$t("busi_jl.sec6_subTitle2_en")/*Editing Package*/}}</p>
            <p class="cont_sub_text">{{$t("busi_jl.sec6_subText2")/*에디팅+포매팅*/}}</p>
            <p class="cont_description">{{$t("busi_jl.sec6_desc7")/*논리성, 명료성, 일관성을 모두 갖춘 완성도 높은 논문 교정을 경험하세요. 포매팅, 추가적인 애프터 서비스까지 모두 지원합니다.*/}}</p>
          </div>
          <div class="service_wrap">
            <h3 style="color: #00C068;">{{$t("busi_jl.sec6_subTitle3")/*포매팅 패키지*/}}</h3>
            <p class="cont_subTitle">{{$t("busi_jl.sec6_subTitle3_en")/*Formatting Package*/}}</p>
            <p class="cont_sub_text">{{$t("busi_jl.sec6_subText3")/*포매팅+투고*/}}</p>
            <p class="cont_description">{{$t("busi_jl.sec6_desc8")/*저널의 규정을 세세하게 확인하고 서식을 맞추며, 추가적인 문서를 챙겨야 하는 번거로운 작업들을 전부 저널랩에게 맡기세요.*/}}</p>
          </div>
          <div class="service_wrap">
            <h3 style="color: #1150CA;">{{$t("busi_jl.sec6_subTitle4")/*저널 추천*/}}</h3>
            <p class="cont_subTitle">{{$t("busi_jl.sec6_subTitle4_en")/*Journal Selection*/}}</p>
            <p class="cont_sub_text">{{$t("busi_jl.sec6_subText4")/*투고 저널 추천*/}}</p>
            <p class="cont_description">{{$t("busi_jl.sec6_desc9")/*내 연구 논문의 가치를 알아주는 맞춤형 저널을 찾아보세요. 저널의 피인용지수, 발행 빈도, 목표와 범위 등 저자님의 상황과 조건에 맞춰 맞춤형 분석을 진행합니다.*/}}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section8">
      <div class="container">
        <div class="text_wrap t_center t_white">
          <p class="cont_title">{{$t("busi_jl.sec2_contTitle")/* 당신의 연구를 연구합니다 */}}</p>
          <p class="cont_description">{{$t("busi_jl.sec7_desc")/* 연구자님은 연구에만 집중하세요. */}}<br>{{$t("busi_jl.sec7_desc2")/* 저널랩은 그 지식이 더 많은 사람들에게 도움을 주는 데 집중하겠습니다. */}}</p>
          <a href="https://journallab.com" target="_blank" class="main_btn">{{$t("busi_jl.sec7_btn")/* 저널랩 바로가기 → */}}</a>
        </div>
      </div>
    </section>
    <div class="header_bg"></div>
  </div>
</template>
