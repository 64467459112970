<script>
export default {
  name: 'businessLexcloud',
  props: {
    window: Object
  },
  data() {
    return {
      applyShadow: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      // 헤더 요소 가져오기
      const header = document.querySelector('.theader')
      // 헤더와 페이지 맨 위 사이의 거리 계산
      const distanceFromTop = header.getBoundingClientRect().top
      // 헤더가 페이지 맨 위에 있으면 그림자 클래스 추가
      this.applyShadow = distanceFromTop === 0
    }
  }
}
</script>

<template>
  <div class="business lexcloud">
    <section class="section1">
      <div class="container flex">
        <div class="left">
          <div class="left_cont">
            <img src="../assets/img/business_lexcloud_section1_img1.png" />
            <img src="../assets/img/business_lexcloud_section1_img2.png" />
            <img src="../assets/img/business_lexcloud_section1_img3.png" />
          </div>
        </div>
        <div class="right">
          <div class="right_cont">
            <div class="flex">
              <p class="business_title">Business</p>
              <p class="business_num"><span>01 </span>— 05</p>
            </div>
            <p class="subtitle">{{$t("menu.lc_subtitle")/* 번역 · 언어데이터 */}}</p>
            <h1>{{$t("busi_lc.sec1_title")/* 전문적인 번역이 필요하다면, */}}<br>{{$t("busi_lc.sec1_title2")/* 렉스클라우드. */}}</h1>
            <a href="https://lexcloud.ai" target="_blank" class="main_btn">{{$t("busi_lc.sec1_btn")/* 시작하기 → */}}</a>
          </div>
        </div>
      </div>
    </section>
    <section class="section2">
      <div class="container t_white">
        <p class="cont_title t_center">{{$t("busi_lc.sec2_contTitle")/* 총 */}} <span class="t_purple">5,600</span>{{$t("busi_lc.sec2_contTitle2")/* 개의 프로젝트가 영어로 완성되었습니다. */}}</p>
        <div class="flex">
          <div class="text_wrap">
            <p class="num_label">{{$t("busi_lc.sec2_num_ttl")/* 진행 중인 프로젝트 */}}</p>
            <p class="num">{{$t("busi_lc.sec2_num")/* 200개 + */}}</p>
          </div>
          <div class="text_wrap">
            <p class="num_label">{{$t("busi_lc.sec2_num_ttl2")/* 전문 번역사 */}}</p>
            <p class="num">{{$t("busi_lc.sec2_num2")/* 124명 + */}}</p>
          </div>
        </div>
        <div class="flex">
          <div class="text_wrap">
            <p class="num_label">{{$t("busi_lc.sec2_num_ttl3")/* 함께 한 고객 수  */}}</p>
            <p class="num">{{$t("busi_lc.sec2_num3")/* 5,798명 + */}}</p>
          </div>
          <div class="text_wrap">
            <p class="num_label">{{$t("busi_lc.sec2_num_ttl4")/* 언어 데이터 */}}</p>
            <p class="num">{{$t("busi_lc.sec2_num4")/* 13,152개 + */}}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section3">
      <div class="container">
        <p class="cont_title t_center" data-aos="fade-down" data-aos-duration="500">{{$t("busi_lc.sec3_contTitle")/* 번역은 물론, 언어 데이터 축적과 파일 관리까지 */}}</p>
        <div class="flex" data-aos="fade-down" data-aos-duration="500">
          <p class="cont_description">{{$t("busi_lc.sec3_desc")/* 렉스클라우드로 번역을 진행해 보세요. */}}<br>{{$t("busi_lc.sec3_desc2")/* 로컬리제이션 담당자의 관리시간과 노력을 최소 50% 이상 줄여 줍니다. */}}</p>
          <p class="cont_description">{{$t("busi_lc.sec3_desc3")/* 번역 진행 상황은 실시간으로 확인할 수 있고, 메시지로 이메일이나 전화보다 빠르고 편하게 소통할 수 있죠. 뿐만 아니라 귀사만의 소중한 언어데이터까지 차곡차곡 쌓아 드립니다.+ */}}<br>{{$t("busi_lc.sec3_desc4") }}</p>
        </div>
        <template v-if="$i18n.locale == 'ko'"><img data-aos="fade-down" data-aos-duration="500" src="../assets/img/business_lexcloud_section3_img1.png" /></template>
        <template v-else><img data-aos="fade-down" data-aos-duration="500" src="../assets/img/business_lexcloud_section3_en_img1.png" /></template>
      </div>
    </section>
    <section class="section4">
      <div class="news_box">
        <div class="container">
          <p class="cont_title t_center">
            {{$t("busi_lc.sec4_contTitle")/* 번역, 왜 */}} <span class="t_purple">{{$t("busi_lc.sec4_contTitle2")/* 렉스클라우드 */}}</span> {{$t("busi_lc.sec4_contTitle3")/* 인가요? */}}
          </p>
          <div class="flex">
            <img src="../assets/img/business_lexcloud_section4_img1.png" />
            <div class="text_wrap">
              <p class="t_500">Newsletter</p>
              <p class="news_box_title">{{$t("busi_lc.sec4_boxTitle1")/* 번역, 고민할 필요 없이 렉스클라우드 하나로 정리하면 되는 이유 */}}<br>{{$t("busi_lc.sec4_boxTitle2")/*     "sec4_boxTitle2": "", */}}</p>
              <p class="cont_description">
                "{{$t("busi_lc.sec4_boxDesc")/* 번역은 고민하지 말고 렉스클라우드 하나로 정리하세요. */}}"<br>{{$t("busi_lc.sec4_boxDesc2")/* 사장이 자기 제품 좋다고 하는 말을 어떤 소비자가 믿겠는가? ‘고슴도치도 제 새끼는 함함하다’는 속담이 있듯이, 그다지 신빙성이 없다고 할 수도 있다. 하지만 개중에는 정말 사장이니까 알고 있는 내밀한 내용도 있을 것이고, ... */}}
              </p>
              <a href="https://lexcode.co.kr/relations/stories/436" class="main_btn">{{$t("busi_lc.sec4_boxBtn")/* 뉴스레터 확인하기 → */}}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section5">
      <template v-if="window.width > 768">
        <div class="container">
          <p class="cont_title t_center">{{$t("busi_lc.sec5_contTitle")/* 렉스클라우드 vs 일반번역 절차를 비교해 보세요. */}}</p>
          <div class="table">
            <div class="tr">
              <p class="td disnone">{{$t("busi_lc.sec5_t1_1")/* 항목 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t1_2")/* 개인/기업 계정 페이지 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t1_3")/* 견적 시간 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t1_4")/* 번역 진행 수단 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t1_5")/* 진행 단계 확인 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t1_6")/* 파일 버전 관리 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t1_7")/* 작업 히스토리 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t1_8")/* 고객 언어 데이터 생성 */}}<span> {{$t("busi_lc.sec5_t1_9")/* (TM/TB) */}}</span></p>
              <p class="td">{{$t("busi_lc.sec5_t1_10")/* 파일저장소 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t1_11")/* 보안/백업 */}}</p>
            </div>
            <div class="tr">
              <p class="td">{{$t("busi_lc.sec5_t2_1")/* LEX-Cloud */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t2_2")/* 있음 */}}</p>
              <p class="td comment">{{$t("busi_lc.sec5_t2_3")/* 즉시 - 30분 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t2_4")/* 렉스클라우드, 렉클톡 */}}<span> {{$t("busi_lc.sec5_t2_5")/* (자체 메신저) */}}</span></p>
              <p class="td">{{$t("busi_lc.sec5_t2_6")/* 자동 추적 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t2_7")/* 자동 저장 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t2_8")/* 자동 저장 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t2_9")/* 자동, 무료 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t2_10")/* 클라우드 서버 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t2_11")/* 강함 */}}</p>
            </div>
            <div class="tr">
              <p class="td th">{{$t("busi_lc.sec5_t3_1")/* 일반 번역회사 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t3_2")/* 없음 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t3_3")/* 3일 - 4일 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t3_4")/* 전화, 이메일 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t3_5")/* 수동 입력/확인 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t3_6")/* 수동 작업 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t3_7")/* 없음 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t3_8")/* 없음, 유료 */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t3_9")/* 개인 PC */}}</p>
              <p class="td">{{$t("busi_lc.sec5_t3_10")/* 약함 */}}</p>
            </div>
            <div class="text_wrap"><p class="cont_description">{{$t("busi_lc.sec5_desc")/* 영업일 기준이며, 텍스트가 추출되지 않는 특수한 확장자 문서는 시간이 더 소요될 수 있습니다. */}}</p></div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="container">
          <p class="cont_title t_center">{{$t("busi_lc.sec5_contTitle")/* 렉스클라우드 vs 일반번역 절차를 비교해 보세요. */}}</p>
        </div>
        <div class="mob_table">
          <div class="theader" :class="{ shadow: applyShadow }">
            <p class="tab t_purple">{{$t("busi_lc.sec5_t2_1")/* LEX-Cloud */}}</p>
            <p class="tab">{{$t("busi_lc.sec5_t3_1")/* 일반 번역회사 */}}</p>
          </div>
          <p class="sec5_subtitle">{{$t("busi_lc.sec5_t1_2")/* 개인/기업 계정 페이지 */}}</p>
          <div class="flex">
            <p class="cont_description">{{$t("busi_lc.sec5_t2_2")/* 있음 */}}</p>
            <p class="cont_description">{{$t("busi_lc.sec5_t3_2")/* 없음 */}}</p>
          </div>
          <p class="sec5_subtitle">{{$t("busi_lc.sec5_t1_3")/* 견적 시간 */}}</p>
          <div class="flex">
            <p class="cont_description comment">{{$t("busi_lc.sec5_t2_3")/* 즉시 - 30분 */}}</p>
            <p class="cont_description">{{$t("busi_lc.sec5_t3_3")/* 3일 - 4일 */}}</p>
            <p class="cont_description w100">* {{$t("busi_lc.sec5_desc")/* 영업일 기준이며, 텍스트가 추출되지 않는 특수한 확장자 문서는 시간이 더 소요될 수 있습니다. */}}</p>
          </div>
          <p class="sec5_subtitle">{{$t("busi_lc.sec5_t1_4")/* 번역 진행 수단 */}}</p>
          <div class="flex">
            <p class="cont_description">{{$t("busi_lc.sec5_t2_4_1")/* 렉스클라우드 */}}<br>{{$t("busi_lc.sec5_t2_4_2")/* 렉클톡(자체 메신저) */}}</p>
            <p class="cont_description">{{$t("busi_lc.sec5_t3_4")/* 전화, 이메일 */}}</p>
          </div>
          <p class="sec5_subtitle">{{$t("busi_lc.sec5_t1_5")/* 진행 단계 확인 */}}</p>
          <div class="flex">
            <p class="cont_description">{{$t("busi_lc.sec5_t2_6")/* 자동 추적 */}}</p>
            <p class="cont_description">{{$t("busi_lc.sec5_t3_5")/* 수동 입력/확인 */}}</p>
          </div>
          <p class="sec5_subtitle">{{$t("busi_lc.sec5_t1_6")/* 파일 버전 관리 */}}</p>
          <div class="flex">
            <p class="cont_description">{{$t("busi_lc.sec5_t2_7")/* 자동 저장 */}}</p>
            <p class="cont_description">{{$t("busi_lc.sec5_t3_6")/* 수동 작업 */}}</p>
          </div>
          <p class="sec5_subtitle">{{$t("busi_lc.sec5_t1_7")/* 작업 히스토리 */}}</p>
          <div class="flex">
            <p class="cont_description">{{$t("busi_lc.sec5_t2_8")/* 자동 저장 */}}</p>
            <p class="cont_description">{{$t("busi_lc.sec5_t3_7")/* 없음 */}}</p>
          </div>
          <p class="sec5_subtitle">{{$t("busi_lc.sec5_t1_8")/* 고객 언어 데이터 생성 */}} {{$t("busi_lc.sec5_t1_9")/* (TM/TB) */}}</p>
          <div class="flex">
            <p class="cont_description">{{$t("busi_lc.sec5_t2_9")/* 자동, 무료 */}}</p>
            <p class="cont_description">{{$t("busi_lc.sec5_t3_8")/* 없음, 유료 */}}</p>
          </div>
          <p class="sec5_subtitle">{{$t("busi_lc.sec5_t1_10")/* 파일저장소 */}}</p>
          <div class="flex">
            <p class="cont_description">{{$t("busi_lc.sec5_t2_10")/* 클라우드 서버 */}}</p>
            <p class="cont_description">{{$t("busi_lc.sec5_t3_9")/* 개인 PC */}}</p>
          </div>
          <p class="sec5_subtitle">{{$t("busi_lc.sec5_t1_11")/* 보안/백업 */}}</p>
          <div class="flex">
            <p class="cont_description">{{$t("busi_lc.sec5_t2_11")/* 강함 */}}</p>
            <p class="cont_description">{{$t("busi_lc.sec5_t3_10")/* 약함 */}}</p>
          </div>
        </div>
      </template>
    </section>
    <section class="section10">
      <div class="container">
        <p class="sec10_badge">{{$t("busi_lc.sec6_badge")/* KSA 한국 표준협회 */}}</p>
        <p class="cont_title t_center">{{$t("busi_lc.sec6_contTitle")/* 렉스코드는 품질경영 시스템 인증 기업입니다. */}}</p>
        <div class="flex">
          <img src="../assets/img/business_lexcloud_section10_img1.png" />
          <img src="../assets/img/business_lexcloud_section10_img2.png" />
        </div>
        <p class="cont_description t_center">{{$t("busi_lc.sec6_desc")/* 2022년 ISO 9001 번역 품질관리 인증 획득 [NTQ-2147] */}}</p>
      </div>
    </section>
    <section class="section12">
      <div class="text_wrap t_center t_white">
        <p class="cont_title">{{$t("busi_lc.sec7_contTitle")/* With LEX-Cloud */}}</p>
        <p class="cont_description">{{$t("busi_lc.sec7_desc")/* 렉스클라우드에서 Friction 없는 번역 업무를 시작하세요. */}}</p>
        <a href="https://lexcloud.ai" target="_blank" class="main_btn">{{$t("busi_lc.sec7_btn")/* LEX-Cloud 이용하기  */}}</a>
      </div>
    </section>
    <div class="header_bg"></div>
  </div>
</template>
