<script>
import MarqueeText from 'vue-marquee-text-component'
import { ref, onMounted } from 'vue'
import { gsap, ScrollTrigger } from 'gsap/all'
gsap.registerPlugin(ScrollTrigger)
export default {
  // name: 'relationsRecruit',
  props: {
    window: Object
  },
  components: {
    MarqueeText
  },
  data() {
    return {
      particles: 50,
      width: 500,
      height: 500,
      isDropdownOpen: false,
      selectedOption: {
        tab: 'recruit.teamTab1',
        class: 'pl',
        title: 'recruit.teamTitle1',
        description: 'recruit.teamDesc1'
      },
      items: [
        {
          subtitle: 'recruit.itemsTitle1',
          description: 'recruit.itemsDesc1'
        },
        {
          subtitle: 'recruit.itemsTitle2',
          description: 'recruit.itemsDesc2'
        },
        {
          subtitle: 'recruit.itemsTitle3',
          description: 'recruit.itemsDesc3'
        },
        {
          subtitle: 'recruit.itemsTitle4',
          description: 'recruit.itemsDesc4'
        }
      ],
      currentClass: 'pl',
      team_info: [
        {
          tab: 'recruit.teamTab1',
          class: 'pl',
          title: 'recruit.teamTitle1',
          description: 'recruit.teamDesc1'
        },
        {
          tab: 'recruit.teamTab2',
          class: 'pm',
          title: 'recruit.teamTitle2',
          description: 'recruit.teamDesc2'
        },
        {
          tab: 'recruit.teamTab3',
          class: 'vendor',
          title: 'recruit.teamTitle3',
          description: 'recruit.teamDesc3'
        },
        {
          tab: 'recruit.teamTab4',
          class: 'acc',
          title: 'recruit.teamTitle4',
          description: 'recruit.teamDesc4'
        },
        {
          tab: 'recruit.teamTab5',
          class: 'hr',
          title: 'recruit.teamTitle5',
          description: 'recruit.teamDesc5'
        },
        {
          tab: 'recruit.teamTab6',
          class: 'dtp',
          title: 'recruit.teamTitle6',
          description: 'recruit.teamDesc6'
        },
        {
          tab: 'recruit.teamTab7',
          class: 'atpe',
          title: 'recruit.teamTitle7',
          description: 'recruit.teamDesc7'
        },
        {
          tab: 'recruit.teamTab8',
          class: 'tech',
          title: 'recruit.teamTitle8',
          description: 'recruit.teamDesc8'
        },
        {
          tab: 'recruit.teamTab9',
          class: 'design',
          title: 'recruit.teamTitle9',
          description: 'recruit.teamDesc9'
        },
        {
          tab: 'recruit.teamTab10',
          class: 'dev',
          title: 'recruit.teamTitle10',
          description: 'recruit.teamDesc10'
        },
        {
          tab: 'recruit.teamTab11',
          class: 'mkt',
          title: 'recruit.teamTitle11',
          description: 'recruit.teamDesc11'
        },
        {
          tab: 'recruit.teamTab12',
          class: 'deep',
          title: 'recruit.teamTitle12',
          description: 'recruit.teamDesc12'
        }
      ],
      activeIndex: -1 // 활성화된 요소의 인덱스를 저장할 변수
    }
  },
  methods: {
    addActiveClass(index) {
      this.activeIndex = index
    },
    removeActiveClass() {
      this.activeIndex = -1
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
    },
    selectOption(option) {
      this.selectedOption = option
      this.currentClass = option.class
      this.isDropdownOpen = true
    }
  },
  computed: {
    boxShadow() {
      return (index) => {
        let boxShadow = ''
        for (let i = 0; i <= index; i++) {
          const x = Math.random() * this.width - this.width / 2
          const y = Math.random() * this.height - this.height / 1.2
          const color = `hsl(${Math.random() * 360}, 100%, 50%)`
          boxShadow += `${x}px ${y}px ${color}, `
        }
        return `${boxShadow}0 0 #fff`
      }
    },
    currentList() {
      return this.team_info.filter((el) => el.class === this.currentClass)
    }
  },
  setup() {
    const links = ref([
      { id: 1, text: '지원하기' }
    ])
    const followerRef = ref(null)

    const handProps = { n: 0, x: 0, dir: 1 }

    onMounted(() => {
      // console.log(followerRef.value)
      gsap.set(followerRef.value, { xPercent: -50, yPercent: -55, scale: 0 })
    })

    const over = (e, link) => {
      gsap.fromTo(
        followerRef.value,
        { x: e.x, y: e.y, scale: 0 },
        { scale: 0.5, ease: 'back(3)' }
      )
      gsap.fromTo(
        '.hand',
        { backgroundPosition: '0px ' + handProps.n * 300 + 'px' },
        { duration: 0.67, backgroundPositionX: '-8700px', ease: 'steps(29)' }
      )
    }

    const out = (e, link) => {
      handProps.n++
      if (handProps.n > 2) handProps.n = 0
      gsap.to(followerRef.value, { scale: 0, duration: 0.4, ease: 'back.in', overwrite: 'auto', opacity: 1 })
      gsap.fromTo('.hand', { backgroundPositionX: '-8700px' }, { backgroundPositionX: '0px', ease: 'steps(29)', overwrite: 'auto' })
    }

    const move = (e, link) => {
      handProps.x = gsap.getProperty(followerRef.value, 'x')
      handProps.dir = e.x > handProps.x ? -1 : 1
      gsap.to(followerRef.value, { y: e.y })
      gsap.to(followerRef.value, {
        x: e.x,
        duration: 1,
        ease: 'expo',
        onUpdate: () => {
          const rot = Math.abs(e.x - gsap.getProperty(followerRef.value, 'x')) / 100
          gsap.set('.hand', { rotation: rot * handProps.dir })
        }
      })
    }
    return {
      links,
      followerRef,
      over,
      out,
      move
    }
  }
}
</script>
<template>
  <div class="relations recruit">
    <section class="section1">
      <div class="flex">
        <div class="left_cont" v-if="window.width > 768">
          <img class="rotate" src="../assets/img/relations_recruit_section1_img2.png" />
          <div class="sns">
            <a class="sns_icon" href="https://www.facebook.com/lexcodekr/" target="_blank">
              <img src="../assets/img/relations_recruit_section1_img3.png" />
            </a>
            <a class="sns_icon" href="https://www.instagram.com/lexcode_korea/" target="_blank">
              <img src="../assets/img/relations_recruit_section1_img4.png" />
            </a>
            <a class="sns_icon" href="https://www.youtube.com/channel/UCBafCU8zlgmQxNwFuwCwFyg" target="_blank">
              <img src="../assets/img/relations_recruit_section1_img5.png" />
            </a>
            <a class="sns_icon" href="https://blog.naver.com/lexcode_kr" target="_blank">
              <img src="../assets/img/relations_recruit_section1_img6.png" />
            </a>
          </div>
        </div>
        <div class="right_cont">
          <h1>We're<br>LEXCODIAN</h1>
          <img src="../assets/img/relations_recruit_section1_img1.png" />
        </div>
        <div class="sns" v-if="window.width < 769">
          <a class="sns_icon" href="https://www.instagram.com/lexcode_korea/" target="_blank">
            <img src="../assets/img/relations_recruit_section1_img3.png" />
          </a>
          <a class="sns_icon" href="https://www.facebook.com/lexcodekr/" target="_blank">
            <img src="../assets/img/relations_recruit_section1_img4.png" />
          </a>
          <a class="sns_icon" href="https://www.youtube.com/channel/UCBafCU8zlgmQxNwFuwCwFyg" target="_blank">
            <img src="../assets/img/relations_recruit_section1_img5.png" />
          </a>
          <a class="sns_icon" href="https://blog.naver.com/lexcode_kr" target="_blank">
            <img src="../assets/img/relations_recruit_section1_img6.png" />
          </a>
        </div>
        <div class="text_wrap w100">
          <p class="cont_description">{{ $t("recruit.sec1_desc")/* 20여 년간, 10만 건이 넘는 프로젝트를 진행하여 4천여 개사 이상의 고객들과 함께해 온
            렉스코드. 우리는 언어 그 이상의 가치에 집중하고, 그 가치를 성공으로 연결하는 일을 합니다. 언어는 장벽이 아니라 기회입니다. */}}</p>
        </div>
      </div>
    </section>
    <section class="section2">
      <div class="container">
        <h1>{{ $t("recruit.sec2_contTitle")/* "사람과 사람을 통하게 하는 언어, 새로운 기회를 만들어 내는 언어.
          */}}<br>{{ $t("recruit.sec2_contTitle2")/* 여러분의 언어는 무엇인가요?" */ }}</h1>
        <img src="../assets/img/relations_recruit_section2_img1.png" />
      </div>
      <marquee-text :duration="30" :repeat="5">
        <img src="../assets/img/relations_recruit_section2_img2.png" />
      </marquee-text>
    </section>
    <section class="section3">
      <div class="container">
        <h1 class="cont_title">{{ $t("recruit.sec3_contTitle")/* 우리는 언어가 가진 힘을 믿고 함께 나아갑니다. */ }}</h1>
        <p class="cont_description">
          {{ $t("recruit.sec3_desc")/* 렉스코드는 대한민국 상위 1%의 로컬리제이션 전문 기업으로 한국 본사와 필리핀 지사의 약 100여 명의 동료들과 함께 언어 장벽 너머의 기회를
          만들어 가고 있습니다. */}}
        </p>
        <div class="flex">
          <div class="sec3_cont" v-for="(item, index) in items" :key="index" @mouseenter="addActiveClass(index)"
            @mouseleave="removeActiveClass(index)" :class="{ active: activeIndex === index }">
            <div class="sec3_cont_box">
              <p class="cont_subtitle">{{ $t(item.subtitle) }}</p>
              <p class="cont_description">{{ $t(item.description) }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section4">
      <div class="container">
        <div class="flex sec4_cont_wrap">
          <div class="text_wrap">
            <h1 class="cont_title">{{ $t("recruit.sec4_contTitle")/* 언제, 어디서, 어떻게 일하든
              */}}<br>{{ $t("recruit.sec4_contTitle2")/* 자유롭게 일하세요. */ }}</h1>
            <p class="cont_description">{{ $t("recruit.sec4_desc")/* 렉스코드의 직원들은 어떻게 생각할까요?
              */}}<br>{{ $t("recruit.sec4_desc2")/* 현직자들이 말하는 렉스코드에서 생활을 확인해보세요! */ }}</p>
          </div>
          <div class="sec4_cont">
            <div class="sec4_text">
              <p class="cont_subtitle">{{ $t("recruit.sec4_subTitle1")/* 유연 근무제 */ }}</p>
              <p class="cont_description" v-html="$t('recruit.sec4_subDesc1')"></p>
              <p class="write_by">{{ $t("recruit.sec4_writer1")/* UXUI 디자이너 Y */ }}</p>
            </div>
            <img src="../assets/img/relations_recruit_section4_img1.png" />
          </div>
          <div class="sec4_cont">
            <div class="sec4_text">
              <p class="cont_subtitle">{{ $t("recruit.sec4_subTitle2")/* 재택근무제 */ }}</p>
              <p class="cont_description" v-html="$t('recruit.sec4_subDesc2')"></p>
              <p class="write_by">{{ $t("recruit.sec4_writer2")/* 딥러닝팀 연구원 K */ }}</p>
            </div>
            <img src="../assets/img/relations_recruit_section4_img2.png" />
          </div>
          <div class="sec4_cont">
            <div class="sec4_text">
              <p class="cont_subtitle">{{ $t("recruit.sec4_subTitle3") }}</p>
              <p class="cont_description" v-html="$t('recruit.sec4_subDesc3')"></p>
              <p class="write_by">{{ $t("recruit.sec4_writer3") }}</p>
            </div>
            <img src="../assets/img/relations_recruit_section4_img3.png" />
          </div>
        </div>
      </div>
    </section>
    <section class="section6">
      <template v-if="window.width > 768">
        <div class="container">
          <div class="flex">
            <div class="sec6_cont_left"></div>
            <div class="sec6_cont">
              <p class="cont_title">{{ $t("recruit.sec6_contTitle")/* 즐겁게 일할 수 있도록 */ }}</p>
              <div class="flex_col">
                <div class="text_wrap">
                  <div class="flex">
                    <p class="num">1</p>
                    <p class="cont_subtitle">{{ $t("recruit.sec6_subTitle1")/* 복지 지원금 */ }}</p>
                  </div>
                  <p class="cont_description">{{ $t("recruit.sec6_desc1")/* 운동, 문화생활, 스포츠 관람, 자격증, 도서, OTT 등 다양한 분야에서 연
                    100만 원의 복지지원금을 지급합니다. */}}</p>
                </div>
                <div class="text_wrap">
                  <div class="flex">
                    <p class="num">2</p>
                    <p class="cont_subtitle">{{ $t("recruit.sec6_subTitle2")/* 데일리 휴식 */ }}</p>
                  </div>
                  <p class="cont_description">{{ $t("recruit.sec6_desc2")/* 매일 30분씩 휴식 시간을 지급합니다. 짧은 산책이나 수면실에서의 낮잠 등
                    자유롭게 활용해 보세요. */}}</p>
                </div>
                <div class="text_wrap">
                  <div class="flex">
                    <p class="num">3</p>
                    <p class="cont_subtitle">{{ $t("recruit.sec6_subTitle3")/* 카페테리아 */ }}</p>
                  </div>
                  <p class="cont_description">{{ $t("recruit.sec6_desc3")/* 기업 전용 카페테리아에서 커피와 간식을 무제한으로 제공합니다. */ }}</p>
                </div>
                <div class="text_wrap">
                  <div class="flex">
                    <p class="num">4</p>
                    <p class="cont_subtitle">{{ $t("recruit.sec6_subTitle4")/* 펫 프렌들리 */ }}</p>
                  </div>
                  <p class="cont_description">{{ $t("recruit.sec6_desc4")/* 반려견과 함께 사무실 출근을 할 수 있습니다. 이미 회사에는 귀여운 비숑
                    ‘렉스’도 상주하고 있거든요! */}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex">
          <div class="sec6_cont_left"></div>
          <div class="sec6_cont">
            <p class="cont_title">{{ $t("recruit.sec6_contTitle")/* 즐겁게 일할 수 있도록 */ }}</p>
            <div class="flex_col">
              <div class="text_wrap">
                <div class="flex">
                  <p class="num">1</p>
                  <p class="cont_subtitle">{{ $t("recruit.sec6_subTitle1")/* 복지 지원금 */ }}</p>
                </div>
                <p class="cont_description">{{ $t("recruit.sec6_desc1")/* 운동, 문화생활, 스포츠 관람, 자격증, 도서, OTT 등 다양한 분야에서 연
                  100만 원의 복지지원금을 지급합니다. */}}</p>
              </div>
              <div class="text_wrap">
                <div class="flex">
                  <p class="num">2</p>
                  <p class="cont_subtitle">{{ $t("recruit.sec6_subTitle2")/* 데일리 휴식 */ }}</p>
                </div>
                <p class="cont_description">{{ $t("recruit.sec6_desc2")/* 매일 30분씩 휴식 시간을 지급합니다. 짧은 산책이나 수면실에서의 낮잠 등 자유롭게
                  활용해 보세요. */}}</p>
              </div>
              <div class="text_wrap">
                <div class="flex">
                  <p class="num">3</p>
                  <p class="cont_subtitle">{{ $t("recruit.sec6_subTitle3")/* 카페테리아 */ }}</p>
                </div>
                <p class="cont_description">{{ $t("recruit.sec6_desc3")/* 기업 전용 카페테리아에서 커피와 간식을 무제한으로 제공합니다. */ }}</p>
              </div>
              <div class="text_wrap">
                <div class="flex">
                  <p class="num">4</p>
                  <p class="cont_subtitle">{{ $t("recruit.sec6_subTitle4")/* 펫 프렌들리 */ }}</p>
                </div>
                <p class="cont_description">{{ $t("recruit.sec6_desc4")/* 반려견과 함께 사무실 출근을 할 수 있습니다. 이미 회사에는 귀여운 비숑 ‘렉스’도
                  상주하고 있거든요! */}}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </section>
    <section class="section7">
      <div class="container">
        <p class="cont_title t_center">{{ $t("recruit.sec7_contTitle")/* 멋진 팀과 함께 혁신을 만들어 보세요. */ }}</p>
        <template v-if="$i18n.locale == 'ko'">
          <img src="../assets/img/relations_recruit_section7_img1.png" />
        </template>
        <template v-else>
          <img src="../assets/img/relations_recruit_section7_en_img1.png" />
        </template>
        <div class="flex">
          <div class="sec7_cont">
            <p class="cont_description">1</p>
            <p class="cont_subtitle">{{ $t("recruit.sec7_subTitle1")/* 서류 제출 */ }}</p>
            <p class="cont_description">{{ $t("recruit.sec7_desc1")/* 함께 하고 싶은 팀에 지원하세요. 양식은 중요하지 않습니다.<br>자신의 열정과 자질,
              그리고
              역량이 잘 표현될 수 있도록 작성해 주세요. */}}</p>
          </div>
          <div class="sec7_cont">
            <p class="cont_description">2</p>
            <p class="cont_subtitle">{{ $t("recruit.sec7_subTitle2")/* 대면 면접 */ }}</p>
            <p class="cont_description">{{ $t("recruit.sec7_desc2")/* 검은 정장을 입고 오지 않으셔도 됩니다.<br>더 솔직하게 서로에 대해서 알아볼 수 있도록
              편한
              마음으로 방문해 주세요. */}}</p>
          </div>
          <div class="sec7_cont">
            <p class="cont_description">3</p>
            <p class="cont_subtitle">{{ $t("recruit.sec7_subTitle3")/* 인턴 교육 */ }}</p>
            <p class="cont_description">{{ $t("recruit.sec7_desc3")/* 한 달의 인턴 기간을 통해 업무적 소양과 역량을 배양할 수 있도록 지원합니다. */ }}
            </p>
          </div>
          <div class="sec7_cont">
            <p class="cont_description">4</p>
            <p class="cont_subtitle">{{ $t("recruit.sec7_subTitle4")/* 정규직 교육 */ }}</p>
            <p class="cont_description">{{ $t("recruit.sec7_desc4")/* 이 모든 단계를 성공적으로 이수한 분들은 정식 근로계약서를 작성하게 됩니다. 렉스코디언이
              된
              것을
              축하드려요! */}}</p>
          </div>
        </div>
        <hr v-if="window.width > 768" />
        <template v-if="window.width > 768">
          <div class="sec7_cont2_wrap flex">
            <div class="sec7_cont2" v-for="(item, index) in team_info" :key="index"
              :class="{ active: item.class === currentClass }" @click="currentClass = item.class">
              <p class="team">{{ $t(item.tab) }}</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="sort_wrap">
            <div class="custom-select" @click="toggleDropdown">
              <div class="selected-option">
                {{ $t(selectedOption.tab) }} <img src="../assets/img/sort_arrow.png"
                  :class="{ rotate: isDropdownOpen }" />
              </div>
              <ul v-show="isDropdownOpen">
                <li v-for="(option, index) in team_info" :key="index" @click="selectOption(option)"
                  @change="toggleDropdown">
                  {{ $t(option.tab) }} <span v-if="selectedOption.class == option.class">&#10004;</span>
                </li>
              </ul>
            </div>
          </div>
        </template>
        <TransitionGroup name="fade-tab">
          <div class="team_info" v-for="(item, index) in currentList" :key="index">
            <p class="cont_subtitle">
              {{ $t(item.title) }}
            </p>
            <p class="cont_description" v-html="$t(item.description)"></p>
          </div>
        </TransitionGroup>
      </div>
    </section>
    <!-- <section class="section8">
      <div class="underlay" v-if="window.width > 768">
        <div ref="followerRef" class="follower">
          <div class="hand"></div>
        </div>
      </div>
      <div class="main-col">
        <div class="txt1">
          <p class="badge">Apply to LEXCODE</p>
          <p class="cont_title">{{ $t("recruit.sec8_contTitle")/* 렉스코드와 함께 일해볼까요? */ }}</p>
          <a href="https://lexcoderecruit.notion.site/fb966db4b5c04e1394b290428e70b9cd?pvs=4" target="_blank"
            class="link" @pointerover="over($event)" @pointerout="out($event)" @pointermove="move($event)">
            <span>{{ $t("recruit.sec8_btn")/* 지원하기 */ }}</span> <img src="../assets/img/main_sec3_arrow.png" />
          </a>
        </div>
      </div>
    </section> -->
    <div class="header_bg"></div>
  </div>
</template>
