<script>
import MarqueeText from 'vue-marquee-text-component'
export default {
  name: 'companyAll',
  props: {
    window: Object
  },
  components: {
    MarqueeText
    // Map
  },
  data () {
    return {
      plusRotate: false,
      company_hq: true,
      company_ph: false,
      hoveredIndex: 0
    }
  },
  methods: {
    getImageSource (index) {
      if (this.hoveredIndex === index || this.window.width < 769) {
        return require(`../assets/img/company_section2_hover_img${index}.png`)
      } else {
        return require(`../assets/img/company_section2_img${index}.png`)
      }
    },
    toggleShow () {
      this.plusRotate = !this.plusRotate
    }
  }
}
</script>
<template>
  <div class="company">
    <section class="section1">
      <div class="container">
        <div class="text_wrap t_white" data-aos="fade-right" data-aos-duration="500">
          <h4>Company</h4>
          <p class="cont_title">{{$t("company.sec1_contTitle")/* 렉스코드는 오늘도 새로운 기회를 만들어 가고 있습니다. */}}</p>
        </div>
      </div>
    </section>
    <section class="section2">
      <div class="container">
        <div class="text_wrap" :class="{t_center : window.width > 768}">
          <h4>about lexcode</h4>
          <p class="cont_title">{{$t("company.sec2_contTitle")/* 시간 안에서 검증된 */}}<br>{{$t("company.sec2_contTitle2")/* 상위 1%의 기업입니다. */}}</p>
        </div>
        <div class="flex">
          <div class="sec2_cont" @mouseover="hoveredIndex = 1" @mouseleave="hoveredIndex = 0" :class="{active : window.width < 769}">
            <img :src="getImageSource(1)" :key="getImageSource(1)" />
            <p class="cont_subtitle">20<span>{{$t("company.sec2_subTitle")/* 년 */}}</span></p>
            <p class="cont_description">{{$t("company.sec2_subDesc")/* 20년이란 시간 속에서 검증받은 신뢰와 경험을 보유하고 있습니다. */}}</p>
          </div>
          <div class="sec2_cont" @mouseover="hoveredIndex = 2" @mouseleave="hoveredIndex = 0" :class="{active : window.width < 769}">
            <img :src="getImageSource(2)" :key="getImageSource(2)" />
            <p class="cont_subtitle">{{$t("company.sec2_subTitle2_1")/* 100 */}}<span>{{$t("company.sec2_subTitle2")/* 억 */}}</span></p>
            <p class="cont_description">{{$t("company.sec2_subDesc2")/* 상위 1%이내의 로컬리제이션 기업으로 연간 100억 원 이상의 프로젝트를 수행하고 있습니다. */}}</p>
          </div>
          <div class="sec2_cont" @mouseover="hoveredIndex = 3" @mouseleave="hoveredIndex = 0" :class="{active : window.width < 769}">
            <img :src="getImageSource(3)" :key="getImageSource(3)" />
            <p class="cont_subtitle">1000<span>{{$t("company.sec2_subTitle3")/* 명 */}}</span></p>
            <p class="cont_description">{{$t("company.sec2_subDesc3")/* 1,000여 명의 국내외 스태프가 각자의 위치에서 빈틈없이 수행될 수 있도록 힘쓰고 있습니다. */}}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section3">
      <div class="container">
        <p class="cont_title t_center">{{$t("company.sec3_contTitle")/* 매년 600여 개의 기업들이 렉스코드와 언어장벽 너머 더 많은 기회를 만나고 있습니다. */}}</p>
      </div>
      <div class="marquee_wrap">
        <marquee-text :duration="30" :repeat="5">
          <img src="../assets/img/company_atGlance_section3_img1.png" />
        </marquee-text>
        <marquee-text reverse :duration="30" :repeat="5">
          <img src="../assets/img/company_atGlance_section3_img2.png" />
        </marquee-text>
      </div>
    </section>
    <section class="section4" id="visionMission">
      <div class="container" data-aos="fade-down" data-aos-duration="500">
        <div class="text_wrap title">
          <h4 class="t_purple">Vision</h4>
          <p class="cont_title">{{$t("company.sec4_contTitle")/* 언어장벽 없는 세상을 향한 새로운 여정 */}}</p>
        </div>
        <div class="text_wrap">
          <p class="cont_description">{{$t("company.sec4_desc1")/* 렉스코드는 번역 서비스 제공 업체를 넘어선 자연어 처리와 최첨단 대규모 언어 모델을 기반으로 하는 로컬리제이션 업계의 기술 중심 기업으로의 여정을 시작합니다. 자연어처리, 자동번역 모델 구축, 생성형 AI를 기반으로 한 솔루션 등 자연어 분야에서 일어나고 있는 최신의 기술을 접목하는 포트폴리오를 담았으며 이를 통해 업계 기술 혁명의 선두에 서게 되었습니다. */}}</p>
          <p class="cont_description">{{$t("company.sec4_desc2")/* - 중략 - */}}</p>
          <p class="cont_description">{{$t("company.sec4_desc3")/* 우리의 여정은 위험으로 가득 차 있고 저항에 부딪힐 수도 있지만, 변화를 수용함으로써 우리의 미래를 확보할 수 있다는 것이 저의 확고한 믿음입니다. 지금이 바로 기술, 시장 상황, 집단적 재능의 독특한 융합으로 놀라운 성공을 거둘 수 있는 기회입니다. */}}</p>
          <p class="cont_description">{{$t("company.sec4_desc4")/* 지금이야말로 인공지능이 열어주는 새로운 역사의 터전에서 더 나은 미래를 설계하고 더 빠른 성장을 이어갈 수 있는 시기입니다. 무엇보다도 새로운 도전을 통해 우리는 고객이 마주하고 있는 언어 장벽을 전례 없이 빠르고 쉽고 저렴하게 기회로 전환할 수 있도록 도울 것입니다. 나아가 지금의 초거대 자연어 인공지능 기술은 메타버스 기술을 촉진하고 그것과 결합함으로써 인류를 전례 없이 가깝게 연결하고 더 자유로운 삶을 영위할 수 있도록 도움을 줄 것이라 기대합니다. */}}</p>
          <b class="cont_description mgt">{{$t("company.sec4_desc5")/* 렉스코드 함철용 */}}</b>
        </div>
      </div>
    </section>
    <section class="section5">
      <div class="container">
        <div class="text_wrap">
          <h4>Milestone</h4>
          <p class="cont_title">{{$t("company.sec5_contTitle")/* 10년간 쌓아온 */}}<br>{{$t("company.sec5_contTitle2")/* 신뢰를 확인하세요. */}}</p>
        </div>
        <div class="flex">
          <div class="sec5_cont img bg1">
            <div class="text_wrap">
              <p class="cont_description t_white">{{$t("company.sec5_desc1")/* 자연어 처리 전문 처리 기업으로서 발돋움 */}}</p>
              <p class="cont_title t_white">{{$t("company.sec5_title1")/* 2012 - 현재 */}}</p>
            </div>
          </div>
          <div class="sec5_cont info">
            <div class="flex inner">
              <p class="cont_title">2020</p>
              <div class="text_wrap">
                <p class="cont_description">{{$t("company.sec5_desc2")/* LEX-Cloud 런칭 */}}</p>
              </div>
            </div>
            <div class="flex inner">
              <p class="cont_title">2019</p>
              <div class="text_wrap">
                <p class="cont_description">{{$t("company.sec5_desc3")/* 용어통합 시스템 GLO 오픈 */}}</p>
                <p class="cont_description">{{$t("company.sec5_desc3_1")/* 고용노동부 청년친화강소기업 선정 */}}</p>
              </div>
            </div>
            <div class="flex inner">
              <p class="cont_title">2018</p>
              <div class="text_wrap">
                <p class="cont_description">{{$t("company.sec5_desc4")/* Journallab LAB 런칭 */}}</p>
                <p class="cont_description">{{$t("company.sec5_desc4_1")/* KOTRA 글로벌 조달전문기업 연속 선정[P500 프로젝트 */}}</p>
              </div>
            </div>
            <div class="flex inner">
              <p class="cont_title">2017</p>
              <div class="text_wrap">
                <p class="cont_description">{{$t("company.sec5_desc5")/* 네이버 다국어 번역 업체 선정 */}}</p>
              </div>
            </div>
            <div class="flex inner">
              <p class="cont_title">2016</p>
              <div class="text_wrap">
                <p class="cont_description">{{$t("company.sec5_desc6")/* eQQui Korea 설립, BIS@T 개발 */}}</p>
                <p class="cont_description">{{$t("company.sec5_desc6_1")/* ISO 9001 인증 [16-Q-2147-00] */}}</p>
                <p class="cont_description">{{$t("company.sec5_desc6_2")/* 기술혁신형 중소기업 연장 [제 R110102-00187호] */}}</p>
              </div>
            </div>
            <div class="flex inner">
              <p class="cont_title">2014</p>
              <div class="text_wrap">
                <p class="cont_description">{{$t("company.sec5_desc7")/* 한국무역협회 가입 [45465035] */}}</p>
                <p class="cont_description">{{$t("company.sec5_desc7_1")/* GALA Membership 가입 */}}</p>
                <p class="cont_description">{{$t("company.sec5_desc7_2")/* UN Global Compact 가입 */}}</p>
              </div>
            </div>
            <div class="flex inner">
              <p class="cont_title">2013</p>
              <div class="text_wrap">
                <p class="cont_description">{{$t("company.sec5_desc8")/* KOTRA 글로벌 조달선도기업 [P300]선정 */}}</p>
                <p class="cont_description">{{$t("company.sec5_desc8_1")/* KOTRA 장보고상-지식서비스산업부분 특별상 수상 */}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="sec5_cont info">
            <div class="flex inner">
              <p class="cont_title">2012</p>
              <div class="text_wrap">
                <p class="cont_description">{{$t("company.sec5_desc9")/* IRIS내부 시스템 개발 완료 */}}</p>
              </div>
            </div>
            <div class="flex inner">
              <p class="cont_title">2011</p>
              <div class="text_wrap">
                <p class="cont_description">{{$t("company.sec5_desc10")/* 특허출원 (제2009-0025669호) */}}</p>
                <p class="cont_description">{{$t("company.sec5_desc10_1")/* “웹을 기반으로 한 번역 용어 구축과 감수에 관한 협업 시스템” */}}</p>
              </div>
            </div>
            <div class="flex inner">
              <p class="cont_title">2010</p>
              <div class="text_wrap">
                <p class="cont_description">{{$t("company.sec5_desc11")/* 필리핀 현지 법인 설립 */}}</p>
              </div>
            </div>
            <div class="flex inner">
              <p class="cont_title">2009</p>
              <div class="text_wrap">
                <p class="cont_description">{{$t("company.sec5_desc12")/* 벤처기업 인증 획득 */}}</p>
              </div>
            </div>
            <div class="flex inner">
              <p class="cont_title">2008</p>
              <div class="text_wrap">
                <p class="cont_description">{{$t("company.sec5_desc13")/* ISO 9001 품질 인증 획득 [JK-13131] */}}</p>
              </div>
            </div>
            <div class="flex inner">
              <p class="cont_title">2007</p>
              <div class="text_wrap">
                <p class="cont_description">{{$t("company.sec5_desc14")/* 렉스코드 법인 전환 */}}</p>
              </div>
            </div>
            <div class="flex inner">
              <p class="cont_title">2004</p>
              <div class="text_wrap">
                <p class="cont_description">{{$t("company.sec5_desc15")/* 회사 설립, 번역 업무 시작 */}}</p>
              </div>
            </div>
          </div>
          <div class="sec5_cont img bg2">
            <div class="text_wrap">
              <p class="cont_description t_white">{{$t("company.sec5_desc16")/* 세상으로의 도약 */}}</p>
              <p class="cont_title t_white">2004 - 2012</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section6" id="contactus">
      <div v-if="window.width > 768" class="sec6_cont img">
        <img src="../assets/img/company_atGlance_section6_img.jpg"/>
      </div>
      <div :class="[{container : window.width > 768 },{ t_center : window.width > 768 }]">
        <div class="text_wrap">
          <h4>Leadership</h4>
          <p class="cont_title">{{$t("company.sec6_contTitle")/* 렉스코드의 혁신 기술로 미래를 풍요롭게 만듭니다 */}}</p>
        </div>
        <img v-if="window.width < 769" src="../assets/img/company_atGlance_section6_img.png"/>
        <div class="sec6_cont text_wrap">
          <b>{{$t("company.sec6_title")/* 언어는 장벽이 아니라 기회입니다. */}}</b>
          <p class="cont_description">
            {{$t("company.sec6_desc")/* 1992년, 미국 실리콘 밸리에서 첫 창업을 하였고 이후 미 육군에서 6년간 복무 하였다. 복무 중 Webster 대학에서 컴퓨터 자원 및 정보관리 석사를 취득하였다.  2002년에 한국으로 귀국해서 번역회사를 창업하여 국내 최대 규모의 번역회사로 성장시켰다. */}}
          </p>
          <p class="cont_description">
            {{$t("company.sec6_desc2")/* 끊임없는 혁신과 기술개발이 그가 치열하게 집중하는 부분이다. 번역의뢰와 진행과정을 e-commerce적인 경험으로 풀어낸 Lex-Cloud, 국내 최대의 수출상담회 운영 플랫폼 BIS@T, 세계 최대의 통역사 플랫폼 eQQui 및 언어 데이터 수집 플랫폼 BAVL등 혁신적인 서비스를 시장에 선보이며 파괴적 혁신을 이끌었다. */}}
          </p>
          <p class="cont_description">
            {{$t("company.sec6_desc3")/* 수년 전부터는 파이썬을 독학하며 인공지능과 딥러닝 툴을 활용하여 사용자가 체감할 수 있는 서비스를 만드는데 집중하고 있다. 그가 주도하고 있는 자연어 딥러닝팀은 팀 결성 6개월만에 초거대 인공지능을 활용한 논문분석 서비스 저널랩과 고객 맞춤 데이터로 학습한 전문분야 자동번역 모델을 백엔드부터 프론트까지 개발하고 서비스하는데 성공하였다. */}}
          </p>
          <p class="cont_description">
            {{$t("company.sec6_desc4")/* 렉스코드를 대한민국의 위상에 맞는 자연어 처리 전문 기업으로 성장시키는 것이 그의 꿈이다. */}}
          </p>
        </div>
        <p class="ceo">{{$t("company.sec6_hcy")/* 렉스코드 함철용 */}} <span>CEO/Founder</span></p>
      </div>
    </section>
    <section class="section8">
      <div class="container">
        <div class="text_wrap">
          <h4>CONTACT US</h4>
          <p class="cont_title">
            {{$t("company.sec8_contTitle")/* 일하기도, 방문하기도 좋은 */}}<br>{{$t("company.sec8_contTitle2")/* 서초동에 있어요. */}}
          </p>
        </div>
        <div class="flex tab_wrap">
          <p class="tab" :class="{ 'active' : company_hq }" @click="company_hq = true, company_ph = false">{{$t("company.sec8_tab1")/* 렉스코드 한국 본사 */}}</p>
          <p class="tab" :class="{ 'active' : company_ph }" @click="company_ph = true, company_hq = false">{{$t("company.sec8_tab2")/* 렉스코드 필리핀 지사 */}}</p>
        </div>
        <div class="map_wrap">
          <transition-group name="fade">
            <template v-if="company_ph != true">
              <template v-if="$i18n.locale == 'ko'">
                <img src="../assets/img/company_atGlance_section8_img1.png"/>
              </template>
              <template v-else>
                <img src="../assets/img/company_atGlance_section8_en_img1.png"/>
              </template>
              <div class="map_info">
                <p class="cont_subtitle">{{$t("company.sec8_subTitle1")/* 렉스코드 본사 */}}</p>
                <div class="text_wrap">
                  <div class="flex">
                    <img src="../assets/img/company_atGlance_section8_img3.png"/>
                    <p class="info">{{$t("company.sec8_address")/* 서울시 서초구 서초중앙로 41 대성빌딩 6층 (3호선 남부터미널 6번 출구 도보 1분) */}}</p>
                  </div>
                  <div class="flex">
                    <img src="../assets/img/company_atGlance_section8_img4.png"/>
                    <p class="info">02-521-2685</p>
                  </div>
                  <div class="flex">
                    <img src="../assets/img/company_atGlance_section8_img5.png"/>
                    <p class="info">070-4275-1168</p>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <template v-if="$i18n.locale == 'ko'">
                <img src="../assets/img/company_atGlance_section8_img2.png"/>
              </template>
              <template v-else>
                <img src="../assets/img/company_atGlance_section8_en_img2.png"/>
              </template>
              <div class="map_info">
                <p class="cont_subtitle">{{$t("company.sec8_subTitle2")/* 렉스코드 필리핀 지사 */}}</p>
                <div class="text_wrap">
                  <div class="flex">
                    <img src="../assets/img/company_atGlance_section8_img3.png"/>
                    <p class="info">12F 6780 Ayala Avenue Building, 6780 Ayala Avenue, San Lorenzo, Makati City, Philippines 1223</p>
                  </div>
                  <div class="flex">
                    <img src="../assets/img/company_atGlance_section8_img4.png"/>
                    <p class="info">+63-2-553-3857</p>
                  </div>
                  <div class="flex">
                    <img src="../assets/img/company_atGlance_section8_img5.png"/>
                    <p class="info">63-2-586-3235</p>
                  </div>
                </div>
              </div>
            </template>
          </transition-group>
        </div>
      </div>
    </section>
    <section class="section7">
      <div class="container">
        <div class="flex fix"  @click="toggleShow">
          <p class="cont_title">{{$t("company.sec7_contTitle")/* 궁금하신게 있다면 여기로 연락주세요. */}}</p>
          <p class="plus" :class="{ 'active' : plusRotate }"></p>
        </div>
        <transition name="roll-up">
          <div v-if="plusRotate" :class="{ 'active' : plusRotate }" class="sec7_cont flex">
            <template v-if="window.width > 768">
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc")/* 일반문의 */}}<br>
                    <span>{{$t("company.sec7_desc16")/* 경영지원팀 */}}</span>
                  </p>
                  <p class="cont_description">acc@lexcode.com</p>
                  <p class="cont_description">02-521-1043</p>
                </div>
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc3")/* 사업제휴 문의 */}}<br>
                    <span>{{$t("company.sec7_desc4")/* 박미순 이사 */}}</span>
                  </p>
                  <p class="cont_description">mspark@lexcode.com</p>
                  <p class="cont_description">02-521-2685</p>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc5")/* 인공지능 사업문의 */}}<br>
                    <span>{{$t("company.sec7_desc6")/* 김기백 대리 */}}</span>
                  </p>
                  <p class="cont_description">kbkim@lexcode.com</p>
                  <p class="cont_description">02-521-2788</p>
                </div>
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc7")/* 언어데이터 사업문의 */}}<br>
                    <span>{{$t("company.sec7_desc8")/* 서재완 과장 */}}</span>
                  </p>
                  <p class="cont_description">jwsuh@lexcode.com</p>
                  <p class="cont_description">070-4217-5393</p>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc9")/* 번역사업 문의 */}}<br>
                    <span>{{$t("company.sec7_desc10")/* 이채현 과장 */}}</span>
                  </p>
                  <p class="cont_description">chlee@lexcode.com</p>
                  <p class="cont_description">010-8370-7490</p>
                </div>
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc11")/* 통역사업 문의 */}}<br>
                    <span>{{$t("company.sec7_desc12")/* 양유진 실장 */}}</span>
                  </p>
                  <p class="cont_description">yjyang@eqqui.com</p>
                  <p class="cont_description">010-8007-7491</p>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc13")/* 인사채용 문의 */}}<br>
                    <span>{{$t("company.sec7_desc14")/* 박수경 실장 */}}</span>
                  </p>
                  <p class="cont_description">skpark@lexcode.com</p>
                  <p class="cont_description">010-8111-7490</p>
                </div>
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc19")/* 마케팅 제휴 문의 */}}<br>
                    <span>{{$t("company.sec7_desc20")/* 이철진 사원 */}}</span>
                  </p>
                  <p class="cont_description">cjlee@eqqui.com</p>
                  <p class="cont_description">010-3966-7490</p>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc21")/* IT 시스템 문의 */}}<br>
                    <span>{{$t("company.sec7_desc22")/* 강예별 대리 */}}</span>
                  </p>
                  <p class="cont_description">ybkang@lexcode.com</p>
                  <p class="cont_description">010-3273-0658</p>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc")/* 일반문의 */}}<br>
                    <span>{{$t("company.sec7_desc16")/* 오영란 실장 */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">acc@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">02-521-1043</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc3")/* 사업제휴 문의 */}}<br>
                    <span>{{$t("company.sec7_desc4")/* 박미순 이사 */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">mspark@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">02-521-2685</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc5")/* 인공지능 사업문의 */}}<br>
                    <span>{{$t("company.sec7_desc6")/* 김기백 대리 */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">kbkim@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">02-521-2788</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc7")/* 언어데이터 사업문의 */}}<br>
                    <span>{{$t("company.sec7_desc8")/* 서재완 과장 */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">jwsuh@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">070-4217-5393</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc9")/* 번역사업 문의 */}}<br>
                    <span>{{$t("company.sec7_desc10")/* 이채현 과장 */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">chlee@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">010-8370-7490</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc11")/* 통역사업 문의 */}}<br>
                    <span>{{$t("company.sec7_desc12")/* 양유진 실장 */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">yjyang@eqqui.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">010-8007-7491</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc13")/* 인사채용 문의 */}}<br>
                    <span>{{$t("company.sec7_desc14")/* 박수경 실장 */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">skpark@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">010-8111-7490</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc15")/* 회계, 세무, 급여문의 */}}<br>
                    <span>{{$t("company.sec7_desc16")/* 오영란 실장 */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">yroh@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">02-521-1043</p>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc17")/* 프리랜서 지원 문의 */}}<br>
                    <span>{{$t("company.sec7_desc14")/* 박수경 실장 */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">skpark@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">010-8111-7490</p>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc19")/* 마케팅 제휴 문의 */}}<br>
                    <span>{{$t("company.sec7_desc20")/* 이철진 사원 */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">cjlee@eqqui.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">010-3966-7490</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("company.sec7_desc21")/* IT 시스템 문의 */}}<br>
                    <span>{{$t("company.sec7_desc22")/* 강예별 대리 */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">ybkang@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">010-3273-0658</p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            </div>
        </transition>
      </div>
    </section>
    <div class="header_bg"></div>
  </div>
</template>
