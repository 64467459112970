<script>
export default {
  name: 'businessBavl',
  data() {
    return {
      texts: [
        'It',
        'is',
        'unbelieBAVL'
      ],
      morphTime: 1,
      cooldownTime: 0.25,
      textIndex: 0,
      time: new Date(),
      morph: 0,
      cooldown: 0,
      animationId: null
    }
  },
  mounted() {
    this.$refs.text1.style.filter = ''
    this.$refs.text2.style.filter = ''
    this.$refs.text1.style.opacity = '0%'
    this.$refs.text2.style.opacity = '100%'
    this.animate()
  },
  methods: {
    doMorph() {
      this.morph -= this.cooldown
      this.cooldown = 0
      let fraction = this.morph / this.morphTime
      if (fraction > 1) {
        this.cooldown = this.cooldownTime
        fraction = 1
      }
      this.setMorph(fraction)
    },
    setMorph(fraction) {
      this.$refs.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`
      this.$refs.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`
      fraction = 1 - fraction
      this.$refs.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`
      this.$refs.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`
      this.$refs.text1.textContent = this.texts[this.textIndex % this.texts.length]
      this.$refs.text2.textContent = this.texts[(this.textIndex + 1) % this.texts.length]
    },
    doCooldown() {
      this.morph = 0
      this.$refs.text2.style.filter = ''
      this.$refs.text2.style.opacity = '100%'
      this.$refs.text1.style.filter = ''
      this.$refs.text1.style.opacity = '0%'
    },
    animate() {
      this.animationId = requestAnimationFrame(this.animate)
      const newTime = new Date()
      const shouldIncrementIndex = this.cooldown > 0
      const dt = (newTime - this.time) / 1000
      this.time = newTime
      this.cooldown -= dt
      if (this.cooldown <= 0) {
        if (shouldIncrementIndex) {
          this.textIndex++
        }
        this.doMorph()
      } else {
        this.doCooldown()
      }
    }
  },
  beforeUnmount() {
    cancelAnimationFrame(this.animationId)
  }
}
</script>

<template>
  <div class="business bavl">
    <section class="section1">
      <div class="container flex">
        <div class="left">
          <div class="left_cont">
            <img src="../assets/img/business_bavl_section1_img1.png" />
          </div>
        </div>
        <div class="right">
          <div class="right_cont">
            <div class="flex">
              <p class="business_title">Business</p>
              <p class="business_num"><span>05 </span>— 05</p>
            </div>
            <p class="subtitle">{{$t("busi_bavl.sec1_subTitle")/* 자연어 데이터 수집 · 정제 */}}</p>
            <h1>{{$t("busi_bavl.sec1_title")/* BAVL, */}}<br>{{$t("busi_bavl.sec1_title2")/* 언어 데이터의 가능성. */}}</h1>
            <a href="https://bavl.lexcloud.ai" target="_blank" class="main_btn">{{$t("busi_bavl.sec1_btn")/* 시작하기 → */}}</a>
          </div>
        </div>
      </div>
    </section>
    <section class="section2">
      <div class="container">
        <div class="text_wrap t_center">
          <p class="cont_title">BAVL</p>
          <p class="underline_text">Crowdsource language data platform</p>
          <p class="cont_subtitle"><span class="t_pink">BAVL</span>{{$t("busi_bavl.sec2_subTitle")/* 은 언어 데이터를 수집하는 */}}<span class="t_pink"> {{$t("busi_bavl.sec2_subTitle2")/* 크라우드 소싱 언어 데이터 플랫폼 */}}</span>{{$t("busi_bavl.sec2_subTitle3")/* 입니다. */}}</p>
          <p class="cont_description">{{$t("busi_bavl.sec2_desc")/* 다양한 연구 기관 및 산업에서 필요로 하는 언어 데이터를 텍스트와 음성의 제한 없이 모두 수집합니다. */}}<br>
          {{$t("busi_bavl.sec2_desc2")/* 수집된 언어 데이터는 정제와 가공의 프로세스를 거쳐, 경험이 되고 지식이 되며, 또 다른 가능성이 됩니다. */}}</p>
        </div>
      </div>
    </section>
    <section class="section8">
      <div class="container">
        <div class="sec8_wrap flex">
          <div class="sec8_cont">
            <img src="../assets/img/business_bavl_section8_img1.png" />
            <h2>{{$t("busi_bavl.sec8_title1")/* 배블은 지금까지 1,445,808개의 언어 데이터를 수집했습니다. */}}</h2>
            <p class="cont_description">{{$t("busi_bavl.sec8_desc1")/* 일상대화부터 비즈니스 까지, 목적과 상황에 맞는 맞춤형 언어 데이터를 수집하고 정제합니다. */}}</p>
            <template v-if="$i18n.locale == 'ko'"><img src="../assets/img/business_bavl_section8_img3.png" /></template>
            <template v-else><img src="../assets/img/business_bavl_section8_en_img3.png" /></template>
          </div>
          <div class="sec8_cont">
            <img src="../assets/img/business_bavl_section8_img2.png" />
            <h2>{{$t("busi_bavl.sec8_title2")/* 언어 데이터셋 라이브러리 */}}</h2>
            <p class="cont_description">{{$t("busi_bavl.sec8_desc2")/* 영어 및 한국어를 포함해 2천만 개가 넘는 단어로 이루어진 3백만 개의 문장 데이터셋이 이미 확보되어 있습니다. 기다릴 필요없이 BAVL의 데이터셋을 지금 바로 활용하세요. */}}</p>
            <template v-if="$i18n.locale == 'ko'"><img src="../assets/img/business_bavl_section8_img4.png" /></template>
            <template v-else><img src="../assets/img/business_bavl_section8_en_img4.png" /></template>
          </div>
        </div>
      </div>
    </section>
    <section class="section3">
      <div>
        <div id="text1" ref="text1" class="text">{{ texts[textIndex % texts.length] }}</div>
        <div id="text2" ref="text2" class="text">{{ texts[(textIndex + 1) % texts.length] }}</div>
      </div>
      <svg id="filters">
        <defs>
          <filter id="threshold">
            <feColorMatrix in="SourceGraphic" type="matrix" values="1 0 0 0 0
                        0 1 0 0 0
                        0 0 1 0 0
                        0 0 0 255 -140" />
          </filter>
        </defs>
      </svg>
    </section>
    <section class="section4">
      <div class="container">
        <div class="flex">
          <div class="text_wrap">
            <p class="cont_subtitle">
              "It’s unbelie<span class="t_pink">BAVL</span>"
            </p>
            <p class="cont_title mgt">
              {{$t("busi_bavl.sec4_subTitle")/* 업계가 인정하는 언어 데이터 수집 플랫폼 */}}
            </p>
            <p class="cont_subtitle2">{{$t("busi_bavl.sec4_contTitle")/* 휴먼 커뮤니케이션의 미래를 이끌기 위한 데이터셋을 구축하세요. */}}</p>
            <p class="cont_description">{{$t("busi_bavl.sec4_desc")/* 여러분의 AI 및 NLP프로젝트에 활용할 수 있는 완벽한 학습용 데이터를 구축하세요. BAVL은 여러분에게 필요한 모든 언어 데이터 수집 및 어노테이션 프로젝트를 성공적으로 완수하기 위한 모든 도구와 기능을 갖추고 있습니다. */}}</p>
          </div>
          <img src="../assets/img/business_bavl_section4_img1.png" />
        </div>
      </div>
    </section>
    <section class="section5">
      <div class="container">
        <div class="text_wrap t_center">
          <p class="cont_subtitle2 t_pink">{{$t("busi_bavl.sec5_subTitle")/* 언어 데이터를 더 유용하게 */}}</p>
          <p class="cont_title">{{$t("busi_bavl.sec5_contTitle")/* 배블 데이터 솔루션 */}}</p>
        </div>
        <div class="flex">
          <div class="cont1">
            <div class="img_wrap">
              <div class="img_cont">
                <img class="chat_img" data-aos="fade-down" data-aos-duration="500" data-aos-delay="500"
                  src="../assets/img/business_bavl_section5_img1.png" />
                <img class="chat_img" data-aos="fade-down" data-aos-duration="500" data-aos-delay="800"
                  src="../assets/img/business_bavl_section5_img1-1.png" />
                <img class="chat_img" data-aos="fade-down" data-aos-duration="500" data-aos-delay="1100"
                  src="../assets/img/business_bavl_section5_img1-2.png" />
              </div>
            </div>
            <div class="title flex">
              <p class="num">1</p>
              <h5>{{$t("busi_bavl.sec5_title1")/* 텍스트 데이터 수집 */}}</h5>
            </div>
            <p class="cont_decription">{{$t("busi_bavl.sec5_desc1")/* 맞춤 스크립트와 시나리오 기반 프롬프트 설정으로 더욱 정교하고 자연스러운 텍스트 데이터를 구축하세요. 20,000명 이상의 검증된 크라우드소스 작업자들이 이를 지원합니다. */}}</p>
          </div>
          <div class="cont1">
            <div class="img_wrap">
              <div class="img_cont">
                <div class="equalizer">
                  <img class="equalizer_img" src="../assets/img/business_bavl_section5_img2.png" />
                  <i class="eq_eq_bar bar_first"></i>
                  <i class="eq_bar bar_second"></i>
                  <i class="eq_bar bar_third"></i>
                  <i class="eq_bar bar_fourth"></i>
                  <i class="eq_bar bar_fifth"></i>
                  <i class="eq_bar bar_sixth"></i>
                  <i class="eq_bar bar_fifth"></i>
                  <i class="eq_bar bar_fourth"></i>
                  <i class="eq_bar bar_third"></i>
                  <i class="eq_bar bar_second"></i>
                  <i class="eq_bar bar_first"></i>
                  <i class="eq_bar bar_first"></i>
                  <i class="eq_bar bar_second"></i>
                  <i class="eq_bar bar_third"></i>
                  <i class="eq_bar bar_fourth"></i>
                  <i class="eq_bar bar_fifth"></i>
                  <i class="eq_bar bar_sixth"></i>
                  <i class="eq_bar bar_fifth"></i>
                  <i class="eq_bar bar_fourth"></i>
                  <i class="eq_bar bar_third"></i>
                  <i class="eq_bar bar_second"></i>
                  <i class="eq_bar bar_first"></i>
                </div>
              </div>
            </div>
            <div class="title flex">
              <p class="num">2</p>
              <h5>{{$t("busi_bavl.sec5_title2")/* 음성 데이터 수집 */}}</h5>
            </div>
            <p class="cont_decription">{{$t("busi_bavl.sec5_desc2")/* 실내, 실외, 스튜디오 등 원하는 환경에서 성별, 나이, 억양, 교육 수준까지 설정한 ‘맞춤형 음성 데이터셋’을 구축하세요. */}}</p>
          </div>
          <div class="cont1">
            <div class="img_wrap">
              <img class="cont3_img" data-aos="fade-down" data-aos-duration="500" data-aos-delay="500"
                src="../assets/img/business_bavl_section5_img3.png" />
                <template v-if="$i18n.locale == 'ko'">
                  <img class="cont3_img" data-aos="fade-down" data-aos-duration="500" data-aos-delay="800"
                    src="../assets/img/business_bavl_section5_img4.png" />
                </template>
                <template v-else>
                  <img class="cont3_img" data-aos="fade-down" data-aos-duration="500" data-aos-delay="800"
                    src="../assets/img/business_bavl_section5_en_img4.png" />
                </template>
            </div>
            <div class="title flex">
              <p class="num">3</p>
              <h5>{{$t("busi_bavl.sec5_title3")/* 이미지 데이터 변환 */}}</h5>
            </div>
            <p class="cont_decription">{{$t("busi_bavl.sec5_desc3")/* 이미지와 세부 사항에 맞춘 적절한 설명을 크라우드 소스 작업자가 텍스트, 음성과 같이 필요한 유형의 데이터로 생성합니다. */}}</p>
          </div>
          <div class="cont1" :class="{ en : $i18n.locale == 'en'}">
            <div class="img_wrap">
              <img class="gif_img" src="../assets/img/business_bavl_section5_img5.gif" />
            </div>
            <div class="title flex">
              <p class="num">4</p>
              <h5>{{$t("busi_bavl.sec5_title4")/* 자유로운 데이터 활용 */}}</h5>
            </div>
            <p class="cont_decription">{{$t("busi_bavl.sec5_desc4")/* 텍스트 데이터와 음성 데이터를 제한 없이 변환하세요. 다음과 같이 활용할 수 있습니다. */}}</p>
            <div class="cont4_info">
              <p class="cont4_subtitle">• {{$t("busi_bavl.sec5_desc4_1")/* 텍스트 데이터 변환 */}}<span class="blue">{{$t("busi_bavl.sec5_desc4_s")/*음*/}}</span>→<span class="green">{{$t("busi_bavl.sec5_desc4_t")/*텍*/}}</span></p>
              <p class="cont4_description">{{$t("busi_bavl.sec5_desc4_2")/* 모든 음성데이터를 빠르게 전사하여 데이터셋 구축을 위한 정확한 문장을 제공합니다. */}}</p>
              <p class="cont4_subtitle">• {{$t("busi_bavl.sec5_desc4_3")/* 음성 데이터 변환 */}}<span class="green">{{$t("busi_bavl.sec5_desc4_t")/*텍*/}}</span>→<span class="blue">{{$t("busi_bavl.sec5_desc4_s")/*음*/}}</span></p>
              <p class="cont4_description">{{$t("busi_bavl.sec5_desc4_4")/* 언어, 억양, 국적, 성별, 나이, 교육 수준 및 전문 지식의 정도를 설정하여 섬세한 음성 데이터로 전환할 수 있습니다. */}}</p>
            </div>
          </div>
          <div class="cont1">
            <div class="img_wrap">
              <div class="img_cont">
                <img class="chat_img" data-aos="fade-down" data-aos-duration="500"
                  src="../assets/img/business_bavl_section5_img5.png" />
                <img class="chat_img" data-aos="fade-down" data-aos-duration="500" data-aos-delay="500"
                  src="../assets/img/business_bavl_section5_img6.png" />
              </div>
            </div>
            <div class="title flex">
              <p class="num">5</p>
              <h5>{{$t("busi_bavl.sec5_title5")/* 언어 데이터 번역 */}}</h5>
            </div>
            <p class="cont_decription">{{$t("busi_bavl.sec5_desc5")/* 데이터 수집부터 번역까지 한 번에 진행하세요. 렉스코드의 전문 번역 서비스로 고품질 다국어 데이터셋을 누구보다 빠르고 정확하게 구축할 수 있습니다. */}}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section6">
      <div class="container">
        <div class="flex">
          <p class="cont_title text_wrap">{{$t("busi_bavl.sec6_contTitle")/* BAVL은 이런 장점을 가져다줍니다. */}}</p>
          <div class="flex sec6_cont">
            <div class="text_wrap">
              <img src="../assets/img/business_bavl_section6_img1.png" />
              <p class="sec6_title">{{$t("busi_bavl.sec6_title1")/* 독보적인 신속성 */}}</p>
              <p class="cont_description">{{$t("busi_bavl.sec6_desc1")/* 크라우드소스 작업자를 활용하여 빠르게 데이터를 수집하고 어노테이션합니다. */}}</p>
            </div>
            <div class="text_wrap">
              <img src="../assets/img/business_bavl_section6_img2.png" />
              <p class="sec6_title">{{$t("busi_bavl.sec6_title2")/* 효율적인 확장성 */}}</p>
              <p class="cont_description">{{$t("busi_bavl.sec6_desc2")/* 예산 범위 내에서 원하는 크기로 데이터셋을 구축할 수 있습니다. */}}</p>
            </div>
            <div class="text_wrap">
              <img src="../assets/img/business_bavl_section6_img3.png" />
              <p class="sec6_title">{{$t("busi_bavl.sec6_title3")/* 고품질 데이터셋 */}}</p>
              <p class="cont_description">{{$t("busi_bavl.sec6_desc3")/* 엄격한 품질관리 프로세스를 통해 데이터의 정확성과 합법적인 규정 준수가 보장됩니다. */}}</p>
            </div>
            <div class="text_wrap">
              <img src="../assets/img/business_bavl_section6_img4.png" />
              <p class="sec6_title">{{$t("busi_bavl.sec6_title4")/* 철저한 비밀 보장 */}}</p>
              <p class="cont_description">{{$t("busi_bavl.sec6_desc4")/* 최고 수준의 보안 및 윤리 기준을 통해 데이터를 안전하게 관리합니다. */}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section7">
      <div class="container">
        <div class="text_wrap t_center t_white">
          <p class="cont_title">{{$t("busi_bavl.sec7_contTitle")/* 언어 데이터의 가능성, BAVL의 놀라움 */}}<br>"It’s unbelieBAVL"</p>
          <p class="cont_description">{{$t("busi_bavl.sec7_desc")/* 언어 데이터를 통해 경험을 쌓고, 지식으로 활용하여, 또 다른 가능성을 만들어보세요! */}}</p>
          <a href="https://bavl.lexcloud.ai" target="_blank" class="main_btn">{{$t("busi_bavl.sec7_btn")/* 배블 바로가기 → */}}</a>
        </div>
      </div>
    </section>
    <div class="header_bg"></div>
  </div>
</template>
