import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import commonHeader from './components/commonHeader.vue'
import commonFooter from './components/commonFooter.vue'
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import './assets/css/app.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import axios from 'axios'
import { createI18n } from 'vue-i18n'
import messages from './assets/lang/index'

const app = createApp(App)

app.component('common-header', commonHeader)
app.component('common-footer', commonFooter)
app.provide('$axios', axios)

const browserLanguage = navigator.language.split('-')[0] // 사용자 브라우저 언어

const i18n = createI18n({
  locale: browserLanguage, // 기본 언어
  allowComposition: true, // Vue3에서 사용하는 Composition API 모드 사용 여부
  legacy: false, // Composition API 사용하는 경우 false
  fallbackLocale: 'en', // 기본값 대체 로케일
  messages: messages, // 번역된 데이터들 상단 임포트한 파일
  missingWarn: false, // 공란 데이터 워닝 안보이도록 처리
  fallbackWarn: false, // 공란 데이터 워닝 안보이도록 처리
  warnHtmlMessage: false // HTML 경고를 비활성화

})
app.provide('$i18n', i18n)

app.use(store).use(router).use(Vue3Lottie).use(i18n)
app.mixin({
  created () {
    AOS.init()
  }
})
app.mount('#app')
