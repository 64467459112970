<script>
import MarqueeText from 'vue-marquee-text-component'
export default {
  name: 'companyPH',
  props: {
    window: Object
  },
  components: {
    MarqueeText
    // Map
  },
  data () {
    return {
      plusRotate: false,
      company_hq: true,
      company_ph: false,
      hoveredIndex: 0
    }
  },
  methods: {
    getImageSource (index) {
      if (this.hoveredIndex === index || this.window.width < 769) {
        return require(`../assets/img/company_section2_hover_img${index}.png`)
      } else {
        return require(`../assets/img/company_section2_img${index}.png`)
      }
    },
    toggleShow () {
      this.plusRotate = !this.plusRotate
    }
  }
}
</script>
<template>
  <div class="companyph">
    <section class="section1">
      <div class="container">
        <div class="text_wrap t_center">
          <h4>{{$t("companyph.sec1_subTitle")/* PHILIPPINES */}}</h4>
          <p class="cont_title">{{$t("companyph.sec1_contTitle")/* Lexcode, pioneering */}}</p>
          <p class="cont_title">{{$t("companyph.sec1_contTitle2")/* opportunities daily. */}}</p>
        </div>
        <img src="../assets/img/companyph_section1_img1.png" alt="">
      </div>
    </section>
    <section class="section2">
      <div class="container">
        <div class="text_wrap t_center">
          <h4>{{$t("companyph.sec2_subTitle")/* About Lexcode Philippines */}}</h4>
          <p class="cont_title">{{$t("companyph.sec2_contTitle")/* Lexcode holds the top position in the Philippines for translation, localization, and editing services, offering unparalleled expertise and quality. */}}</p>
          <div class="sub_title"><strong>10+</strong> {{$t("companyph.sec2_subTitle2")/* years of experience */}}</div>
          <div class="cont_description">{{$t("companyph.sec2_desc")/* Over a decade of proven trust and experience, underscored by our unwavering commitment to excellence. */}}</div>
        </div>
        <template v-if="window.width > 768">
          <template v-if="$i18n.locale == 'ko'">
            <img src="../assets/img/companyph_section2_img1.png" alt="">
          </template>
          <template v-else>
            <img src="../assets/img/companyph_section2_img1_en.png" alt="">
          </template>
        </template>
        <template v-else>
          <template v-if="$i18n.locale == 'ko'">
            <img src="../assets/img/companyph_section2_img1_mob.png" alt="">
          </template>
          <template v-else>
            <img src="../assets/img/companyph_section2_img1_mob_en.png" alt="">
          </template>
        </template>
      </div>
    </section>
    <section class="section3">
      <div class="container">
          <div class="text_wrap t_center">
            <p class="cont_title">{{$t("companyph.sec3_contTitle")/* Lexcode Philippines empowers companies to break language barriers, */}}</p>
            <p class="cont_title">{{$t("companyph.sec3_contTitle2")/* unveiling countless opportunities as the nation's top choice. */}}</p>
          </div>
      </div>
      <div class="marquee_wrap">
        <marquee-text :duration="30" :repeat="5">
          <img src="../assets/img/companyph_section3_img1.png" />
        </marquee-text>
        <marquee-text reverse :duration="30" :repeat="5">
          <img src="../assets/img/companyph_section3_img2.png" />
        </marquee-text>
      </div>
    </section>
    <section class="section4">
      <div class="container">
        <div class="text_wrap t_center t_white">
          <h4 class="t_white">Vision</h4>
          <p class="cont_title">{{$t("companyph.sec4_contTitle")/* Embracing language diversity, */}}</p>
          <p class="cont_title">{{$t("companyph.sec4_contTitle2")/* unlocking global potential. */}}</p>
        </div>
        <div class="text_wrap t_justify t_white">
          <p class="cont_description">{{$t("companyph.sec4_desc")/* Founded in 2010, Lexcode Philippines has spent more than a decade dismantling language barriers for businesses aiming for a global audience. What started as a simple vision to embody the ethos of “a company of the Filipino, by the Filipino, for the Filipino” ultimately became the driving force to evolve from a localization company to a technology-driven leader in the natural language processing industry. */}}</p><br>
          <p class="cont_description">{{$t("companyph.sec4_desc2")/* From English style editing and proofreading to leveraging technology with LEX-Cloud for translation and eQQui for multilingual interpretation, Lexcode Philippines continues to streamline processes and expand their reach in various regions across the nation. */}}</p><br>
          <p class="cont_description">{{$t("companyph.sec4_desc3")/* Thriving in the country’s central business hub, the local branch continues to foster a culture of growth, attracting diverse and remarkable talent with the core mission to pioneer groundbreaking initiatives in revolutionizing natural language AI technologies in the Philippines. */}}</p>
        </div>
      </div>
    </section>
    <section class="section5">
      <div class="container">
        <div class="text_wrap t_center">
          <h4>Milestone</h4>
          <p class="cont_title">{{$t("companyph.sec5_contTitle")/* With over 10 years of */}}<br>{{$t("companyph.sec5_contTitle2")/* making a difference. */}}</p>
        </div>
      </div>
      <div class="flex">
        <div class="left_cont"><img src="../assets/img/companyph_section5_img1.png" alt=""></div>
        <div class="right_cont">
          <div>
            <p class="cont_title">2023</p>
            <div class="text_wrap">
              <p class="cont_description">{{$t("companyph.sec5_desc")/* 렉스코드 필리핀, 자연어 처리 기술의 선도 기업으로서 발돋움 */}}</p>
            </div>
          </div>
          <div>
            <p class="cont_title">2020</p>
            <div class="text_wrap">
              <p class="cont_description">{{$t("companyph.sec5_desc2")/* LEX-Cloud 플랫폼 런칭 */}}</p>
            </div>
          </div>
          <div>
            <p class="cont_title">2019</p>
            <div class="text_wrap">
              <p class="cont_description">{{$t("companyph.sec5_desc3")/* 필리핀 현지 국제 통역 컨퍼런스 "Hasaan 7" 참가 */}}</p>
              <p class="cont_description">{{$t("companyph.sec5_desc4")/* UST Department of Filipino 사업 제휴 */}}</p>
              <p class="cont_description">{{$t("companyph.sec5_desc5")/* UST Sentro sa Salin at Araling Salin (번역 및 번역 연구 센터) 사업 제휴 */}}</p>
              <p class="cont_description">{{$t("companyph.sec5_desc6")/* Komisyon sa Wikang Filipino (KWF, 필리핀어 위원회) 사업 제휴 */}}</p>
            </div>
          </div>
          <div>
            <p class="cont_title">2017</p>
            <div class="text_wrap">
              <p class="cont_description">{{$t("companyph.sec5_desc7")/* eQQui 필리핀 설립 */}}</p>
              <p class="cont_description">{{$t("companyph.sec5_desc8")/* 아세안 비즈니스 & 투자 서밋 (AIBS) 참가 */}}</p>
              <p class="cont_description">{{$t("companyph.sec5_desc9")/* 제12차 이동성 야생동물 보존에 관한 협약(CMS COP12) 당사국 회의 참가 */}}</p>
            </div>
          </div>
          <div>
            <p class="cont_title">2010</p>
            <div class="text_wrap">
              <p class="cont_description">{{$t("companyph.sec5_desc10")/* 필리핀 현지 법인 설립 */}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section7">
      <div class="container">
        <div class="flex fix"  @click="toggleShow">
          <p class="cont_title">{{$t("company.sec7_contTitle")/* 궁금하신게 있다면 여기로 연락주세요. */}}</p>
          <p class="plus" :class="{ 'active' : plusRotate }"></p>
        </div>
        <transition name="roll-up">
          <div v-if="plusRotate" :class="{ 'active' : plusRotate }" class="sec7_cont flex">
            <template v-if="window.width > 768">
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle1")/* General Inquiries */}}<br>
                    <span>{{$t("companyph.sec7_contTitle1")/* Operations Support Team */}}</span>
                  </p>
                  <p class="cont_description">lexcodeph@lexcode.com</p>
                  <p class="cont_description">+63 (02) 8551 13 04</p>
                </div>
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle2")/* Business Partnerships */}}<br>
                    <span>{{$t("companyph.sec7_contTitle2")/* Andrea Bongalosa */}}</span>
                  </p>
                  <p class="cont_description">andie.b@lexcode.com</p>
                  <p class="cont_description">+63 917 804 2056</p>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle3")/* Translation Inquiries */}}<br>
                    <span>{{$t("companyph.sec7_contTitle3")/* Translation Team */}}</span>
                  </p>
                  <p class="cont_description">phpm@lexcode.com</p>
                  <p class="cont_description">+63 917 539 2633<br>+63 939 931 4034<br>+63 (02) 8553 3861</p>
                </div>
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle4")/* Interpretation Inquiries */}}<br>
                    <span>{{$t("companyph.sec7_contTitle4")/* Interpretation Team */}}</span>
                  </p>
                  <p class="cont_description">interpret@lexcode.com</p>
                  <p class="cont_description">+63 917 803 7437<br>+63 917 801 4949<br>+63 917 639 1976<br>+63 (02) 7975 4158<br>+63 (02) 7975 4504</p>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle5")/* Research Editing Inquiries */}}<br>
                    <span>{{$t("companyph.sec7_contTitle5")/* Journal Lab Team */}}</span>
                  </p>
                  <p class="cont_description">jll@lexcode.com</p>
                  <p class="cont_description"></p>
                </div>
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle6")/* Invoices and Billing */}}<br>
                    <span>{{$t("companyph.sec7_contTitle6")/* Accounting Team */}}</span>
                  </p>
                  <p class="cont_description">accounting@lexcode.com </p>
                  <p class="cont_description">+63 917 804 2056</p>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle7")/* HR Inquiries */}}<br>
                    <span>{{$t("companyph.sec7_contTitle7")/* HR Team */}}</span>
                  </p>
                  <p class="cont_description">lexcodehr@lexcode.com</p>
                  <p class="cont_description">+63 917 702 8384</p>
                </div>
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle8")/* Accounting Inquiries */}}<br>
                    <span>{{$t("companyph.sec7_contTitle8")/* Accounting Team */}}</span>
                  </p>
                  <p class="cont_description">accounting@lexcode.com</p>
                  <p class="cont_description">+63 917 804 2056</p>
                  </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle9")/* Freelance Opportunities */}}<br>
                    <span>{{$t("companyph.sec7_contTitle9")/* Vendor Management Team */}}</span>
                  </p>
                  <p class="cont_description">freelance.d@lexcode.com</p>
                  <p class="cont_description"></p>
                </div>
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle10")/* Marketing Inquiry */}}<br>
                    <span>{{$t("companyph.sec7_contTitle10")/* Business Development Team */}}</span>
                  </p>
                  <p class="cont_description">global@lexcode.com</p>
                  <p class="cont_description"></p>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle1")/* General Inquiries */}}<br>
                    <span>{{$t("companyph.sec7_contTitle1")/* Operations Support Team */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">lexcodeph@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">+63 (02) 8551 13 04</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle2")/* Business Partnerships */}}<br>
                    <span>{{$t("companyph.sec7_contTitle2")/* Andrea Bongalosa */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">andie.b@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">+63 917 804 2056</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle3")/* Translation Inquiries */}}<br>
                    <span>{{$t("companyph.sec7_contTitle3")/* Translation Team */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">phpm@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">+63 917 539 2633<br>+63 939 931 4034<br>+63 (02) 8553 3861</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle4")/* Interpretation Inquiries */}}<br>
                    <span>{{$t("companyph.sec7_contTitle4")/* Interpretation Team */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">interpret@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">+63 917 803 7437<br>+63 917 801 4949<br>+63 917 639 1976<br>+63 (02) 7975 4158<br>+63 (02) 7975 4504</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle5")/* Research Editing Inquiries */}}<br>
                    <span>{{$t("companyph.sec7_contTitle5")/* Journal Lab Team */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">jll@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">-</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle6")/* Invoices and Billing */}}<br>
                    <span>{{$t("companyph.sec7_contTitle6")/* Accounting Team */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">accounting@lexcode.com </p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">+63 917 804 2056</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle7")/* HR Inquiries */}}<br>
                    <span>{{$t("companyph.sec7_contTitle7")/* HR Team */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">lexcodehr@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">+63 917 702 8384</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle8")/* Accounting Inquiries */}}<br>
                    <span>{{$t("companyph.sec7_contTitle8")/* Accounting Team */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">accounting@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">+63 917 804 2056</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle9")/* Freelance Opportunities */}}<br>
                    <span>{{$t("companyph.sec7_contTitle9")/* Vendor Management Team */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">freelance.d@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">-</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_wrap flex">
                <div class="info flex">
                  <p class="cont_description">
                    {{$t("companyph.sec7_subTitle10")/* Marketing Inquiry */}}<br>
                    <span>{{$t("companyph.sec7_contTitle10")/* Business Development Team */}}</span>
                  </p>
                  <div class="info_wrap flex">
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img1.png"/>
                      <p class="cont_description">global@lexcode.com</p>
                    </div>
                    <div class="info_cont flex">
                      <img src="../assets/img/company_atGlance_section7_img2.png"/>
                      <p class="cont_description">-</p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            </div>
        </transition>
      </div>
    </section>
    <section class="section8">
      <div class="container t_center">
        <a href="https://ph.lexcode.com/" target="_bla" class="btn">{{$t("companyph.sec8_btn")/* Visit our PH Branch */}}</a>
      </div>
    </section>
    <div class="header_bg"></div>
  </div>
</template>
