<script>
export default {
  name: 'relationsPartners',
  data () {
    return {
      fieldTabs: ['partner.field1', 'partner.field2', 'partner.field3', 'partner.field4', 'partner.field5', 'partner.field6', 'partner.field7', 'partner.field8', 'partner.field9'],
      // fieldTabs: ['법률/정치', '인문/예술/문화/관광', '기계/기술', '경제/금융', '의학/제약', '에너지/원자력/환경', 'IT/게임', '기업문서/마케팅', '국방/군사'],
      data: [
        {
          field_tab: 'partner.field1',
          data: [
            {
              company: '국회도서관',
              work: '외국 법률 번역 및 법률번역 가이드라인 제작'
            },
            {
              company: '헌법재판소',
              work: '외국 헌법재판 자료 번역'
            },
            {
              company: '통일부',
              work: '독일 통일 관련 보고서 번역'
            },
            {
              company: '행정안전부',
              work: '공공기관의 정보공개에 관한 법률 시행령 번역'
            },
            {
              company: '법제처',
              work: '찾기 쉬운 생활법령 번역 (8개 언어), 홈페이지 콘텐츠 번역'
            },
            {
              company: '법원행정처',
              work: '외국인 사법지원을 위한 재판양식 번역 (16개 언어)'
            },
            {
              company: '대통령기록관',
              work: '역대 대통령 영문 서한 번역'
            },
            {
              company: '한국저작권위원회',
              work: '법률 및 홍보자료 번역'
            },
            {
              company: '개인정보보호위원회',
              work: '법률 및 해외 보고서 번역'
            },
            {
              company: '한국법령정보원',
              work: '찾기 쉬운 생활법령 다국어 번역'
            }
          ]
        },
        {
          field_tab: 'partner.field2',
          data: [
            {
              company: '문화체육관광부',
              work: '홍보 간행물 번역'
            },
            {
              company: '해외문화홍보원',
              work: '정부 다국어 포털 코리아넷 콘텐츠 제작 및 번역'
            },
            {
              company: '한국관광공사',
              work: '관광지명 및 음식명, 홍보 간행물 번역 및 감수'
            },
            {
              company: '부산관광공사',
              work: '관광 가이드북 등 홍보 간행물 번역'
            },
            {
              company: '국립아시아문화전당',
              work: '아시아문화예술 아카이브 목록 및 홈페이지 콘텐츠 번역'
            },
            {
              company: '제주관광공사',
              work: '관광사업 보고서 및 홍보자료 번역'
            },
            {
              company: '한국콘텐츠진흥원',
              work: '게임 콘텐츠 및 온라인 시장 분석 보고서 번역'
            },
            {
              company: '영화진흥위원회',
              work: 'KoBiz 사이트 콘텐츠 중문 번역 및 감수'
            },
            {
              company: '동북아역사재단',
              work: '독도 관련 보고서 및 논문 번역'
            },
            {
              company: '정선아리랑문화재단',
              work: '정선아리랑 가사 번역 및 발간'
            }
          ]
        },
        {
          field_tab: 'partner.field3',
          data: [
            {
              company: '한국엔지니어링협회',
              work: '엔지니어링 해외 진출을 위한 각국의 법률자료 번역'
            },
            {
              company: 'TTA(한국정보통신기술협회)',
              work: 'KS 부합화 (국제 표준 번역 및 KSDT 편집)'
            },
            {
              company: '한국스마트그리드협회',
              work: '해외 보고서, 서적, 국제 표준 번역'
            },
            {
              company: '한국국토정보공사',
              work: '2020년 주소의 국제표준화 대응 방안 연구용역 관련 번역'
            },
            {
              company: '인천국제공항공사',
              work: '공항 연구소 해외 보고서 번역'
            },
            {
              company: '교통안전공단',
              work: 'K-City 아키텍처 보고서, 자율 차 정책집, 관련 논문 번역'
            },
            {
              company: '한국연구재단',
              work: '해외 주요국 연구개발 전략 분석 보고서 번역'
            },
            {
              company: '한국산업기술진흥원(KIAT)',
              work: '보고서 및 해외 자료 번역'
            },
            {
              company: '대구경북과학기술원',
              work: '혁신형 융복합 기술 관련 논문 및 발표 자료 번역'
            },
            {
              company: '국토교통과학기술진흥원',
              work: '스마트시티 홍보자료 및 각종 발표 자료 번역'
            }
          ]
        },
        {
          field_tab: 'partner.field4',
          data: [
            {
              company: '산업통상자원부',
              work: '홈페이지 영문 번역 및 감수'
            },
            {
              company: '해양수산부',
              work: '보고서 및 내부 문서 번역'
            },
            {
              company: '중소벤처기업부',
              work: 'OECD 보고서 및 보도자료 번역'
            },
            {
              company: '중소기업청',
              work: '규정 및 보고서 번역'
            },
            {
              company: '통계청',
              work: '보고서 및 법률 번역'
            },
            {
              company: '특허청',
              work: '보고서 및 보도자료 번역'
            },
            {
              company: '한국조세재정연구원',
              work: '조세조약 관련 다자 간 협약 및 법률 해설서 번역'
            },
            {
              company: '대외경제정책연구원',
              work: '연구 리포트 및 해외 보고서 번역'
            },
            {
              company: '한국지식재산연구원',
              work: '지식재산 관련 연구논문 및 판례, 법령 번역'
            },
            {
              company: '한국표준협회',
              work: 'KS 부합화 표준 개발 및 보고서/홍보물 번역 보고서'
            }
          ]
        },
        {
          field_tab: 'partner.field5',
          data: [
            {
              company: '보건복지부',
              work: '의료 관련 법령, 국민 건강정책사업 보고서 번역'
            },
            {
              company: '국민건강보험공단',
              work: '건강 문진표 다국어 번역'
            },
            {
              company: '한국보건산업진흥원',
              work: '헬스케어 브로슈어 및 연구 자료, 진료서식집 다국어 번역'
            },
            {
              company: '식품안전정보원',
              work: '회의록 번역'
            },
            {
              company: '국립보건연구원',
              work: '보건 규정 번역'
            },
            {
              company: '한국보건의료연구원',
              work: '보고서, 발표 자료, 홈페이지 콘텐츠 번역'
            },
            {
              company: '근로복지공단 근로복지연구원',
              work: '질병 및 치료 관련 규정 보고서 번역'
            },
            {
              company: '한국한의학연구원',
              work: '보고서, 발표 자료, 논문 번역'
            },
            {
              company: '한국보건복지인력개발원',
              work: '의료 통역사 교육용 교재 번역'
            },
            {
              company: '나노융합산업연구조합',
              work: '보고서 및 홍보자료 번역'
            }
          ]
        },
        {
          field_tab: 'partner.field6',
          data: [
            {
              company: '기상청',
              work: '지구 관측 그룹(GEO) 34회차 총회 보고서, 총회 의제, 기후 관련 국제기구(IBCS) 회의자료, 백두산 천지 화산 도서 번역'
            },
            {
              company: '국립환경과학원',
              work: '해외 보고서, 포럼 자료 번역'
            },
            {
              company: '한국에너지공단',
              work: '보고서, 가이드라인, 발표 자료 번역'
            },
            {
              company: '한국수력원자력',
              work: '원자력 발전소 통번역'
            },
            {
              company: '한수원월성원자력본부',
              work: 'AIP 보고서 번역'
            },
            {
              company: '한국원자력연구원',
              work: '보고서 및 해외 자료 번역'
            },
            {
              company: '한국원자력기술원',
              work: '보고서 및 해외 자료 번역'
            },
            {
              company: '한국전력기술',
              work: '지침서 번역'
            },
            {
              company: '한국가스공사',
              work: '계약서, 절차서, 표준 규격 번역'
            },
            {
              company: '한국화재보험협회',
              work: '해외자료, 보고서 번역'
            }
          ]
        },
        {
          field_tab: 'partner.field7',
          data: [
            {
              company: '한국로봇산업진흥원',
              work: '로봇 상용화 전망에 관한 보고서 번역'
            },
            {
              company: '한국인터넷진흥원',
              work: '정보통신망법 관련 보고서 및 해외 자료 번역'
            },
            {
              company: '한국정보화진흥원',
              work: '보고서, 해외 자료, 발표 자료 번역'
            },
            {
              company: '정보통신산업진흥원',
              work: '전자상거래 준칙 번역'
            },
            {
              company: '한국네트워크산업협회',
              work: '5G 관련 이동통신 자료 번역'
            },
            {
              company: '4399 KOREA',
              work: '게임 콘텐츠 번역'
            },
            {
              company: '룽투코리아',
              work: '게임 콘텐츠 번역'
            },
            {
              company: '엔씨소프트',
              work: '게임 콘텐츠 번역 및 LQA'
            },
            {
              company: '한빛소프트',
              work: '게임 콘텐츠 번역'
            },
            {
              company: '카카오게임즈',
              work: '게임 콘텐츠 번역'
            },
            {
              company: '삼성SDS',
              work: '화면 설계서, 프로그램 매뉴얼 번역'
            }
          ]
        },
        {
          field_tab: 'partner.field8',
          data: [
            {
              company: 'CJ올리브네트웍스',
              work: '제품 홍보자료 및 내부 문서 번역'
            },
            {
              company: 'CJ인재원',
              work: '교육 콘텐츠 번역'
            },
            {
              company: 'EBS',
              work: '글로벌 홈페이지 동영상 콘텐츠 번역'
            },
            {
              company: 'KT&G',
              work: '계약서 및 보고서 번역'
            },
            {
              company: 'LG유플러스',
              work: '단말규격서 및 시험절차서 번역'
            },
            {
              company: '굿네이버스',
              work: '뉴스레터 번역'
            },
            {
              company: '남양유니베라',
              work: '규정 및 해외 자료 번역'
            },
            {
              company: '네오위즈',
              work: '게임 콘텐츠 및 보고서 번역'
            },
            {
              company: '대우조선해양',
              work: '법률 및 사내 문서 번역'
            },
            {
              company: '대웅제약',
              work: '임상시험 자료집, 보고서 번역'
            }
          ]
        },
        {
          field_tab: 'partner.field9',
          data: [
            {
              company: '국가보훈처',
              work: '정책 보고서 번역'
            },
            {
              company: '방위사업청',
              work: '방위산업 기술의 보호에 관한 법률, 미 안보지원 관리교범번역'
            },
            {
              company: '국군재정관리단',
              work: '기술 교범 번역'
            },
            {
              company: '국방기술품질원',
              work: '최신 방산기술정보 번역 및 감수 용역'
            },
            {
              company: '공군군수사령부',
              work: '항공 기술 교범 번역'
            },
            {
              company: '해군군수사령부',
              work: '기술 교범 번역'
            },
            {
              company: '국방부군사편찬연구소',
              work: '군사편찬 연구 보고서 번역, 한미 동맹 60년사 번역'
            },
            {
              company: '합동군사대학교',
              work: '군사 전략 번역'
            }
          ]
        }
      ],
      currentData: [],
      activeTab: 'partner.field1' // 초기값으로 '법률/정치'를 설정
    }
  },
  methods: {
    showData (fieldTab) {
      const matchingData = this.data.find(item => item.field_tab === fieldTab)
      if (matchingData) {
        this.currentData = matchingData.data
        this.activeTab = fieldTab // 선택한 탭을 활성화
      }
    }
  },
  mounted () {
    this.showData('partner.field1') // 기본값으로 '법률/정치' 데이터 표시
  }
}
</script>
<template>
  <div class="relations partners">
    <section class="section1">
      <div class="container">
        <h3 class="translator_title">{{$t("partner.sec1_subTitle")/* 파트너 실적 */}}</h3>
        <h1 class="cont_title">
          {{$t("partner.sec1_contTitle")/* 기회를 성공으로 연결하세요. */}}<br>{{$t("partner.sec1_contTitle2")/* 렉스코드가 더 넓은 세상으로 안내합니다. */}}
        </h1>
      </div>
    </section>
    <section class="section2"></section>
    <section class="section3" :class="{ en : $i18n.locale == 'en'}">
      <div class="container">
        <h1 class="cont_title t_center">
          {{$t("partner.sec3_contTitle")/* 6,000여 개의 기업들이 이미 더 쉽고 빠르게 번역 업무를 진행하고 있습니다. */}}
        </h1>
        <div class="flex">
          <div class="sec3_cont">
            <p class="num">
              <span class="before">{{$t("partner.sec3_num_1")/* 최우수 등급 */}}</span>
              80%
              <span class="after">{{$t("partner.sec3_num_2")/* [100점 만점 기준] */}}</span>
            </p>
            <p class="cont_description" v-html='$t("partner.sec3_desc1")'></p> <!-- {{/* 공공입찰 수주율 */}} -->
          </div>
          <div class="sec3_cont">
            <p class="num">
              <span class="before">{{$t("partner.sec3_num_1")/* 최우수 등급 */}}</span>
              {{$t("partner.sec3_num")/* 97.5점 */}}
              <span class="after">{{$t("partner.sec3_num_2")/* [100점 만점 기준] */}}</span>
            </p>
            <p class="cont_description" v-html='$t("partner.sec3_desc2")'></p> <!-- {{/* 조달청 계약이행실적 평가 */}} -->
          </div>
          <div class="sec3_cont">
            <p class="num">
              <span class="before">{{$t("partner.sec3_num_1")/* 최우수 등급 */}}</span>
              94%
              <span class="after">{{$t("partner.sec3_num_2")/* [100점 만점 기준] */}}</span>
            </p>
            <p class="cont_description" v-html='$t("partner.sec3_desc3")'></p> <!-- {{/* 번역 품질 만족도 */}} -->
          </div>
          <div class="sec3_cont">
            <p class="num">
              <span class="before">{{$t("partner.sec3_num_1")/* 최우수 등급 */}}</span>
              95%
              <span class="after">{{$t("partner.sec3_num_2")/* [100점 만점 기준] */}}</span>
            </p>
            <p class="cont_description" v-html='$t("partner.sec3_desc4")'></p> <!-- {{/* 서비스 품질 만족도 */}} -->
          </div>
        </div>
        <p class="sec3_description">{{$t("partner.sec3_desc5")/* * 2022 - 2023년 기준 */}}</p>
      </div>
    </section>
    <section class="section5">
      <div class="container">
        <h1 class="cont_title">{{$t("partner.sec5_contTitle")/* 매일 500개 이상의 프로젝트가 진행되고 있습니다. */}}</h1>
        <div class="field">
          <p class="tab-link" v-for="tab in fieldTabs" :key="tab" @click="showData(tab)" :class="{ active: tab === activeTab }">{{$t(tab) }}</p>
        </div>
        <div class="data-container flex">
          <div v-for="item in currentData" :key="item.company">
            <p class="company">{{ item.company }}</p>
            <p class="work">{{ item.work }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section6">
      <div class="container">
        <h1 class="cont_title">{{$t("partner.sec6_contTitle")/* 더 자세한 사례가 궁금하신가요? */}}</h1>
        <div class="sec6_cont_wrap flex">
          <router-link to="/relations/stories/469" class="sec6_cont">
            <div class="text_wrap">
              <p class="cont_subtitle">LEX-Cloud</p>
              <p class="cont_description">{{$t("partner.sec6_desc1")/* 로컬리제이션 스트림라인 구축 */}}</p>
            </div>
            <p class="arrow">→</p>
          </router-link>
          <router-link to="/relations/stories/470" class="sec6_cont">
            <div class="text_wrap">
              <p class="cont_subtitle">TransCreation</p>
              <p class="cont_description">{{$t("partner.sec6_desc2")/* 생성형 AI 기반 번역 솔루션 구축 */}}</p>
            </div>
            <p class="arrow">→</p>
          </router-link>
          <router-link to="/relations/stories/471" class="sec6_cont">
            <div class="text_wrap">
              <p class="cont_subtitle">CAT-X</p>
              <p class="cont_description">{{$t("partner.sec6_desc3")/* 커스텀 자동번역 엔진 구축 */}}</p>
            </div>
            <p class="arrow">→</p>
          </router-link>
          <router-link to="/relations/stories/472" class="sec6_cont">
            <div class="text_wrap">
              <p class="cont_subtitle">Journal LAB</p>
              <p class="cont_description">{{$t("partner.sec6_desc4")/* AI 논문 분석 시스템 구축 */}}</p>
            </div>
            <p class="arrow">→</p>
          </router-link>
          <router-link to="/relations/stories/473" class="sec6_cont">
            <div class="text_wrap">
              <p class="cont_subtitle">BAVL</p>
              <p class="cont_description">{{$t("partner.sec6_desc5")/* AI 학습용 데이터 구축 */}}</p>
            </div>
              <p class="arrow">→</p>
          </router-link>
        </div>
      </div>
    </section>
    <div class="header_bg"></div>
    </div>
  </template>
