<script>

export default {
  name: 'ReportEditor',
  inject: ['$axios'], // $axios를 주입받음
  data () {
    return {
      title: '',
      author: '',
      summary: '',
      summaryPlaceholder: '요약1\n요약2\n요약3\n\n엔터로 문단 나눔을 꼭 해주세요',
      PDFFile: null,
      droppedFile: null,
      isDragging: false
    }
  },
  mounted () {
  },
  methods: {
    handleFileChange (event) {
      const files = event.target.files
      this.handleFiles(files)
    },
    handleDragOver (event) {
      event.preventDefault()
      this.isDragging = true
    },
    handleDragLeave () {
      this.isDragging = false
    },
    handleDrop (event) {
      event.preventDefault()
      this.isDragging = false
      const files = event.dataTransfer.files
      this.handleFiles(files)
    },
    handleFiles (files) {
      const maxSize = 500 * 1000 * 1000 // 500MB (byte 단위)
      if (files && files.length > 0) {
        const file = files[0]
        if (file.size > maxSize) {
          alert(this.$t("stories.alert7")) // 파일 크기가 500MB 제한을 초과했습니다.
          return
        }
        // 파일 크기가 제한 내에 있는 경우 추가 처리 로직
        // console.log('파일 크기가 제한 내에 있습니다.')
        // 파일 처리 로직
        this.droppedFile = file
        this.PDFFile = file // 수정된 부분
        // console.log(files)
      }
    },
    openFileInput () {
      this.$refs.fileInput.click()
    },
    formatFileSize (size) {
      if (size === 0) return '0 Bytes'
      const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      const i = Math.floor(Math.log2(size) / 10)
      return `${(size / Math.pow(1024, i)).toFixed(2)} ${units[i]}`
    },
    resetFile () {
      this.droppedFile = null
    },
    saveContent () {
      const textWithLineBreaks = this.summary.replace(/\n/g, '<br>') // 개행 문자를 <br> 태그로 변경
      console.log(textWithLineBreaks) // 데모를 위한 출력
      if (confirm(this.$t("stories.alert8_1"))) { // '저장하시겠습니까?'
        const formData = new FormData()
        formData.append('title', this.title)
        formData.append('author', this.author)
        formData.append('summary_list', this.summary)
        formData.append('report_file', this.PDFFile)
        // console.log(formData, this.title, this.PDFFile)
        this.$axios
          .post('https://lexcode.co.kr/app/create_report', formData, { // 배포서버
          // .post('https://lexcode.co.kr/dev/create_report', formData, { // 개발서버
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            // console.log(response)
            this.$router.push('/technology/dev/')
            alert(this.$t("stories.alert9_1")) // '저장되었습니다.'
          })
          .catch(error => {
            console.error('failed:', error)
          })
      } else {
        //
      }
    }
  }
}
</script>
<template>
  <div class="relations newsletter-edit">
    <section class="section1">
      <div class="container">
        <p class="cont_title">{{$t("stories.update_title2")/* 연구일지 작성 */}}</p>
        <div class="wrap">
          <fieldset class="title">
            <label>{{$t("stories.title1")/* 제목 */}}</label>
            <input type="text" v-model="title" />
          </fieldset>
          <fieldset class="title">
            <label>{{$t("stories.title4")/* 작성자 */}}</label>
            <input type="text" v-model="author" />
          </fieldset>
          <fieldset class="title">
            <label>{{$t("stories.title8")/* 요약 */}}</label>
            <textarea v-model="summary" rows="5" :placeholder="summaryPlaceholder"></textarea>
          </fieldset>
          <fieldset class="title">
            <label>{{$t("stories.title9")/* PDF 파일 */}}</label>
            <div class="drop-area" :class="{ 'drag-over': isDragging, 'isFile': droppedFile != null}" @dragover.prevent="handleDragOver" @dragleave="handleDragLeave" @drop="handleDrop">
              <template v-if="droppedFile">
                <div class="file_info">
                <a :href="droppedFile.url" target="_blank">{{ droppedFile.name }} ({{ formatFileSize(droppedFile.size) }})</a>
              </div>
              <p v-if="droppedFile" @click="resetFile" class="reset-button">X</p>
              </template>
              <template v-else>
                <span>{{$t("stories.title7")/* 이곳에 파일을 드롭하세요 */}}</span>
                <p @click="openFileInput" class="plus">+</p>
              </template>
            </div>
            <input type="file" ref="fileInput" style="display: none" @change="handleFileChange"/>
          </fieldset>
        </div>
        <button class="main_btn" @click="saveContent()">{{$t("stories.btn_save")/* 저장 */}}</button>
      </div>
    </section>
  </div>
</template>
