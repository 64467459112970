<script>
import checklistModal from '../components/catxChecklist.vue'
export default {
  name: 'businessCatX',
  props: {
    window: Object
  },
  components: {
    checklistModal
  },
  data () {
    return {
      checklistVal: false,
      currentTagName: 'CAT-S',
      tabList: [
        {
          title: 'busi_catx.tab1_title', // 테크놀로지
          model: 'CAT-S',
          description: 'busi_catx.tab1_desc', // 정보를 정확하게 신속하게 전달하는 데 적합합니다. 매뉴얼, 고객 지원, 소프트웨어 로컬리제이션 등에 걸리는 시간과 비용을 현저하게 줄일 수 있습니다.
          description2: 'busi_catx.tab1_desc2'
        },
        {
          title: 'busi_catx.tab2_title', // 전자상거래
          model: 'CAT-C',
          description: 'busi_catx.tab2_desc', // 제품 설명, 고객 리뷰 등을 신속하게 전달함으로써 고객 경험을 향상시키고 비즈니스가 글로벌 시장으로 확대되는데 기여합니다.
          description2: 'busi_catx.tab2_desc2'
        },
        {
          title: 'busi_catx.tab3_title', // 헬스케어
          model: 'CAT-H',
          description: 'busi_catx.tab3_desc', // 병원이나 제약, 의료 장비 등의 기업은 환자 정보, 의료 기록, 임상 기록 등을 자동번역 함으로써 더욱 신속하고 정확한 서비스와 제품을 고객에게 전달하는 데 기여할 수 있습니다.
          description2: 'busi_catx.tab3_desc2'
        },
        {
          title: 'busi_catx.tab4_title', // 금융
          model: 'CAT-F',
          description: 'busi_catx.tab4_desc', // 숫자 오류가 발생하지 않도록 강화된 트레이닝을 거칩니다. 이렇게 생성된 금융 분야 자동번역 모델은 회계 분석 자료, 대차대조표, 기업 보고서 등을 자동화하는데 중대한 역할을 수행합니다.
          description2: 'busi_catx.tab4_desc2'
        },
        {
          title: 'busi_catx.tab5_title', // 여행
          model: 'CAT-HS',
          description: 'busi_catx.tab5_desc', // 회사의 상품, 서비스, 웹페이지 등을 신속하게 번역하여 고객에게 전달하거나 외국의 여행 콘텐츠를 고객에서 제공하는 용도로 활용될 수 있습니다. 뿐만 아니라 글로벌 고객들의 리뷰를 실시간으로 번역하는 데 활용할 수 있습니다.
          description2: 'busi_catx.tab5_desc2'
        },
        {
          title: 'busi_catx.tab6_title', // 제조
          model: 'CAT-T',
          description: 'busi_catx.tab6_desc', // 전문적인 용어와 표준화된 문서로 인해 커스텀 자동번역의 정확도와 투자대비효용이 가장 높은 분야 중 하나입니다. 기술 매뉴얼, 제품 사양, 관련 기술정보 등 방대한 정보를 예산과 시간의 제약 없이 활용할 수 있도록 합니다.
          description2: 'busi_catx.tab6_desc2'
        },
        {
          title: 'busi_catx.tab7_title', // 법률
          model: 'CAT-L',
          description: 'busi_catx.tab7_desc', // 렉스코드는 10여 년 이상 법률번역을 전문으로 해온 기업입니다. 법률 전문 번역사들이 만든 데이터로 학습된 법률 자동번역 모델은 민감한 내용의 보안을 유지하면서 최종 단계의 법률 문서를 완성하는데 획기적인 기여를 할 수 있습니다.
          description2: 'busi_catx.tab7_desc2'
        },
        {
          title: 'busi_catx.tab8_title', // 교육
          model: 'CAT-E',
          description: 'busi_catx.tab8_desc', // 연구소 및 교육기관, 글로벌 체인과 지속적인 교육을 수행해야 하는 기업에게 활용됩니다. 시시각각 업데이트되는 교제, 매뉴얼, 연구논문, 강의자료를 비용과 예산의 제약 없이 사용자에게 전달할 수 있습니다.
          description2: 'busi_catx.tab8_desc2'
        },
        {
          title: 'busi_catx.tab9_title', // 공공기관
          model: 'CAT-G',
          description: 'busi_catx.tab9_desc', // 방대한 공문서, 보안이 강조되는 민감한 정보, 수시로 변경되는 지침, 다양한 언어의 대민 서비스 등에 자동번역 엔진을 효과적으로 활용할 수 있습니다. 정보의 보안과 투명성, 어느 쪽이든 자동번역은 사용자의 의도에 맞게 활용될 수 있습니다.
          description2: 'busi_catx.tab9_desc2'
        },
        {
          title: 'busi_catx.tab10_title', // 미디어
          model: 'CAT-M',
          description: 'busi_catx.tab10_desc', // 뉴스 원고, 스크립트, SNS 콘텐츠, 영상 등 다양한 분야에서 트렌드를 가장 빠르게 반영한 자동 번역을 경험할 수 있습니다. 
          description2: 'busi_catx.tab10_desc2'
        }
      ],
      currentMonth: 'm0',
      tabMonth: [
        {
          month: 'm0',
          number: '01',
          class: 'chart1',
          title: 'busi_catx.tabMonth1_title', // 문제와 효과 정의
          time: 'busi_catx.tabMonth1_time', // 소요 시간: 1주
          description: 'busi_catx.tabMonth1_desc' // 고객이 자동번역 모델 구축을 통해서 어떤 문제를 해결하고자 하는지, 그리고 어떤 이익을 기대하는지 Feasibility 연구를 선행합니다. 초기 투자 비용에 따른 투자수익(ROI)을 추정하고, 이후 유지보수 및 개선 방안을 수립합니다. 렉스코드는 체계적으로 준비된 설문과 가이드라인을 통해 고객이 신속하고 합리적인 판단을 내릴 수 있도록 도움을 드립니다.
        },
        {
          month: 'm1',
          number: '02',
          class: 'chart2',
          title: 'busi_catx.tabMonth2_title', // 데이터 수집
          time: 'busi_catx.tabMonth2_time', // 소요 시간: 2주-4주
          description: 'busi_catx.tabMonth2_desc' // 자동번역을 구축하고자 하는 전문 분야의 언어 데이터를 분류하고 수집하는 과정입니다. 최근의 기술은 이미 대규모 데이터로 학습된 베이스 모델을 기준으로 하여 추가의 데이터를 학습시키는 방식으로 진행되므로 과거와 달리 대규모의 데이터 쌍은 필요하지 않지만, 최소한 1만 쌍 이상의 언어 데이터를 수집해야 합니다. 고객의 문서에서 언어 데이터를 구축하거나 추출하는 것은 가장 좋은 방법입니다.
        },
        {
          month: 'm2',
          number: '03',
          class: 'chart3',
          title: 'busi_catx.tabMonth3_title', // 데이터 전처리
          time: 'busi_catx.tabMonth3_time', // 소요 시간: 4주-8주
          description: 'busi_catx.tabMonth3_desc' // 수집된 데이터는 전처리 과정을 통하여 목적에 맞도록 가공됩니다. 다양한 NLP(Natural Language Processing) 기법을 활용하여, 민감한 데이터 비식별화, 원문과 번역문의 품질 검사, 고객에게 맞는 용어집 적용, 특수문자 처리 등의 작업을 수행합니다. 렉스코드는 20여 년간의 경험 안에서 번역뿐만 아니라 다양한 기업에게 언어 정제 서비스를 제공하고, 수백만 쌍의 언어 데이터를 구축해 왔습니다.
        },
        {
          month: 'm3',
          number: '04',
          class: 'chart4',
          title: 'busi_catx.tabMonth4_title', // 모델 트레이닝
          time: 'busi_catx.tabMonth4_time', // 소요시간: 3주-4주
          description: 'busi_catx.tabMonth4_desc' // 정제된 데이터를 사용 목적에 맞는 최적의 프레임워크(Tensorflow, Pytorch)에 투입하여 모델을 트레이닝하는 단계입니다. 이때 데이터는 학습데이터, 검증 데이터, 테스트 데이터로 분류해서 제공됩니다. 이를 바탕으로 Transformer, 그리고 그것을 응용한 BERT, GPT 등 다양한 신경망 모델을 활용할 수 있습니다. 렉스코드는 자사에 고사양 GPU를 보유하고 있어, 학습에 소요되는 시간과 비용을 절감하여 그 혜택을 고객과 공유합니다.
        },
        {
          month: 'm4',
          number: '05',
          class: 'chart5',
          title: 'busi_catx.tabMonth5_title', // 모델평가와 점진적 향상
          time: 'busi_catx.tabMonth5_time', // 소요시간: 4주
          description: 'busi_catx.tabMonth5_desc' // 학습된 자동번역 모델은 BLEU, ROUGE, ChrF와 같은 평가 척도에 의해서 정확도를 수치화할 뿐만 아니라, 전문가 감수에 의한 평가를 병행해서 품질 수준을 판단합니다. 최종 품질 향상을 위해, 학습률(Learning rate), 반복수(Epoch), 배치 크기(Batch size)와 같은 하이퍼 파라미터(Hyperparameter)를 조절하고, 적절한 최적화 알고리즘(Optimizer)을 적용하여 주어진 데이터 안에서 최대의 정확도를 끌어올리면서 더 이상의 향상이 이루어지지 않을 때까지 학습 과정을 반복합니다.
        },
        {
          month: 'm5',
          number: '06',
          class: 'chart6',
          title: 'busi_catx.tabMonth6_title', // 모델 배포
          time: 'busi_catx.tabMonth6_time', // 소요시간: 4주
          description: 'busi_catx.tabMonth6_desc' // 학습이 끝난 모델은 고객사의 활용 목적에 따라 클라우드 또는 로컬 서버에 세팅하는 백엔드 작업과, UX 디자인과 웹페이지를 구현하는 프론트 엔드 작업을 거쳐 최종적으로 고객이 활용할 수 있는 서비스로 전달됩니다. 이 과정은 보안과 웹 접근성을 고려하여 진행합니다. 이 외에도 사용자의 필요에 따라 용어검색이나 용어집 업데이트, 휴먼번역이나 감수 요청 등 다른 기능과 연동되는 기능이 추가되기도 합니다.
        },
        {
          month: 'm6',
          number: '07',
          class: 'chart7',
          title: 'busi_catx.tabMonth7_title', // 유지보수 및 버전 업그레이드
          time: 'busi_catx.tabMonth7_time', // 소요시간: 2년 이상
          description: 'busi_catx.tabMonth7_desc' // 시스템이 완성된 후에도 사용자의 트래픽에 따라 알맞은 서버용량을 조정하고, 중요한 데이터를 백업해 놓는 과정은 정기적으로 수행되어야 합니다. 뿐만 아니라 사람의 교정을 거쳐 더 향상된 언어 데이터와 새롭게 확보된 언어 데이터를 활용하여 고객의 자동번역 모델의 정확도를 지속적으로 향상시키고 고도화시키는 단계를 거치게 됩니다. 이렇게 구축된 자동번역 모델은 고객의 경쟁력을 강화시킬 뿐만 아니라 경쟁사가 단기간에 모방할 수 없는 기술적 장벽이 되어 줍니다.
        }
      ],
      activeIndex: -1
    }
  },
  methods: {
    checklistOpen () {
      this.checklistVal = true
    },
    checklistClose: function (value) {
      this.checklistVal = value
    },
    toggleActive (index) {
      if (this.activeIndex === index) {
        this.activeIndex = -1
      } else {
        this.activeIndex = index
      }
    }
  },
  computed: {
    currentList () {
      return this.tabList.filter((el) => el.model === this.currentTagName)
    },
    currentGantt () {
      return this.tabMonth.filter((el) => el.month === this.currentMonth)
    }
  }
}
</script>

<template>
  <div class="business catx">
    <section class="section1">
      <div class="container flex">
        <div class="left">
          <div class="left_cont">
            <img src="../assets/img/business_cmtx_section1_img1.png" />
          </div>
        </div>
        <div class="right">
          <div class="right_cont">
            <div class="flex">
              <p class="business_title">Business</p>
              <p class="business_num"><span>03 </span>— 05</p>
            </div>
            <p class="subtitle">{{$t("busi_catx.sec1_subTitle")/* 커스텀 자동번역 모델 */}}</p>
            <h1>CAT-X<br>{{$t("busi_catx.sec1_subTitle")/* 커스텀 자동 번역 모델 구축(CAT-X) */}}</h1>
            <!-- <a href="#" class="main_btn">시작하기 →</a> -->
          </div>
        </div>
      </div>
    </section>
    <section class="section2">
      <div class="container">
        <div class="text_wrap t_center">
          <p class="cont_title">{{$t("busi_catx.sec2_contTitle")/* 보다 우수한 */}}</p>
          <p class="underline_text">Customized Machine Translation</p>
          <p class="cont_subtitle">{{$t("busi_catx.sec2_subTitle")/* 맞춤형 AI 번역 엔진 */}} <span class="t_purple">{{$t("busi_catx.sec2_subTitle2")/* 을 제공합니다. */}}</span>{{$t("busi_catx.sec2_subTitle3")/* 렉스코드는 20년간 번역 서비스를 제공해 온 풍부한 경험을 보유하고 있습니다. */}}</p>
          <p class="cont_description">{{$t("busi_catx.sec2_desc")/* 쌓인 언어 데이터를 바탕으로 귀사가 속해있는 분야와 관련된 번역 서비스를 맞춤 설정할 수 있습니다. */}}<br>{{$t("busi_catx.sec2_desc2")/* 커스텀 자동 모델구축, */}}</p>
        </div>
      </div>
    </section>
    <section class="section3">
      <img src="../assets/img/business_cmtx_section3_bg.gif"/>
    </section>
    <section class="section4">
      <div class="container">
        <p class="cont_title">{{$t("busi_catx.sec4_contTitle")/* 이런 분야에서 사용합니다. */}}<br>{{$t("busi_catx.sec4_contTitle2")/* 커스텀 자동번역 모델은 이런 장점을 가져다줍니다. */}}</p>
        <div class="tab_wrap">
          <template v-if="window.width > 768">
            <div class="tab_list flex">
              <p class="tab" v-for="(item, index) in tabList" :key="index" :class="{ active: item.model === currentTagName }"
              @mouseover="currentTagName = item.model">
              {{$t(item.title)}}
              </p>
            </div>
          </template>
            <template v-else>
              <div class="tab_overflow">
                  <div class="tab_list flex">
                  <p class="tab" v-for="(item, index) in tabList" :key="index" :class="{ active: item.model === currentTagName }"
                    @mouseover="currentTagName = item.model">
                    {{$t(item.title)}}
                  </p>
                </div>
              </div>
            </template>
          <TransitionGroup name="fade-tab">
            <div class="tab_cont t_white" v-for="(item, index) in currentList" :key="index">
              <div :class="item.model == currentTagName ? item.model : ''">
                <p class="model">{{$t(item.title)}} {{ item.model }}</p>
                <p class="description">{{$t(item.description)}}<br>{{$t(item.description2)}}</p>
              </div>
            </div>
          </TransitionGroup>
        </div>
      </div>
    </section>
    <section class="section6">
      <div class="container">
        <div class="flex">
          <p class="cont_title text_wrap">{{$t("busi_catx.sec6_contTitle")/* 더욱 정확한 자동번역 */}}</p>
          <div class="flex sec8_cont">
            <div class="text_wrap">
              <img src="../assets/img/business_cmtx_section6_img1.png" />
              <p class="sec8_title">{{$t("busi_catx.sec6_title1")/* 고객의 산업 분야, 용어 데이터로 학습하므로 정교한 로컬리제이션을 가능하게 합니다. */}}</p>
              <p class="cont_description">{{$t("busi_catx.sec6_desc1")/* 경쟁력 강화 */}}</p>
            </div>
            <div class="text_wrap">
              <img src="../assets/img/business_cmtx_section6_img2.png" />
              <p class="sec8_title">{{$t("busi_catx.sec6_title2")/* 커스텀 자동번역은 궁극적으로 경쟁력을 강화시킵니다. */}}</p>
              <p class="cont_description">{{$t("busi_catx.sec6_desc2")/* 번역 비용 절감 */}}</p>
            </div>
            <div class="text_wrap">
              <img src="../assets/img/business_cmtx_section6_img3.png" />
              <p class="sec8_title">{{$t("busi_catx.sec6_title3")/* 내부ˑ외부로 지불하는 번역 비용을 줄여 효율을 향상시킵니다. */}}</p>
              <p class="cont_description">{{$t("busi_catx.sec6_desc3")/* 더욱 강화된 보안 */}}</p>
            </div>
            <div class="text_wrap">
              <img src="../assets/img/business_cmtx_section6_img4.png" />
              <p class="sec8_title">{{$t("busi_catx.sec6_title4")/* 기업의 민감한 정보가 외부에 노출되거나 유출되는 것을 원천적으로 차단합니다. */}}</p>
              <p class="cont_description">{{$t("busi_catx.sec6_desc4")/* 사용자 경험 증진 */}}</p>
            </div>
            <div class="text_wrap">
              <img src="../assets/img/business_cmtx_section6_img5.png" />
              <p class="sec8_title">{{$t("busi_catx.sec6_title5")/* 고객에게 제공되는 자동번역은 사용자들의 편의를 향상시킵니다. */}}</p>
              <p class="cont_description">{{$t("busi_catx.sec6_desc5")/* 정보공유 촉진 */}}</p>
            </div>
            <div class="text_wrap">
              <img src="../assets/img/business_cmtx_section6_img6.png" />
              <p class="sec8_title">{{$t("busi_catx.sec6_title6")/* 로컬리제이션 장벽을 낮추어 다국어로 정보공유를 촉진합니다. */}}</p>
              <p class="cont_description">{{$t("busi_catx.sec6_desc6")/* 자동번역 모델의 탄생과 성장까지 정리해 봤어요. */}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section7">
      <div class="container">
        <p class="cont_title">{{$t("busi_catx.sec7_contTitle")/* 선점하세요. */}}</p>
        <template v-if="window.width > 768">
          <div class="gantt">
            <div class="month">
              <p class="month_title">Month</p>
              <div class="chart_area"></div>
            </div>
            <div class="month">
              <p class="month_title">m1</p>
              <div class="chart_area"></div>
            </div>
            <div class="month">
              <p class="month_title">m2</p>
              <div class="chart_area"></div>
            </div>
            <div class="month">
              <p class="month_title">m3</p>
              <div class="chart_area"></div>
            </div>
            <div class="month flex">
              <p class="month_title">m4</p>
              <p class="month_title">m5-</p>
              <div class="chart_area"></div>
            </div>
            <div class="gantt_cont">
              <div v-for="(item, index) in tabMonth" :key="index"
                :class="[item.class, { 'active': currentMonth == item.month }]" @mouseover="currentMonth = item.month">
                {{$t(item.title)}}</div>
            </div>
          </div>
          <div class="gantt_description flex" v-for="(item, index) in currentGantt" :key="index">
            <b>{{ item.number }}</b>
            <b class="title">{{$t(item.title)}}</b>
            <img src="../assets/img/business_cmtx_section7_img1.png" />
            <p class="time">{{$t(item.time)}}</p>
            <p class="cont_description">{{$t(item.description)}}</p>
          </div>
        </template>
        <template v-else>
          <div class="sec7_cont_wrap">
            <div class="sec7_cont" v-for="(item, index) in tabMonth" :key="index" @click="toggleActive(index)">
              <p class="sec7_title">0{{ index + 1 }}  {{$t(item.title)}}</p>
              <p class="plus" :class="{ 'active' : activeIndex === index }"></p>
              <transition name="roll-up">
                <div class="sec7_description" v-if="activeIndex === index">
                  <img src="../assets/img/business_cmtx_section7_img1.png" /><p class="time">{{ item.time }}</p>
                  <p class="cont_description">{{$t(item.description)}}</p>
                </div>
              </transition>
            </div>
          </div>
        </template>
      </div>
    </section>
    <section class="section8">
      <div class="container t_white">
        <p class="cont_title t_center">{{$t("busi_catx.sec8_contTitle")/* 선점하세요. */}}</p>
        <p class="description t_center">
          {{$t("busi_catx.sec8_desc")/* 여러분의 경쟁사는 이미 활용하고 있습니다. */}}<br>
          {{$t("busi_catx.sec8_desc2")/* 40% 할인된 비용에 커스텀 자동번역과 Chat-GPT까지 구축하면, */}}<br>
          {{$t("busi_catx.sec8_desc3")/* 1년 이내에 투자 비용을 회수하고 */}}<br>
          {{$t("busi_catx.sec8_desc4")/* 최소한 1명 이상의 인력을 대체하는 효과가 발생합니다. */}}</p>
      </div>
    </section>
    <section class="section9">
      <div class="container">
        <p class="cont_title">{{$t("busi_catx.sec9_contTitle")/* 구축 비용 */}}</p>
        <div class="flex mgt">
          <div class="build_price active">
            <p class="title">Cloud</p>
            <p class="cont_description">{{$t("busi_catx.sec9_desc")/* 별도의 장비 없이 렉스코드가 서비스를 관리해 드립니다. */}}<br>{{$t("busi_catx.sec9_desc1")/*     "sec9_desc1": "", */}}</p>
            <p class="before_price">{{$t("busi_catx.sec9_before_p")/* 4,850 만원 */}}</p>
            <div class="price_wrap">
              <p class="t_purple">40%</p>
              <p class="price">{{$t("busi_catx.sec9_price")/* 1,975 */}}<span>{{$t("busi_catx.sec9_krw")/* 만 원 */}}</span></p>
              <p class="vat">{{$t("busi_catx.sec9_vat")/* VAT 별도 */}}</p>
            </div>
            <div class="work_wrap">
              <div class="text_wrap flex">
                <img src="../assets/img/business_cmtx_section9_chk.png"/>
                <p class="description">{{$t("busi_catx.sec9_cloud_desc1")/* 기본 · 강화 학습 모델 구축 포함 */}}</p>
              </div>
              <div class="text_wrap flex">
                <img src="../assets/img/business_cmtx_section9_chk.png"/>
                <p class="description">{{$t("busi_catx.sec9_cloud_desc2")/* 백엔드 서버 구축, 포팅 및 프론트 비용 포함 */}}</p>
              </div>
              <div class="text_wrap flex">
                <img src="../assets/img/business_cmtx_section9_chk.png"/>
                <p class="description">{{$t("busi_catx.sec9_cloud_desc3")/* 계약 후 x연간 운영 · 유지보수 포함 */}}</p>
              </div>
            </div>
          </div>
          <div class="build_price">
            <p class="title">On-Premise</p>
            <p class="cont_description">{{$t("busi_catx.sec9_desc2")/* 귀사의 자체적으로 보유한 전산 서버에 직접 설치해 드립니다. */}}</p>
            <div class="price_wrap">
              <p class="price">{{$t("busi_catx.sec9_price2")/* 4,850 */}}<span>{{$t("busi_catx.sec9_krw")/* 만 원 */}}</span></p>
              <p class="vat">{{$t("busi_catx.sec9_vat2")/* VAT·서버장비 별도 */}}</p>
            </div>
            <div class="work_wrap">
              <div class="text_wrap flex">
                <img src="../assets/img/business_cmtx_section9_chk.png"/>
                <p class="description">{{$t("busi_catx.sec9_cloud_desc1")/* 기본 · 강화 학습 모델 구축 포함 */}}</p>
              </div>
              <div class="text_wrap flex">
                <img src="../assets/img/business_cmtx_section9_chk.png"/>
                <p class="description">{{$t("busi_catx.sec9_cloud_desc2")/* 백엔드 서버 구축, 포팅 및 프론트 비용 포함 */}}</p>
              </div>
              <div class="text_wrap flex">
                <img src="../assets/img/business_cmtx_section9_chk.png"/>
                <p class="description">{{$t("busi_catx.sec9_cloud_desc3")/* 계약 후 x연간 운영 · 유지보수 포함 */}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section10">
      <div class="container">
        <p class="cont_title">{{$t("busi_catx.sec10_contTitle")/* 상세 금액 안내 */}}</p>
        <template v-if="window.width > 768">
          <div class="table_wrap">
            <div class="th">
              <p>{{$t("busi_catx.sec10_tr1_td1")/* 내역 */}}</p>
              <p>{{$t("busi_catx.sec10_tr1_td2")/* 작업기간 */}}</p>
              <p>{{$t("busi_catx.sec10_tr1_td3")/* 비용 */}}</p>
              <p>{{$t("busi_catx.sec10_tr1_td4")/* 작업내역, 조건 */}}</p>
            </div>
            <div class="td">
              <p>{{$t("busi_catx.sec10_tr2_td1")/* Feasibility Report, 모델 디자인 */}}</p>
              <p>{{$t("busi_catx.sec10_tr2_td2")/* 1주 */}}</p>
              <p>{{$t("busi_catx.sec10_tr2_td3")/* 무료 */}}</p>
              <p v-html='$t("busi_catx.sec10_tr2_td4")'></p><!-- {{/* 보고서 10쪽 내외<br>사용자 기능 UX 디자인 */}} -->
            </div>
            <div class="td">
              <p>{{$t("busi_catx.sec10_tr3_td1")/* 기본모델 구축 */}}</p>
              <p>{{$t("busi_catx.sec10_tr3_td2")/* 3주 */}}</p>
              <p>{{$t("busi_catx.sec10_tr3_td3")/* 1천만 원 */}}</p>
              <p>{{$t("busi_catx.sec10_tr3_td4")/* 한-영 말뭉치 2천만 쌍 이상 활용 포함, 컴퓨팅 운영비 */}}</p>
            </div>
            <div class="td">
              <p>{{$t("busi_catx.sec10_tr4_td1")/* 사용자 특화 말뭉치 수집, 정제, 윤문 */}}</p>
              <p>{{$t("busi_catx.sec10_tr4_td2")/* 4주 */}}</p>
              <p>{{$t("busi_catx.sec10_tr4_td3")/* 750만 원 */}}</p>
              <p>{{$t("busi_catx.sec10_tr4_td4")/* 기존 데이터 1만 쌍 한영 말뭉치 활용기준 */}}</p>
            </div>
            <div class="td">
              <p>{{$t("busi_catx.sec10_tr5_td1")/* 사용자 특화 용어집 구축 */}}</p>
              <p>{{$t("busi_catx.sec10_tr5_td2")/* 1주 */}}</p>
              <p>{{$t("busi_catx.sec10_tr5_td3")/* 300만 원 */}}</p>
              <p>{{$t("busi_catx.sec10_tr5_td4")/* 1천 쌍 이상 Terminology 구축 */}}</p>
            </div>
            <div class="td">
              <p>{{$t("busi_catx.sec10_tr6_td1")/* 강화학습모델 구축 */}}</p>
              <p>{{$t("busi_catx.sec10_tr6_td2")/* 3주 */}}</p>
              <p>{{$t("busi_catx.sec10_tr6_td3")/* 1천만 원 */}}</p>
              <p>{{$t("busi_catx.sec10_tr6_td4")/* 컴퓨팅 운영비 포함 */}}</p>
            </div>
            <div class="td">
              <p>{{$t("busi_catx.sec10_tr7_td1")/* 백엔드 서버 구축, 포팅 */}}</p>
              <p>{{$t("busi_catx.sec10_tr7_td2")/* 2주 */}}</p>
              <p>{{$t("busi_catx.sec10_tr7_td3")/* 5백만 원 */}}</p>
              <p>{{$t("busi_catx.sec10_tr7_td4")/* 동시접속자 100명 이내. 아마존 클라우드 기준 */}}</p>
            </div>
            <div class="td">
              <p>{{$t("busi_catx.sec10_tr8_td1")/* 프론트 페이지 개발 */}}</p>
              <p>{{$t("busi_catx.sec10_tr8_td2")/* 2주 */}}</p>
              <p>{{$t("busi_catx.sec10_tr8_td3")/* 5백만 원 */}}</p>
              <p>{{$t("busi_catx.sec10_tr8_td4")/* 용어 검색, 사용 이력 로그, 편집툴 등 */}}</p>
            </div>
            <div class="td">
              <p>{{$t("busi_catx.sec10_tr9_td1")/* 운영, 유지보수 */}}</p>
              <p>{{$t("busi_catx.sec10_tr9_td2")/* 1년 */}}</p>
              <p>{{$t("busi_catx.sec10_tr9_td3")/* 800만원 */}}</p>
              <p v-html='$t("busi_catx.sec10_tr9_td4")'></p><!-- {{/* Cloud 1년간 서버 운영비, 웹 호스팅, 사용자 트래픽에 따른 서버비용<br>On-Premise 로컬 서버 세팅 비용 */}} -->
            </div>
          </div>
        </template>
        <template v-else>
          <div class="sec10_cont">
            <p class="sec10_title">{{$t("busi_catx.sec10_tr2_td1")/* Feasibility Report, 모델 디자인 */}}</p>
            <div class="table_wrap">
              <div class="th">
                <p>{{$t("busi_catx.sec10_tr1_td2")/* 내역 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td3")/* 작업기간 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td4")/* 비용 */}}</p>
              </div>
              <div class="td">
                <p>{{$t("busi_catx.sec10_tr2_td2")/* 1주 */}}</p>
                <p>{{$t("busi_catx.sec10_tr2_td3")/* 무료 */}}</p>
                <p v-html="$t('busi_catx.sec10_tr2_td4')"></p> <!-- 보고서 10쪽 내외<br>사용자 기능 UX 디자인 -->
              </div>
            </div>
          </div>
          <div class="sec10_cont">
            <p class="sec10_title">{{$t("busi_catx.sec10_tr3_td1")/* 기본모델 구축 */}}</p>
            <div class="table_wrap">
              <div class="th">
                <p>{{$t("busi_catx.sec10_tr1_td2")/* 내역 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td3")/* 작업기간 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td4")/* 비용 */}}</p>
              </div>
              <div class="td">
                <p>{{$t("busi_catx.sec10_tr3_td2")/* 3주 */}}</p>
                <p>{{$t("busi_catx.sec10_tr3_td3")/* 1천만 원 */}}</p>
                <p v-html="$t('busi_catx.sec10_tr3_td4')"></p> <!-- 한-영 말뭉치 2천만 쌍 이상 활용 포함, 컴퓨팅 운영비 -->
              </div>
            </div>
          </div>
          <div class="sec10_cont">
            <p class="sec10_title">{{$t("busi_catx.sec10_tr4_td1")/* 사용자 특화 말뭉치 수집, 정제, 윤문 */}}</p>
            <div class="table_wrap">
              <div class="th">
                <p>{{$t("busi_catx.sec10_tr1_td2")/* 내역 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td3")/* 작업기간 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td4")/* 비용 */}}</p>
              </div>
              <div class="td">
                <p>{{$t("busi_catx.sec10_tr4_td2")/* 4주 */}}</p>
                <p>{{$t("busi_catx.sec10_tr4_td3")/* 750만 원 */}}</p>
                <p v-html="$t('busi_catx.sec10_tr4_td4')"></p> <!-- 기존 데이터 1만 쌍 한영 말뭉치 활용기준 -->
              </div>
            </div>
          </div>
          <div class="sec10_cont">
            <p class="sec10_title">{{$t("busi_catx.sec10_tr5_td1")/* 사용자 특화 용어집 구축 */}}</p>
            <div class="table_wrap">
              <div class="th">
                <p>{{$t("busi_catx.sec10_tr1_td2")/* 내역 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td3")/* 작업기간 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td4")/* 비용 */}}</p>
              </div>
              <div class="td">
                <p>{{$t("busi_catx.sec10_tr5_td2")/* 1주 */}}</p>
                <p>{{$t("busi_catx.sec10_tr5_td3")/* 300만 원 */}}</p>
                <p v-html="$t('busi_catx.sec10_tr5_td4')"></p> <!-- 1천 쌍 이상 Terminology 구축 -->
              </div>
            </div>
          </div>
          <div class="sec10_cont">
            <p class="sec10_title">{{$t("busi_catx.sec10_tr6_td1")/* 강화학습모델 구축 */}}</p>
            <div class="table_wrap">
              <div class="th">
                <p>{{$t("busi_catx.sec10_tr1_td2")/* 내역 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td3")/* 작업기간 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td4")/* 비용 */}}</p>
              </div>
              <div class="td">
                <p>{{$t("busi_catx.sec10_tr6_td2")/* 3주 */}}</p>
                <p>{{$t("busi_catx.sec10_tr6_td3")/* 1천만 원 */}}</p>
                <p v-html="$t('busi_catx.sec10_tr6_td4')"></p> <!-- 컴퓨팅 운영비 포함 -->
              </div>
            </div>
          </div>
          <div class="sec10_cont">
            <p class="sec10_title">{{$t("busi_catx.sec10_tr7_td1")/* 백엔드 서버 구축, 포팅 */}}</p>
            <div class="table_wrap">
              <div class="th">
                <p>{{$t("busi_catx.sec10_tr1_td2")/* 내역 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td3")/* 작업기간 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td4")/* 비용 */}}</p>
              </div>
              <div class="td">
                <p>{{$t("busi_catx.sec10_tr7_td2")/* 2주 */}}</p>
                <p>{{$t("busi_catx.sec10_tr7_td3")/* 5백만 원 */}}</p>
                <p v-html="$t('busi_catx.sec10_tr7_td4')"></p> <!-- 동시접속자 100명 이내. 아마존 클라우드 기준 -->
              </div>
            </div>
          </div>
          <div class="sec10_cont">
            <p class="sec10_title">{{$t("busi_catx.sec10_tr8_td1")/* 프론트 페이지 개발 */}}</p>
            <div class="table_wrap">
              <div class="th">
                <p>{{$t("busi_catx.sec10_tr1_td2")/* 내역 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td3")/* 작업기간 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td4")/* 비용 */}}</p>
              </div>
              <div class="td">
                <p>{{$t("busi_catx.sec10_tr8_td2")/* 2주 */}}</p>
                <p>{{$t("busi_catx.sec10_tr8_td3")/* 5백만 원 */}}</p>
                <p v-html="$t('busi_catx.sec10_tr8_td4')"></p> <!-- 용어 검색, 사용 이력 로그, 편집툴 등 -->
              </div>
            </div>
          </div>
          <div class="sec10_cont">
            <p class="sec10_title">{{$t("busi_catx.sec10_tr9_td1")/* 운영, 유지보수 */}}</p>
            <div class="table_wrap">
              <div class="th">
                <p>{{$t("busi_catx.sec10_tr1_td2")/* 내역 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td3")/* 작업기간 */}}</p>
                <p>{{$t("busi_catx.sec10_tr1_td4")/* 비용 */}}</p>
              </div>
              <div class="td">
                <p>{{$t("busi_catx.sec10_tr9_td2")/* 1년 */}}</p>
                <p>{{$t("busi_catx.sec10_tr9_td3")/* 800만원 */}}</p>
                <p v-html="$t('busi_catx.sec10_tr9_td4')"></p> <!-- Cloud 1년간 서버 운영비, 웹 호스팅, 사용자 트래픽에 따른 서버비용<br>On-Premise 로컬 서버 세팅 비용 -->
              </div>
            </div>
          </div>
        </template>
      </div>
    </section>
    <section class="section11">
      <p class="cont_title t_white">{{$t("busi_catx.sec11_contTitle")/* 우리회사에 커스텀 자동번역모델이 필요할까? */}}</p>
      <a @click="checklistOpen()" class="main_btn">{{$t("busi_catx.sec11_btn")/* 5분만에 알아보기 → */}}</a>
    </section>
    <transition name="overlay-fade">
      <checklistModal v-if="checklistVal == true" :checklist-val="checklistVal" @close:checklistModal="checklistClose">
      </checklistModal>
    </transition>
    <transition name="overlay-fade">
      <p class="main_btn checklist" @click="checklistOpen()" v-if="checklistVal == false">{{$t("busi_catx.float_btn")/* 커스텀 자동번역 모델 진단테스트하 */}}<img
          src="../assets/img/business_cmtx_modalBtn_img1.png" /></p>
    </transition>
    <div class="header_bg"></div>
    <transition name="overlay-fade">
      <div v-if="checklistVal == true" class="overlay"></div>
    </transition>
</div>
</template>
