<script>
export default {
  name: 'notFound',
  methods: {
    goBack () {
      const currentPath = window.location.pathname
      const targetPath = '/relations/stories/'

      if (currentPath.includes(targetPath)) {
        this.$router.go(-2)
      } else {
        this.$router.go(-1)
      }
    }
  }
}

</script>
<template>
  <div class="notfound">
    <section>
        <div class="text_wrap t_white">
            <p style="color:#FED940">Ooops, Page Not found!</p>
            <h1>{{$t("not_found.title") }}</h1>
            <p class="cont_description">
              {{$t("not_found.desc") }}
              <br>
              {{$t("not_found.desc2") }}
            </p>
        </div>
        <p @click="goBack()" class="main_btn">{{$t("not_found.btn") }}</p>
        <img src="../assets/img/404.png"/>
    </section>
  </div>
</template>
