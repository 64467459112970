<script>
import { computed, ref, inject, onMounted } from 'vue'

export default {
  name: 'technologyDev',
  data () {
    return {
    }
  },
  setup () {
    const $axios = inject('$axios') // $axios 주입 받기
    const itemsPerPage = 10 // 페이지 당 표시할 항목 수
    const currentPage = ref(1)
    const byAll = ref([])
    const response = ref(null) // 초기값으로 null 설정
    const scrollContainer = ref(null) // 초기값으로 null 설정
    const loginYN = ref(null) // 초기값으로 null 설정

    // 검색, 소팅 변수 지정
    const isDropdownOpen = ref(false)
    const selectedOption = ref({ text: 'tech_dev.filter1', value: { sort: 'created_at', ascending: 'DESC' } })
    const options = [
      { text: 'tech_dev.filter1', value: { sort: 'created_at', ascending: 'DESC' } }, /* 최신순 */
      { text: 'tech_dev.filter2', value: { sort: 'views', ascending: 'DESC' } }, /* 조회수 높은순 */
      { text: 'tech_dev.filter3', value: { sort: 'likes', ascending: 'DESC' } }, /* 추천수 높은순 */
      { text: 'tech_dev.filter4', value: { sort: 'title', ascending: 'ASC' } } /* 가나다순 */
    ]
    const keyword = ref('')

    // 전체 포스트 가져오기 + 검색어 + 소팅값
    async function fetchPosts (selectedOption, keywordValue) {
      try {
        response.value = await $axios.post('https://lexcode.co.kr/app/get_all_report_by_sort', {
        // response.value = await $axios.post('https://lexcode.co.kr/dev/get_all_report_by_sort', {
          sort: selectedOption.value.sort,
          ascending: selectedOption.value.ascending,
          keyword: keywordValue
        })
        // console.log(response.data)
        if (Array.isArray(response.value.data)) {
          byAll.value = response.value.data.map(item => {
            return {
              ...item,
              created_at: formatDate(item.created_at)
            }
          })
        } else {
          byAll.value = []
          response.value = response.value.data
        }
      } catch (error) {
        console.error(error)
      }
    }
    // 날짜 변환 함수
    function formatDate (dateString) {
      const date = new Date(dateString)
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      return formattedDate
    }

    // 소팅, 검색 기능
    function toggleDropdown () {
      isDropdownOpen.value = !isDropdownOpen.value
    }

    function selectOption (option) {
      selectedOption.value = option
      isDropdownOpen.value = false
      fetchPosts(option, keyword.value)
    }

    function searchPost () {
      fetchPosts(selectedOption.value, keyword.value)
      currentPage.value = 1
      // console.log(selectedOption.value, keyword.value)
    }

    function resetKeyword () {
      keyword.value = ''
      fetchPosts(selectedOption.value, keyword.value)
    }

    // 페이지 각각 장수 계산
    const paginatedData = computed(() => {
      const startIndex = (currentPage.value - 1) * itemsPerPage
      const endIndex = startIndex + itemsPerPage
      return byAll.value.slice(startIndex, endIndex)
    })
    // 전체 페이지 계산
    const totalPages = computed(() => Math.ceil(byAll.value.length / itemsPerPage))
    // 현재 페이지 주변에 표시할 페이지 번호 계산
    const visiblePages = computed(() => {
      const pageRange = 1 // 현재 페이지를 기준으로 양쪽에 표시할 페이지 번호 개수
      const totalVisiblePages = 4 // 처음과 마지막을 제외한 표시될 페이지 번호 개수
      const currentPageIndex = currentPage.value - 1
      const visiblePages = []

      if (totalPages.value <= totalVisiblePages + 2) {
        // 페이지 번호가 모두 표시 가능한 경우
        visiblePages.push(...Array.from({ length: totalPages.value }, (_, index) => index + 1))
      } else if (currentPageIndex < totalVisiblePages - pageRange) {
        // 현재 페이지가 처음 페이지 근처에 위치한 경우
        visiblePages.push(...Array.from({ length: totalVisiblePages + 1 }, (_, index) => index + 1))
        visiblePages.push('...')
        visiblePages.push(totalPages.value)
      } else if (currentPageIndex >= totalPages.value - 2 - pageRange) {
        // 현재 페이지가 마지막 페이지 근처에 위치한 경우
        visiblePages.push(1)
        visiblePages.push('...')
        visiblePages.push(...Array.from({ length: totalVisiblePages + 1 }, (_, index) => totalPages.value - totalVisiblePages + index))
      } else {
        // 현재 페이지가 중간에 위치한 경우
        visiblePages.push(1)
        visiblePages.push('...')
        visiblePages.push(...Array.from({ length: pageRange * 2 + 1 }, (_, index) => currentPageIndex - pageRange + index + 1))
        visiblePages.push('...')
        visiblePages.push(totalPages.value)
      }

      return visiblePages
    })
    function changePage (page) {
      currentPage.value = page
      localStorage.setItem('savePage', JSON.stringify(currentPage.value))
      scrollContainer.value.scrollIntoView()
    }

    onMounted(() => {
      const savedPage = localStorage.getItem('savePage')
      if (savedPage) {
        currentPage.value = JSON.parse(savedPage)
      }
      // 페이지 로드 시 구동
      // console.log(selectedOption.value, keyword.value)
      fetchPosts(selectedOption.value, keyword.value)
      localStorage.removeItem('savePage')
      const login = localStorage.getItem('login')
      if (login) {
        loginYN.value = true
      } else {
        loginYN.value = false
      }
    })

    return {
      currentPage,
      paginatedData,
      totalPages,
      visiblePages,
      scrollContainer,
      changePage,
      itemsPerPage,
      byAll,
      isDropdownOpen,
      selectedOption,
      options,
      keyword,
      toggleDropdown,
      selectOption,
      searchPost,
      response,
      resetKeyword,
      loginYN
    }
  }
}
</script>
<template>
    <div class="technology dev">
      <section class="section1">
        <div class="container">
          <h1 class="cont_title">{{$t("tech_dev.sec1_contTitle")/* 우리의 기술을 통해 */}}<br>{{$t("tech_dev.sec1_contTitle2")/* 더 큰 혁신에 도전합니다. */}}</h1>
          <p class="cont_description">{{$t("tech_dev.sec1_desc")/* 인공지능을 활용하여 사람의 언어를 이해하고, 그 정보를 다시 사람의 언어로 생성하도록 하는일, 우리가 도전하고 있는 멋진 과제입니다. */}}</p>
        </div>
      </section>
      <section class="section2">
        <div class="container">
          <div class="sec2_cont_wrap flex">
            <div class="sec2_cont">
              <img src="../assets/img/technology_dev_section1_img1.png"/>
              <p class="sec2_cont_title">ATPE</p>
              <p class="sec2_cont_subtitle">AI Translation and Post Editing</p>
              <p class="cont_description">{{$t("tech_dev.sec2_desc")/* 사람과 기술의 협업. */}}<br>{{$t("tech_dev.sec2_desc2")/* 우리의 언어를 더욱 확실하게, 그리고 그 안에 담긴 가치를 가장 효율적으로 전달하고자 합니다. */}}</p>
            </div>
            <div class="sec2_cont">
              <img src="../assets/img/technology_dev_section1_img2.png"/>
              <p class="sec2_cont_title">CAT-X</p>
              <p class="sec2_cont_subtitle">Customized Machine Translation Model</p>
              <p class="cont_description">{{$t("tech_dev.sec2_desc3")/* 누구나 쉽고, 편하게 활용할 수 있도록. 인공지능과 딥러닝 기술을 활용한 자동번역 모델을 통해 더 넓은 기회를 지원하고자 합니다. */}}</p>
            </div>
            <div class="sec2_cont">
              <img src="../assets/img/technology_dev_section1_img3.png"/>
              <p class="sec2_cont_title">Language Data Mining</p>
              <p class="sec2_cont_subtitle" style="opacity: 0">Customized Machine Translation Model</p>
              <p class="cont_description">{{$t("tech_dev.sec2_desc4")/* 언어에 담긴 정보를 장벽 없이 자유자재로 탐색하고 분석하여 비즈니스를 성장시키는 인사이트를 얻을 수 있도록 새로운 차원의 가능성을 제시하고자 합니다. */}}</p>
            </div>
          </div>
        </div>
      </section>
      <section class="section3" id="reportList" ref="scrollContainer">
        <div class="container">
          <div class="text_wrap">
            <p class="cont_subtitle">{{$t("tech_dev.sec3_subTitle")/* 연구일지, 함께 참여해 주세요. */}}</p>
            <p class="cont_description">{{$t("tech_dev.sec3_desc")/* 누군가에게는 우리의 경험이 도움이 되고, 또 누군가는 우리의 경험에 도움이 되어 주기를 희망합니다. */}}<br>{{$t("tech_dev.sec3_desc2")/* 지식의 개방과 공유는 우리의 변함없는 철학입니다. */}}</p>
          </div>
          <div v-if="loginYN" class="btn_wrap">
            <router-link to="/technology/dev/report-editor/new" class="main_btn">글쓰기</router-link>
          </div>
          <div class="report_function">
            <div class="total_wrap">
              <p class="total">{{$t('tech_dev.sec3_length', {length: byAll.length})/* 총 n개의 연구일지 */}}</p>
            </div>
            <div class="function_wrap">
              <div class="search_wrap">
                <input type="text" class="search_input" v-model="keyword" @keyup.enter="searchPost(keyword)" :placeholder="$t('tech_dev.sec3_placeholder')"/><!-- /* 검색어를 입력하고 엔터를 눌러주세요. */ -->
                <img src="../assets/img/search_icon.png" @click="searchPost(keyword)"/>
              </div>
              <div class="sort_wrap">
                <div class="custom-select" @click="toggleDropdown">
                  <div class="selected-option">
                    {{ $t(selectedOption.text) }} <img src="../assets/img/sort_arrow.png" :class="{rotate : isDropdownOpen}"/>
                  </div>
                  <ul v-show="isDropdownOpen">
                    <li v-for="(option, index) in options" :key="index" @click="selectOption(option)" @change="toggleDropdown">
                      {{ $t(option.text) }} <span v-if="selectedOption.text == option.text">&#10004;</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="report_wrap">
            <template v-if="response !== false">
              <div class="report_cont" v-for="(item, index) in paginatedData" :key="index">
                <router-link :to="`/technology/dev/${item.id}`">
                  <!-- <p class="num">{{ (index + 1) + (currentPage - 1) * 10 }}</p> -->
                  <p class="title">{{ item.title }}</p>
                  <p class="author">by. {{ item.author }}</p>
                  <div class="summary">
                    <p v-html="item.summary"></p>
                  </div>
                  <div class="lastinfo">
                    <p class="date">{{ item.created_at }}</p>
                    <div class="reactinfo">
                      <p class="like"><img src="../assets/img/relations_stories_icon.png"/>{{ item.likes }}</p>
                      <p class="like">댓글  {{ item.comment_count }}</p>
                      <p class="view">조회수  {{ item.views }}</p>
                    </div>
                  </div>
                </router-link>
              </div>
            </template>
            <template v-else>
              <div class="report_cont t_center empty">
                <img src="../assets/img/search_icon.png"/>
                <p class="title">{{$t('tech_dev.sec3_noResult', {keyword: keyword})/* keyword에 대한 검색결과가 없습니다. */}}</p>
                <p class="cont_description">{{$t("tech_dev.sec3_tryAgain")/* 다시 시도해보세요. */}}</p>
                <p class="cont_description btn" @click="resetKeyword">{{$t("tech_dev.sec3_reset")/* 검색어 초기화 */}}</p>
              </div>
            </template>
          </div>
          <div class="pagination">
            <button class="page-btn" @click="changePage(currentPage - 1)" :disabled="currentPage === 1"><img src="../assets/img/pagination_arrow.png"/></button>
            <template v-for="(page, index) in visiblePages" :key="index">
              <button class="page-btn" v-if="page !== '...'" @click="changePage(page)" :class="{ active: page === currentPage }">{{ page }}</button>
              <span v-else>...</span>
            </template>
            <button class="page-btn" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"><img class="rotate" src="../assets/img/pagination_arrow.png"/></button>
          </div>
        </div>
      </section>
      <div class="header_bg"></div>
    </div>
  </template>
