<script>

export default {
  name: 'reportUpdate',
  inject: ['$axios'], // $axios를 주입받음
  data () {
    return {
      title: '',
      author: '',
      summary: '',
      PDFFile: null,
      droppedFile: null,
      isDragging: false,
      selectedItem: {}
    }
  },
  mounted () {
    this.fetchDetail()
  },
  methods: {
    handleFileChange (event) {
      const files = event.target.files
      this.handleFiles(files)
    },
    handleDragOver (event) {
      event.preventDefault()
      this.isDragging = true
    },
    handleDragLeave () {
      this.isDragging = false
    },
    handleDrop (event) {
      event.preventDefault()
      this.isDragging = false
      const files = event.dataTransfer.files
      this.handleFiles(files)
    },
    handleFiles (files) {
      const maxSize = 500 * 1000 * 1000 // 500MB (byte 단위)
      if (files && files.length > 0) {
        const file = files[0]
        if (file.size > maxSize) {
          // 파일 크기가 제한을 초과하는 경우 처리 로직 추가
          alert(this.$t("stories.alert7")) // 파일 크기가 500MB 제한을 초과했습니다.
          return
        }
        // 파일 크기가 제한 내에 있는 경우 추가 처리 로직
        // console.log('파일 크기가 제한 내에 있습니다.')
        // 파일 처리 로직
        this.droppedFile = file
        this.PDFFile = file
        // console.log(files)
      }
    },
    openFileInput () {
      this.$refs.fileInput.click()
    },
    formatFileSize (size) {
      if (size === 0) return '0 Bytes'
      const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      const i = Math.floor(Math.log2(size) / 10)
      return `${(size / Math.pow(1024, i)).toFixed(2)} ${units[i]}`
    },
    resetFile () {
      this.droppedFile = null
    },
    saveContent () {
      const data = {
        title: this.title,
        summary: this.summary,
        author: this.author,
        PDFFile: this.PDFFile
      }
      console.log(data)
    },
    async fetchDetail () {
      const itemId = this.$route.params.id
      try {
        const response = await this.$axios.get(`https://lexcode.co.kr/app/get_report_detail/${itemId}`)
        // const response = await this.$axios.get(`https://lexcode.co.kr/dev/get_report_detail/${itemId}`)
        this.selectedItem = { ...response.data }
        this.editContent()
      } catch (error) {
        console.error(error)
      }
    },
    editContent () {
      this.title = this.selectedItem.title
      this.summary = this.selectedItem.summary
      this.author = this.selectedItem.author
      this.droppedFile = {
        name: this.selectedItem.pdf_name,
        url: this.selectedItem.pdf_file,
        size: this.selectedItem.file_size
      }
    },
    updateContent () {
      if (confirm(this.$t("stories.alert5"))) { // '수정하시겠습니까?'
        const contentId = this.selectedItem.id
        const formData = new FormData()
        formData.append('title', this.title)
        formData.append('summary', this.summary)
        formData.append('author', this.author)
        formData.append('contentId', contentId)
        if (this.PDFFile == null) { // 파일 수정 안했을 경우 str
          formData.append('pdf_file', this.selectedItem.pdf_file)
          formData.append('pdf_name', this.selectedItem.pdf_name)
        } else { // 파일 수정했을 경우 file
          formData.append('pdf_file', this.PDFFile)
        }
        console.log(formData)
        this.$axios
          .post('https://lexcode.co.kr/app/update_report', formData, {
          // .post('https://lexcode.co.kr/dev/update_report', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            // console.log(response)
            this.$router.push('/technology/dev/' + this.selectedItem.id)
            alert(this.$t("stories.alert6_1")) // '수정되었습니다.'
          })
          .catch(error => {
            console.error('failed:', error)
          })
      } else {
        // 처리하지 않을 경우에 대한 코드 작성
      }
    }
  }
}
</script>
<template>
  <div class="relations newsletter-edit">
    <section class="section1">
      <div class="container">
        <p class="cont_title">{{$t("stories.edit_title2")/* 연구일지 수정 */}}</p>
        <div class="wrap">
          <fieldset class="title">
            <label>{{$t("stories.title1")/* 제목 */}}</label>
            <input type="text" v-model="title" />
          </fieldset>
          <fieldset class="title">
            <label>{{$t("stories.title4")/* 작성자 */}}</label>
            <input type="text" v-model="author" />
          </fieldset>
          <fieldset class="title">
            <label>{{$t("stories.title8")/* 요약 */}}</label>
            <textarea v-model="summary" rows="5"></textarea>
          </fieldset>
          <fieldset class="title">
            <label>{{$t("stories.title9")/* PDF 파일 */}}</label>
            <div class="drop-area" :class="{ 'drag-over': isDragging, 'isFile': droppedFile != null}" @dragover.prevent="handleDragOver" @dragleave="handleDragLeave" @drop="handleDrop">
              <template v-if="droppedFile">
                <div class="file_info">
                <a :href="droppedFile.url" target="_blank">{{ droppedFile.name }} ({{ formatFileSize(droppedFile.size) }})</a>
              </div>
              <p v-if="droppedFile" @click="resetFile" class="reset-button">X</p>
              </template>
              <template v-else>
                <span>{{$t("stories.title7")/* 이곳에 파일을 드롭하세요 */}}</span>
                <p @click="openFileInput" class="plus">+</p>
              </template>
            </div>
            <input type="file" ref="fileInput" style="display: none" @change="handleFileChange" accept="image/*" />
          </fieldset>
        </div>
        <button class="main_btn" @click="updateContent()">{{$t("stories.btn_save")/* 저장 */}}</button>
      </div>
    </section>
  </div>
</template>
