<script>
/* eslint-disable */
export default {
    name: 'commonFooter',
    props: {
        window: Object
    }
}
</script>


<template>
    <div class="footer">
        <div class="container">
            <div class="footer_left">
                <img v-if="window.width > 768" class="footer_logo" src="../assets/img/footer_logo.png" />
                <div class="sns">
                    <a class="sns_icon" href="https://www.facebook.com/lexcodekr/" target="_blank">
                        <img src="../assets/img/footer_sns1.png" />
                    </a>
                    <a class="sns_icon" href="https://www.instagram.com/lexcode_korea/" target="_blank">
                        <img src="../assets/img/footer_sns2.png" />
                    </a>
                    <a class="sns_icon" href="https://www.youtube.com/channel/UCBafCU8zlgmQxNwFuwCwFyg" target="_blank">
                        <img src="../assets/img/footer_sns3.png" />
                    </a>
                    <a class="sns_icon" href="https://blog.naver.com/lexcode_kr" target="_blank">
                        <img src="../assets/img/footer_sns4.png" />
                    </a>
                </div>
                <!-- <div class="family_site t_white">
                    <p>Family Site</p>
                    <p>+</p>
                </div> -->
            </div>
            <div class="footer_right">
                <div class="footer_gnb">
                    <div class="footer_gnb_cont business">
                        <h4>BUSINESS</h4>
                        <a href="/business/lex-cloud">LEX-Cloud
                            <span>{{ $t("header.business_1")/* 번역 · ATPE" */ }}</span>
                        </a>
                        <a href="/business/tc">TransCreation
                            <span>{{ $t("header.business_2")/* 생성형 AI & NLP 모델" */ }}</span>
                        </a>
                        <a href="/business/cat-x">CAT-X
                            <span>{{ $t("header.business_3")/* 커스텀 자동번역 모델구축" */ }}</span>
                        </a>
                        <a href="/business/journallab">Journal Lab
                            <span>{{ $t("header.business_4")/* GPT 기반 논문 분석 · 에디팅" */ }}</span>
                        </a>
                        <a href="/business/bavl">BAVL
                            <span>{{ $t("header.business_5")/* 자연어 데이터 수집 · 정제" */ }}</span>
                        </a>
                    </div>
                    <div class="footer_gnb_cont">
                        <h4>TECHNOLOGY</h4>
                        <a href="/technology/atpe">{{ $t("header.tech_1")
                            }}</a><!-- {{/* 자연어 번역 · 감수​ ATPE" */}} -->
                        <a href="/technology/deepread">{{ $t("header.tech_2")/* 생성형 AI · NLP" */ }}</a>
                        <a href="/technology/dev">{{ $t("header.tech_3")/* 연구일지" */ }}</a>
                    </div>
                    <div class="footer_gnb_cont">
                        <h4>RELATIONS</h4>
                        <a href="/relations/stories">{{ $t("header.relation_1")/* Stories" */ }}</a>
                        <a href="/relations/partners">{{ $t("header.relation_2")/* 파트너 실적" */ }}</a>
                        <a href="/relations/translator">{{ $t("header.relation_3")/* 번역사 지원" */ }}</a>
                        <a href="/relations/recruit">{{ $t("header.relation_4")/* 채용 */ }}</a>
                    </div>
                    <div class="footer_gnb_cont">
                        <h4>COMPANY</h4>
                        <a href="/company">ABOUT LEXCODE</a>
                        <a href="/company/#visionMission">VISION & MILESTONE</a>
                        <a href="/company/#contactus">CONTACT US</a>
                        <a href="/companyPH">LEXCODE Philippines</a>
                    </div>
                </div>
                <div class="copyright">
                    <p>{{ $t("footer.CEO")/* 대표자: 함철용" */ }} | {{ $t("footer.BRN")/* 사업자등록번호 : 214-88-17490" */ }} |
                        {{ $t("footer.OBRN")/* 통신판매업신고번호 : 2007-서울서초-10795" */ }}</p>
                    <p>{{ $t("footer.COPY")/* Copyright 2019 Lexcode Inc. All Rights Reserved */ }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
