<script>
export default {
  name: 'relationsTranslator',
  props: {
    window: Object
  },
  data() {
    return {
      visible1: false,
      visible2: false,
      visible3: false
    }
  }
}
</script>
<template>
  <div class="relations translator">
    <section class="section1">
      <div class="container">
        <h3 class="translator_title">{{$t("tr_supp.sec1_subTitle")/* 번역사 지원 */}}</h3>
        <h1 class="cont_title">
          {{$t("tr_supp.sec1_contTitle")/* 여러분의 언어로 */}}<br>{{$t("tr_supp.sec1_contTitle2")/* 다양한 기회를 만들어 가세요. */}}
        </h1>
      </div>
      <img src="../assets/img/relations_translator_section1_bg.png" />
    </section>
    <section class="section2 t_center">
      <div class="container">
        <h1 class="cont_title">{{$t("tr_supp.sec2_contTitle")/* 수많은 기회가 이미 기다리고 있습니다. */}}</h1>
        <div class="flex">
          <div class="sec2_cont">
            <p class="num">1,600+</p>
            <p class="cont_description">{{$t("tr_supp.sec2_desc1")/* 렉스클라우드 가입 기업 */}}</p>
          </div>
          <div class="sec2_cont">
            <p class="num">500+</p>
            <p class="cont_description">{{$t("tr_supp.sec2_desc2")/* 일평균 프로젝트 진행 수 */}}</p>
          </div>
          <div class="sec2_cont">
            <p class="num">1,000+</p>
            <p class="cont_description">{{$t("tr_supp.sec2_desc3")/* 등록된 번역사 */}}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section3">
      <div class="container">
        <h1 class="cont_title t_white t_center">{{$t("tr_supp.sec3_contTitle")/* 렉스코드와 함께한다면 */}}</h1>
        <div class="flex">
          <template v-if="window.width > 768">
            <div class="sec3_cont" @mouseover="visible1 = true" @mouseout="visible1 = false"
              :class="{ active: visible1 }">
              <img src="../assets/img/relations_translator_section3_img1.png" />
              <p class="sec3_cont_tiny">{{$t("tr_supp.sec3_trIs")/* 번역사는 */}}</p>
              <p class="cont_subtitle t_white">{{$t("tr_supp.sec3_subTitle1")/* 편합니다. */}}</p>
              <p class="sec3_cont_tiny mgt">{{$t("tr_supp.sec3_trIs2")/* 어떻게요? */}}</p>
              <p class="cont_description t_white">
                {{$t("tr_supp.sec3_desc1")/* 렉스클라우드 플랫폼을 활용하여 업무를 쉽고 간편하게 진행하세요. 전화나 이메일 없이 플랫폼에서 프로젝트를 배정받고 납품까지 한 번에 할 수 있으며, 별도의 정리 없이 프로젝트 히스토리를 파악할 수 있습니다. 개인 일정을 실시간으로 공유하거나 포스팅 지원 기능을 통해, 작업이 몰리거나 중단되는 일을 방지하세요. */}}
              </p>
            </div>
            <div class="sec3_cont" @mouseover="visible2 = true" @mouseout="visible2 = false"
              :class="{ active: visible2 }">
              <img src="../assets/img/relations_translator_section3_img2.png" />
              <p class="sec3_cont_tiny">{{$t("tr_supp.sec3_trIs")/* 번역사는 */}}</p>
              <p class="cont_subtitle t_white">{{$t("tr_supp.sec3_subTitle2")/* 집중합니다. */}}</p>
              <p class="sec3_cont_tiny mgt">{{$t("tr_supp.sec3_trIs2")/* 어떻게요? */}}</p>
              <p class="cont_description t_white">
                {{$t("tr_supp.sec3_desc2")/* 안심하고 작업에만 집중하세요. 프로젝트 내역과 작업료는 알아서 정리됩니다. 내부 ERP 시스템을 통해 누락 없이 정확하게 작업료를 책정하고 지급합니다. */}}
              </p>
            </div>
            <div class="sec3_cont" @mouseover="visible3 = true" @mouseout="visible3 = false"
              :class="{ active: visible3 }">
              <img src="../assets/img/relations_translator_section3_img3.png" />
              <p class="sec3_cont_tiny">{{$t("tr_supp.sec3_trIs")/* 번역사는 */}}</p>
              <p class="cont_subtitle t_white">{{$t("tr_supp.sec3_subTitle3")/* 성장합니다. */}}</p>
              <p class="sec3_cont_tiny mgt">{{$t("tr_supp.sec3_trIs2")/* 어떻게요? */}}</p>
              <p class="cont_description t_white">
                {{$t("tr_supp.sec3_desc3")/* 전문 에디터의 평가 리포트를 통해 내 작업물에 대한 피드백을 받을 수 있습니다. 정기적인 CAT Tool 교육과 번역사의 밤과 같은 오프라인 모임을 통해 다양한 정보를 제공 받을 수 있습니다. 렉스코드 번역사는 혼자서 일하지 않습니다. 함께 성장하세요. */}}
              </p>
            </div>
          </template>
          <template v-else>
            <div class="sec3_cont" @mouseover="visible1 = true" @mouseout="visible1 = false"
              :class="{ active: visible1 }">
              <p class="sec3_cont_tiny">{{$t("tr_supp.sec3_trIs")/* 번역사는 */}}</p>
              <img src="../assets/img/relations_translator_section3_img1.png" />
              <p class="cont_subtitle t_white">{{$t("tr_supp.sec3_subTitle1")/* 편합니다. */}}</p>
              <div class="bind">
                <p class="sec3_cont_tiny mgt">{{$t("tr_supp.sec3_trIs2")/* 어떻게요? */}}</p>
                <p class="cont_description t_white">
                  {{$t("tr_supp.sec3_desc1_1")/* 렉스클라우드 플랫폼을 활용하여 업무를 쉽고 간편하게 진행하세요. 전화나 이메일 없이 플랫폼에서 프로젝트를 배정받고 납품까지 한 번에 할 수 있으며, 별도의 정리 없이 프로젝트 히스토리를 파악할 수 있습니다. 개인 일정을 실시간으로 공유하거나 포스팅 지원 기능을 통해, 작업이 몰리거나 중단되는 일을 방지하세요. */}}
                </p>
              </div>
            </div>
            <div class="sec3_cont" @mouseover="visible2 = true" @mouseout="visible2 = false"
              :class="{ active: visible2 }">
              <p class="sec3_cont_tiny">{{$t("tr_supp.sec3_trIs")/* 번역사는 */}}</p>
              <img src="../assets/img/relations_translator_section3_img2.png" />
              <p class="cont_subtitle t_white">{{$t("tr_supp.sec3_subTitle2")/* 집중합니다. */}}</p>
              <div class="bind">
                <p class="sec3_cont_tiny mgt">{{$t("tr_supp.sec3_trIs2")/* 어떻게요? */}}</p>
                <p class="cont_description t_white">
                  {{$t("tr_supp.sec3_desc2_1")/* 안심하고 작업에만 집중하세요. 프로젝트 내역과 작업료는 알아서 정리됩니다. 내부 ERP 시스템을 통해 누락 없이 정확하게 작업료를 책정하고 지급합니다. */}}
                </p>
              </div>
            </div>
            <div class="sec3_cont" @mouseover="visible3 = true" @mouseout="visible3 = false"
              :class="{ active: visible3 }">
              <p class="sec3_cont_tiny">{{$t("tr_supp.sec3_trIs")/* 번역사는 */}}</p>
              <img src="../assets/img/relations_translator_section3_img3.png" />
              <p class="cont_subtitle t_white">{{$t("tr_supp.sec3_subTitle3")/* 성장합니다. */}}</p>
              <div class="bind">
                <p class="sec3_cont_tiny mgt">{{$t("tr_supp.sec3_trIs2")/* 어떻게요? */}}</p>
                <p class="cont_description t_white">
                  {{$t("tr_supp.sec3_desc3_1")/* 전문 에디터의 평가 리포트를 통해 내 작업물에 대한 피드백을 받을 수 있습니다. 정기적인 CAT Tool 교육과 번역사의 밤과 같은 오프라인 모임을 통해 다양한 정보를 제공 받을 수 있습니다. 렉스코드 번역사는 혼자서 일하지 않습니다. 함께 성장하세요. */}}
                </p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
    <section class="section4">
      <div class="container">
        <h1 class="cont_title">{{$t("tr_supp.sec4_contTitle")/* 렉스코드의 문은 활짝 열려있습니다. */}}</h1>
        <p class="cont_description" v-html="$t('tr_supp.sec4_desc')"></p><!-- br있어서 v-html처리 -->
        <div class="flex sec4_cont_wrap">
          <div class="sec4_cont">
            <p class="cont_subtitle">{{$t("tr_supp.sec4_subTitle")/* 번역사 지원 요건 */}}</p>
            <p class="cont_description" v-html="$t('tr_supp.sec4_desc1')"></p>
            <p class="cont_description" v-html="$t('tr_supp.sec4_desc2')"></p>
            <p class="cont_description" v-html="$t('tr_supp.sec4_desc3')"></p>
            <p class="cont_description" v-html="$t('tr_supp.sec4_desc4')"></p>
            <p class="cont_description mgt" v-html="$t('tr_supp.sec4_desc5')"></p>
          </div>
          <div class="sec4_cont">
            <p class="cont_subtitle">{{$t("tr_supp.sec4_subTitle2")/* 번역사 선발 절차 */}}</p>
            <div class="flex_col">
              <div class="text_wrap">
                <div class="flex">
                  <p class="num">1</p>
                  <p class="cont_description">{{$t("tr_supp.sec4_desc14")/* 서류검토 */}}</p>
                </div>
              </div>
              <div class="text_wrap">
                <div class="flex">
                  <p class="num">2</p>
                  <p class="cont_description">{{$t("tr_supp.sec4_desc15")/* 샘플 테스트 (1차 서류검토 통과자에 한하여 진행) */}}</p>
                </div>
              </div>
              <div class="text_wrap">
                <div class="flex">
                  <p class="num">3</p>
                  <p class="cont_description">{{$t("tr_supp.sec4_desc16")/* 대면 인터뷰(해외 체류 시 화상 인터뷰 가능) */}}</p>
                </div>
              </div>
              <div class="text_wrap">
                <div class="flex">
                  <p class="num">4</p>
                  <p class="cont_description">{{$t("tr_supp.sec4_desc17")/* 학력 증명서 및 각종 증빙 서류 제출 */}}</p>
                </div>
              </div>
              <div class="text_wrap">
                <div class="flex">
                  <p class="num">5</p>
                  <p class="cont_description">{{$t("tr_supp.sec4_desc18")/* 계약서 작성 및 최종 선발 */}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section5 t_white">
      <h1 class="cont_title">{{$t("tr_supp.sec5_contTitle")/* 준비되셨다면 망설이지 마세요. */}}</h1>
      <a class="main_btn" href="https://lexcloud.ai/log/translator.add.nx" target="_blank">{{$t("tr_supp.sec5_btn")/* 지금 바로 지원하기 */}} →</a>
    </section>
    <div class="header_bg"></div>
  </div>
</template>
