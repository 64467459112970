<script>
import boardComments from '../components/boardComments.vue'

export default {
  name: 'reportDetail',
  inject: ['$axios'], // $axios를 주입받음
  components: {
    boardComments
  },
  props: {
    window: Object
  },
  data () {
    return {
      likes_active: false,
      selectedItem: {}, // 선택한 항목의 상세 정보 데이터,
      login_yn: false,
      pdfUrl: null
    }
  },
  mounted () {
    const login = localStorage.getItem('login')
    if (login) {
      this.login_yn = true
    }
    // 선택한 항목의 상세 정보를 가져오는 로직
    this.fetchDetail()
  },
  methods: {
    async fetchDetail () {
      const itemId = this.$route.params.id
      const likedItems = JSON.parse(localStorage.getItem('likedItems')) || []
      // console.log(likedItems, itemId)
      if (likedItems.includes(Number(itemId))) {
        this.$data.likes_active = true
      }
      try {
        this.$axios.get(`https://lexcode.co.kr/app/get_report_detail/${itemId}`)
        // this.$axios.get(`https://lexcode.co.kr/dev/get_report_detail/${itemId}`)
          .then(response => {
            this.selectedItem = {
              ...response.data,
              created_at: this.formatDate(response.data.created_at)
            }
            this.$data.pdfUrl = this.selectedItem.pdf_file
          })
      } catch (error) {
        console.error(error)
      }
    },
    formatDate (dateString) {
      const date = new Date(dateString)
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      return formattedDate
    },
    async likeNewsletter () {
      const likedItems = JSON.parse(localStorage.getItem('likedItems')) || []
      if (likedItems.includes(this.selectedItem.id)) {
        alert(this.$t("stories.alert4")) // 이미 좋아요를 누르셨습니다!
        return
      }
      try {
        const response = await this.$axios.post('https://lexcode.co.kr/app/increase_like_report', null, {
        // const response = await this.$axios.post('https://lexcode.co.kr/dev/increase_like_report', null, {
          params: {
            contents_id: this.selectedItem.id
          },
          headers: {
            Accept: 'application/json'
          }
        })
        const likes = response.data.likes[0][0]
        // console.log(response.data)
        this.selectedItem.likes = likes
        this.$data.likes_active = true
        likedItems.push(this.selectedItem.id)
        localStorage.setItem('likedItems', JSON.stringify(likedItems))
      } catch (error) {
        console.error(error)
      }
    },
    async deletePost () {
      if (confirm($t("stories.alert1"))) { // 삭제하시겠습니까?
        try {
          await this.$axios.post('https://lexcode.co.kr/app/delete_report', null, {
          // await this.$axios.post('https://lexcode.co.kr/dev/delete_report', null, {
            params: {
              report_id: this.selectedItem.id
            },
            headers: {
              Accept: 'application/json'
            }
          })
          // console.log(response)
          this.$router.push({
            name: 'technologyDev'
          })
          alert(this.$t("stories.alert2")) // 삭제되었습니다.
        } catch (error) {
          console.error('failed:', error)
        }
      } else {
        // 취소 버튼을 눌렀을 때 수행할 작업 추가
      }
    }
  }
}
</script>

<template>
  <div class="newsletter report">
    <section class="top">
      <div class="btn_wrap" v-if="login_yn">
        <router-link :to="`/technology/dev/report-editor/${selectedItem.id}`">
          <img src="../assets/img/modify_icon.png"/>
        </router-link>
        <p @click="deletePost()">
          <img src="../assets/img/delete_icon.png"/>
        </p>
      </div>
      <div class="container">
        <h1>{{ selectedItem.title }}</h1>
        <p class="author">by. {{ selectedItem.author }}</p>
        <div class="sub-info">
          <p>{{$t("stories.view")/* 조회수 */}} {{ selectedItem.views }}</p>
          <p>{{ selectedItem.created_at }}</p>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container">
        <template v-if="window.width > 768">
          <embed :src="'https://directory.lexcode.co.kr/' + pdfUrl" type="application/pdf"/>
        </template>
        <template v-else>
          <iframe :src="'https://docs.google.com/gview?url=https://directory.lexcode.co.kr/' + pdfUrl + '&embedded=true'" type="application/pdf"/>
        </template>
      </div>
    </section>
    <section class="likes">
      <div class="container">
        <p class="likes_label">{{$t("stories.likes_label2")/* 이 뉴스레터가 재밌으셨다면 Thumbs Up! */}}</p>
        <div class="main_btn" :class="{active: likes_active}" @click="likeNewsletter()">
          <template v-if="likes_active == true">
            <img src="../assets/img/relations_stories_icon_active.png"/>
          </template>
          <template v-else>
            <img src="../assets/img/relations_stories_icon.png"/>
          </template>
          <p>{{ selectedItem.likes }}</p>
        </div>
      </div>
    </section>
    <section class="comment">
      <div class="container">
        <boardComments :content_id="this.selectedItem.id" :board_id="2"></boardComments>
      </div>
    </section>
    <section class="goBack">
      <div class="container">
        <a class="goBack_btn" href="/technology/dev#reportList">{{$t("stories.btn_to_list")/* 목록으로 */}}</a>
      </div>
    </section>
    <div class="header_bg"></div>
  </div>
</template>
