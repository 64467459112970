<script>
export default {
  name: 'technologyATPE',
  props: {
    window: Object
  }
}
</script>
<template>
  <div class="technology atpe">
    <section class="section1">
      <div class="sec1_bg"></div>
      <div class="container">
        <div class="text_wrap t_white">
          <p class="sub_title">{{$t("tech_atpe.sec1_subTitle")/* 자연어 번역 · 감수 ATPE */}}</p>
          <h1 class="cont_title">{{$t("tech_atpe.sec1_contTitle")/* 인공지능과 사람의 장점을 담았습니다. */}}</h1>
        </div>
      </div>
    </section>
    <section class="section2">
      <div class="container">
        <div class="text_wrap t_center">
          <p class="cont_title">{{$t("tech_atpe.sec2_contTitle")/* 인공지능 휴먼번역(ATPE) */}}</p>
          <p class="underline_text">Ai Translation Post Editing</p>
          <p class="cont_subtitle">{{$t("tech_atpe.sec2_subTitle")/* 최신 인공지능의 번역과 전문가의 교정을 결합했습니다. */}}</p>
          <p class="cont_description">Maximize efficiency and quality with MTPE - the fusion of cutting-edge technology and expert touch.</p>
        </div>
      </div>
      <div class="bg">
        <div class="container">
          <div class="flex">
            <template v-if="window.width > 768">
              <template v-if="$i18n.locale == 'ko'"><img src="../assets/img/business_lexcloud_section6_img1.png"/></template>
              <template v-else-if="$i18n.locale == 'en'"><img src="../assets/img/business_lexcloud_section6_en_img1.png"/></template>
            </template>
            <template v-else>
              <template v-if="$i18n.locale == 'ko'"><img src="../assets/img/technology_atpe_section6_mob_img1.png"/></template>
              <template v-else-if="$i18n.locale == 'en'"><img src="../assets/img/technology_atpe_section6_en_mob_img1.png"/></template>
            </template>
            <img src="../assets/img/business_lexcloud_section6_img2.png" class="img2"/>
            <template v-if="$i18n.locale == 'ko'"><img src="../assets/img/business_lexcloud_section6_img3.png"/></template>
            <template v-else-if="$i18n.locale == 'en'"><img src="../assets/img/business_lexcloud_section6_en_img3.png"/></template>
          </div>
        </div>
      </div>
    </section>
    <section class="section3">
      <div class="container">
        <div class="flex">
          <img src="../assets/img/business_lexcloud_section7_img1.png"/>
          <div class="text_wrap">
            <p class="cont_title">{{$t("tech_atpe.sec3_contTitle")/* ATPE, MTPE와 다른가요? */}}</p>
            <p class="cont_description">
              {{$t("tech_atpe.sec3_desc")/* MTPE는 기계번역 후 편집(Machine Translation and Post Editing)하는 번역방식입니다. */}}
              <br><br>
              {{$t("tech_atpe.sec3_desc2")/* 이에 비해 ATPE는 기계번역을 더욱 자동화한 자동...  */}}
              <b>
                {{$t("tech_atpe.sec3_desc3")/* 일반적인 기계번역보다 높은 수준의 품질과 효율성을 추구 */}}
              </b>
              {{$t("tech_atpe.sec3_desc4")/* 합니다. */}}
              <br><br>
              {{$t("tech_atpe.sec3_desc5")/* 기반으로 알려진 번역 프로세스에서 한 단계 더 나아간  */}}
              <b>
                {{$t("tech_atpe.sec3_desc6")/* 렉스코드만의 '인공지능 번역-전문가 후편집' 프로세스를 의미 */}}
              </b>
              {{$t("tech_atpe.sec3_desc7")/* 합니다. */}}
              {{$t("tech_atpe.sec3_desc8")/* 인공지능으로 번역하고 (AI Translation), 렉스코드 내부 전문가가 감수하는(Post-Editing) 번역 체계를 말합니다. */}}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section4">
      <div class="container">
        <div class="flex">
          <p class="cont_title text_wrap">{{$t("tech_atpe.sec4_contTitle")/* 렉스코드 ATPE, 인공지능과 사람의 장점을 여러분 문서에 담아 드립니다. */}}</p>
          <div class="flex sec8_cont">
            <div class="text_wrap">
              <img src="../assets/img/business_lexcloud_section8_img1.png"/>
              <p class="sec8_title">{{$t("tech_atpe.sec4_title1")/* 빠르고 효율적인 번역 */}}</p>
              <p class="cont_description">{{$t("tech_atpe.sec4_desc1")/* 인공지능 기술 활용으로 대량의 문서를 빠르고 효율적으로 번역합니다. */}}</p>
            </div>
            <div class="text_wrap">
              <img src="../assets/img/business_lexcloud_section8_img2.png"/>
              <p class="sec8_title">{{$t("tech_atpe.sec4_title2")/* 생산성 향상 */}}</p>
              <p class="cont_description">{{$t("tech_atpe.sec4_desc2")/* 플랫폼 및 자연어 처리 기술과 접목해 생산성을 향상시킵니다. */}}</p>
            </div>
            <div class="text_wrap">
              <img src="../assets/img/business_lexcloud_section8_img3.png"/>
              <p class="sec8_title">{{$t("tech_atpe.sec4_title3")/* 번역 비용 절감 */}}</p>
              <p class="cont_description">{{$t("tech_atpe.sec4_desc3")/* 내부ˑ외부로 지불하는 번역 비용을 줄여 효율을 향상시킵니다. */}}</p>
            </div>
            <div class="text_wrap">
              <img src="../assets/img/business_lexcloud_section8_img4.png"/>
              <p class="sec8_title">{{$t("tech_atpe.sec4_title4")/* 품질관리 향상 */}}</p>
              <p class="cont_description">{{$t("tech_atpe.sec4_desc4")/* 기술과 사람의 협업으로 품질관리를 향상시킬 수 있습니다. */}}</p>
            </div>
            <div class="text_wrap">
              <img src="../assets/img/business_lexcloud_section8_img5.png"/>
              <p class="sec8_title">{{$t("tech_atpe.sec4_title5")/* 정확하고 통일된 번역 */}}</p>
              <p class="cont_description">{{$t("tech_atpe.sec4_desc5")/* 탑티어 전문가의 리뷰로 더욱 정확하고 통일된 번역을 제공합니다. */}}</p>
            </div>
            <div class="text_wrap">
              <img src="../assets/img/business_lexcloud_section8_img6.png"/>
              <p class="sec8_title">{{$t("tech_atpe.sec4_title6")/* 정보공유 촉진 */}}</p>
              <p class="cont_description">{{$t("tech_atpe.sec4_desc6")/* 로컬리제이션 장벽을 낮추어 다국어로 정보공유를 촉진합니다. */}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section5">
      <div class="container">
        <div class="text_wrap t_center">
          <p class="cont_title">{{$t("tech_atpe.sec5_contTitle" /* 품질평가는 이렇게 하는 것 */) }}</p>
          <p class="cont_description">{{$t("tech_atpe.sec5_desc" /* 의미가 정확하고, 잘 읽히며, 일관된 품질의 번역문이 고객에게 전달되도록. */) }}</p>
        </div>
        <div class="sec5_cont_wrap">
          <template v-if="window.width > 768">
            <template v-if="$i18n.locale == 'ko'"><img src="../assets/img/technology_atpe_section5_img1.png"/></template>
            <template v-else-if="$i18n.locale == 'en'"><img src="../assets/img/technology_atpe_section5_en_img1.png"/></template>
            <template v-if="$i18n.locale == 'ko'"><img src="../assets/img/technology_atpe_section5_img2.png"/></template>
            <template v-else-if="$i18n.locale == 'en'"><img src="../assets/img/technology_atpe_section5_en_img2.png"/></template>
          </template>
          <template v-else>
            <template v-if="$i18n.locale == 'ko'"><img src="../assets/img/technology_atpe_section5_mob_img1.png"/></template>
            <template v-else-if="$i18n.locale == 'en'"><img src="../assets/img/technology_atpe_section5_en_mob_img1.png"/></template>
            <template v-if="$i18n.locale == 'ko'"><img src="../assets/img/technology_atpe_section5_mob_img2.png"/></template>
            <template v-else-if="$i18n.locale == 'en'"><img src="../assets/img/technology_atpe_section5_en_mob_img2.png"/></template>
            <template v-if="$i18n.locale == 'ko'"><img src="../assets/img/technology_atpe_section5_mob_img3.png"/></template>
            <template v-else-if="$i18n.locale == 'en'"><img src="../assets/img/technology_atpe_section5_en_mob_img3.png"/></template>
          </template>
        </div>
      </div>
    </section>
    <section class="section6">
      <div class="container">
        <div class="text_wrap">
          <img src="../assets/img/technology_atpe_section6_img1.png"/>
          <p class="cont_title">{{$t("tech_atpe.sec6_contTitle")/* 하나의 점을 위해 수백 번의 테스트를 진행합니다. */}}</p>
          <p class="cont_description">{{$t("tech_atpe.sec6_desc")/* 렉스코드 ATPE팀은 명확한 품질 평가 기준을 바탕... */}}</p>
        </div>
        <div class="legend">
          <p class="legend_title">{{$t("tech_atpe.sec6_legend")/* 언어 및 분야별 인공지능 번역 엔진 비교 평가서 */}}</p>
          <legend class="eg1">{{$t("tech_atpe.sec6_legend1")/* 번역엔진 1 */}}</legend>
          <legend class="eg2">{{$t("tech_atpe.sec6_legend2")/* 번역엔진 2 */}}</legend>
          <legend class="eg3">{{$t("tech_atpe.sec6_legend3")/* 번역엔진 3 */}}</legend>
        </div>
        <div class="chart">
          <template v-if="$i18n.locale == 'ko'">
            <div class="chart_cont">
              <p class="chart_title">{{$t("tech_atpe.sec6_chart1")/* 기계기술 분야 */}}</p>
              <video autoplay muted loop>
                <source src="../assets/img/technology_atpe_section6_img1_ko.mp4" type="video/mp4" />
              </video>
            </div>
            <div class="chart_cont">
              <p class="chart_title">{{$t("tech_atpe.sec6_chart2")/* 정치 분야 */}}</p>
              <video autoplay muted loop>
                <source src="../assets/img/technology_atpe_section6_img2_ko.mp4" type="video/mp4" />
              </video>
            </div>
            <div class="chart_cont">
              <p class="chart_title">{{$t("tech_atpe.sec6_chart3")/* 사회 분야 */}}</p>
              <video autoplay muted loop>
                <source src="../assets/img/technology_atpe_section6_img3_ko.mp4" type="video/mp4" />
              </video>
            </div>
          </template>
          <template v-else>
            <div class="chart_cont">
              <p class="chart_title">{{$t("tech_atpe.sec6_chart1")/* 기계기술 분야 */}}</p>
              <video autoplay muted loop>
                <source src="../assets/img/technology_atpe_section6_img1_en.mp4" type="video/mp4" />
              </video>
            </div>
            <div class="chart_cont">
              <p class="chart_title">{{$t("tech_atpe.sec6_chart2")/* 정치 분야 */}}</p>
              <video autoplay muted loop>
                <source src="../assets/img/technology_atpe_section6_img2_en.mp4" type="video/mp4" />
              </video>
            </div>
            <div class="chart_cont">
              <p class="chart_title">{{$t("tech_atpe.sec6_chart3")/* 사회 분야 */}}</p>
              <video autoplay muted loop>
                <source src="../assets/img/technology_atpe_section6_img3_en.mp4" type="video/mp4" />
              </video>
            </div>
          </template>
        </div>
      </div>
    </section>
    <section class="section7">
      <div class="container">
        <div class="text_wrap t_center t_white">
          <p class="cont_title">{{$t("tech_atpe.sec7_contTitle")/* 정교하고 자연스러운 인공지능 번역과 전문 에디터의 포스팅 에디팅을 통 */}}<br>{{$t("tech_atpe.sec7_contTitle2")/* 더 효율적인 번역을 경험하세요. */}}</p>
        </div>
      </div>
    </section>
    <div class="header_bg"></div>
  </div>
</template>
