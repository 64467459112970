<script>
/* eslint-disable */
import { Editor } from '@toast-ui/editor'
import '@toast-ui/editor/dist/toastui-editor.css'
import 'tui-color-picker/dist/tui-color-picker.css'
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css'
import colorSyntax from '@toast-ui/editor-plugin-color-syntax'
import '@toast-ui/editor/dist/i18n/ko-kr'

// TODO: 
// tui-editor가 기존 vue2까지는 지원을 했엇으나 vue3부터는 vue3와 함께 Tui.Editor를 사용하는 경우 직접 래퍼 해야합니다.
// https://yy-z-a.tistory.com/14

export default {
  name: 'NewsletterEditor',
  inject: ['$axios'], // $axios를 주입받음
  data() {
    return {
      editor: null,
      title: '',
      company: false,
      author: '',
      metadata: {},
      currentLocale: this.$i18n.locale,
      thumbnailFile: null,
      droppedFile: null,
      isDragging: false
    }
  },
  mounted() {
    this.editor = new Editor({
      el: document.querySelector('#editor'),
      previewStyle: 'vertical',
      height: '500px',
      initialEditType: 'wysiwyg',
      plugins: [colorSyntax],
      language: 'ko-KR',
      hideModeSwitch: false,
      hooks: {
        addImageBlobHook: (blob, callback) => {
          const maxSize = 500 * 1000 * 1000 // 500MB (byte 단위)
          if (blob.size <= maxSize) {
            this.uploadImage(blob, callback)
          } else {
            alert('파일 크기가 500MB 제한을 초과했습니다.')
          }
        }
      },
      linkAttributes: {
        target: '_blank'
      },
      customHTMLRenderer: {
        htmlBlock: {
          iframe(node) {
            return [
              { type: 'openTag', tagName: 'iframe', outerNewLine: true, attributes: node.attrs },
              { type: 'html', content: node.childrenHTML },
              { type: 'closeTag', tagName: 'iframe', outerNewLine: true }
            ]
          }
        }
      }
    })
  },
  methods: {
    uploadImage(blob, callback) {
      // FormData 생성
      const formData = new FormData()
      formData.append('image', blob)
      // console.log(formData)
      // 서버로 이미지 업로드 요청
      this.$axios
        .post('https://lexcode.co.kr/app/upload-image', formData)
        // .post('https://lexcode.co.kr/dev/upload-image', formData)
        .then(response => {
          // 서버로부터 반환된 이미지의 링크
          console.log(response)
          const imageUrl = response.data
          callback(imageUrl, 'Image alt text')
        })
        .catch(error => {
          console.error('Image upload failed:', error)
          if (error.response.status == 409) {
            alert(error.response.data.detail + " please change the file name.")
          }
        })
      // callback(imageUrl, 'Image alt text')
      console.log('blob:', blob)
      console.log('callback:', callback)
    },
    handleFileChange(event) {
      const files = event.target.files
      this.handleFiles(files)
    },
    handleDragOver(event) {
      event.preventDefault()
      this.isDragging = true
    },
    handleDragLeave() {
      this.isDragging = false
    },
    handleDrop(event) {
      event.preventDefault()
      this.isDragging = false
      const files = event.dataTransfer.files
      this.handleFiles(files)
    },
    handleFiles(files) {
      const maxSize = 500 * 1000 * 1000 // 500MB (byte 단위)
      if (files && files.length > 0) {
        const file = files[0]
        if (file.size > maxSize) {
          alert(this.$t("stories.alert7")) // 파일 크기가 500MB 제한을 초과했습니다.
          return
        }
        // 파일 크기가 제한 내에 있는 경우 추가 처리 로직
        // console.log('파일 크기가 제한 내에 있습니다.')
        // 파일 처리 로직
        this.droppedFile = file
        this.thumbnailFile = file // 수정된 부분
        // console.log(files)
      }
    },
    openFileInput() {
      this.$refs.fileInput.click()
    },
    formatFileSize(size) {
      if (size === 0) return '0 Bytes'
      const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      const i = Math.floor(Math.log2(size) / 10)
      return `${(size / Math.pow(1024, i)).toFixed(2)} ${units[i]}`
    },
    resetFile() {
      this.droppedFile = null
    },
    saveContent() {
      if (confirm(this.$t("stories.alert8"))) { // 저장?
        const content = this.editor.getHTML()
        const formData = new FormData()
        formData.append('title', this.title)
        formData.append('company', this.company)
        formData.append('metadata_title', this.metadata.title);
        formData.append('metadata_keyword', this.metadata.keyword);
        formData.append('metadata_description', this.metadata.description);
        formData.append('locale', this.currentLocale)
        formData.append('author', this.author)
        formData.append('content', content)
        formData.append('thumbnailFile', this.thumbnailFile)
        // console.log(formData, this.title, content, this.thumbnailFile)
        this.$axios
          .post('https://lexcode.co.kr/app/create_post', formData, { // 배포서버
            // .post('https://lexcode.co.kr/dev/create_post', formData, { // 개발서버
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            // console.log(response)
            this.$router.push('/relations/stories/')
            alert(this.$t("stories.alert9")) // 저장! 2024년 화이팅!!
          })
          .catch(error => {
            console.error('failed:', error)
          })
      } else {
        //
      }
    }
  }
}
</script>
<template>
  <div class="relations newsletter-edit">
    <section class="section1">
      <div class="container">
        <p class="cont_title">{{ $t("stories.update_title")/* 뉴스레터 작성 */ }}</p>
        <div class="wrap">
          <fieldset class="title location">
            <label>Location</label>
            <div>
              <span class="description">Korean (한국어)</span>
              <input type="radio" id="locale-ko" name="locale" value="ko" v-model="currentLocale" />
            </div>
            <div>
              <span class="description">English (영어)</span>
              <input type="radio" id="locale-en" name="locale" value="en" v-model="currentLocale" />
            </div>
          </fieldset>
          <fieldset class="title">
            <label>{{ $t("stories.title1")/* 제목 */ }}</label>
            <input type="text" v-model="title" />
          </fieldset>
          <fieldset class="title">
            <label>{{ $t("stories.title2")/* 기업 리포트라면 체크해주세요! */ }} <span class="description">{{ $t("stories.title3")/*
                (기업
                리포트는 뉴스레터 리스트에 노출되지 않습니다.) */}}</span></label>
            <input type="checkbox" v-model="company" />
          </fieldset>
          <fieldset class="title">
            <label>{{ $t("stories.title4")/* 작성자 */ }}</label>
            <input type="text" v-model="author" />
          </fieldset>
          <fieldset class="title">
            <label>META TAG</label>
            <span>Title: </span><input type="text" v-model="metadata.title" />
            <span>Description: </span><input type="text" v-model="metadata.description" />
            <span>Keyword: </span><input type="text" v-model="metadata.keyword" />
          </fieldset>
          <fieldset class="title">
            <label>{{ $t("stories.title5")/* 내용 */ }}</label>
            <div id="editor"></div>
          </fieldset>
          <fieldset class="title">
            <label>{{ $t("stories.title6")/* 썸네일 */ }}</label>
            <div class="drop-area" :class="{ 'drag-over': isDragging, 'isFile': droppedFile != null }"
              @dragover.prevent="handleDragOver" @dragleave="handleDragLeave" @drop="handleDrop">
              <template v-if="droppedFile">
                <div class="file_info">
                  <a :href="droppedFile.url" target="_blank">{{ droppedFile.name }} ({{ formatFileSize(droppedFile.size)
                    }})</a>
                </div>
                <p v-if="droppedFile" @click="resetFile" class="reset-button">X</p>
              </template>
              <template v-else>
                <span>{{ $t("stories.title7")/* 이곳에 파일을 드롭하세요 */ }}</span>
                <p @click="openFileInput" class="plus">+</p>
              </template>
            </div>
            <input type="file" ref="fileInput" style="display: none" @change="handleFileChange" accept="image/*" />
          </fieldset>
        </div>
        <button class="main_btn" @click="saveContent()">저장</button>
      </div>
    </section>
  </div>
</template>
