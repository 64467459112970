<script>
export default {
  props: {
    checklistVal: {}
  },
  data () {
    return {
      step1: true,
      step2: false,
      step3: false,
      calc: {
        question1: '',
        question2: '',
        question3: '',
        question4: '',
        question5: '',
        question6: '',
        question7: '',
        question8: '',
        question9: '',
        question10: ''
      },
      allResult: false
    }
  },
  methods: {
    checklistClose () {
      this.$emit('close:checklistModal', false)
    },
    scrollToTop () {
      this.$refs.popup.scrollTo(0, 0)
    },
    reset () {
      this.$data.calc.question1 = null
      this.$data.calc.question2 = null
      this.$data.calc.question3 = null
      this.$data.calc.question4 = null
      this.$data.calc.question5 = null
      this.$data.calc.question6 = null
      this.$data.calc.question7 = null
      this.$data.calc.question8 = null
      this.$data.calc.question9 = null
      this.$data.calc.question10 = null
      this.$data.step1 = true
      this.$data.step2 = false
      this.$data.step3 = false
      this.scrollToTop()
    },
    allResultToggle () {
      if (this.$data.allResult === false) {
        this.$data.allResult = true
      } else {
        this.$data.allResult = false
      }
    }
  },
  computed: {
    total () {
      const sum = (Number(this.calc.question1) + Number(this.calc.question2) + Number(this.calc.question3)) * Number(this.calc.question4)
      return sum + Number(this.calc.question5) + Number(this.calc.question6) + Number(this.calc.question7) + Number(this.calc.question8) + Number(this.calc.question9) + Number(this.calc.question10)
    }
  }
}
</script>

<template>
  <div class="catxChecklist">
    <p class="close_btn" @click="checklistClose()"><img src="../assets/img/business_cmtx_checklist_closeBtn.png" /></p>
    <div class="quest_wrap" ref="popup">
        <div class="quest_top" v-if="step3 != true">
            <p class="number"><b>{{ step1 == true ? '1' : '2' }}</b> / 2</p>
            <p class="cont_subtitle">{{$t("busi_cl.title")/* 우리회사에 커스텀 자동번역모델이 필요할까? */}}</p>
        </div>
        <transition-group name="fade">
          <template v-if="step1 == true">
              <div class="quest_cont">
                  <p class="t_purple">Q1</p>
                  <p class="question">{{$t("busi_cl.q1_title")/* 현재 번역지원 업무에 외부로 연간 얼마를 지출하시나요? */}}</p>
                  <div class="answer_wrap">
                    <div class="answer">
                      <input type="radio" id="q1_a1" name="q1" value="10" v-model="calc.question1"/>
                      <label class="box" for="q1_a1"><label class="check"></label>{{$t("busi_cl.q1_a1")/* 2,000만 원 이상 */}}</label>
                    </div>
                    <div class="answer">
                      <input type="radio" id="q1_a2" name="q1" value="5" v-model="calc.question1"/>
                      <label class="box" for="q1_a2"><label class="check"></label>{{$t("busi_cl.q1_a2")/* 1,000만 원 이상 */}}</label>
                    </div>
                    <div class="answer">
                      <input type="radio" id="q1_a3" name="q1" value="0" v-model="calc.question1"/>
                      <label class="box" for="q1_a3"><label class="check"></label>{{$t("busi_cl.q1_a3")/* 모름, 없음. */}}</label>
                    </div>
                  </div>
              </div>
              <div class="quest_cont">
                <p class="t_purple">Q2</p>
                <p class="question">{{$t("busi_cl.q2_title")/* 내부에 전담 번역/감수 직원이 있으신가요? */}}</p>
                <div class="answer_wrap">
                  <div class="answer">
                    <input type="radio" id="q2_a1" name="q2" value="10" v-model="calc.question2"/>
                    <label class="box" for="q2_a1"><label class="check"></label>{{$t("busi_cl.q2_a1")/* 1명 이상있다. */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q2_a2" name="q2" value="5" v-model="calc.question2"/>
                    <label class="box" for="q2_a2"><label class="check"></label>{{$t("busi_cl.q2_a2")/* 없지만 필요에 따라 나눈다. */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q2_a3" name="q2" value="0" v-model="calc.question2"/>
                    <label class="box" for="q2_a3"><label class="check"></label>{{$t("busi_cl.q2_a3")/* 없지만 자동번역기를 활용한다. */}}</label>
                  </div>
                </div>
              </div>
              <div class="quest_cont">
                <p class="t_purple">Q3</p>
                <p class="question">{{$t("busi_cl.q3_title")/* 자체적으로 원문-번역문 문서를 보유하고계신가요? */}}</p>
                <div class="answer_wrap">
                  <div class="answer">
                    <input type="radio" id="q3_a1" name="q3" value="10" v-model="calc.question3"/>
                    <label class="box" for="q3_a1"><label class="check"></label>{{$t("busi_cl.q3_a1")/* 1만 페이지 이상 보유 */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q3_a2" name="q3" value="5" v-model="calc.question3"/>
                    <label class="box" for="q3_a2"><label class="check"></label>{{$t("busi_cl.q3_a2")/* 1천 페이지 이상 보유 */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q3_a3" name="q3" value="0" v-model="calc.question3"/>
                    <label class="box" for="q3_a3"><label class="check"></label>{{$t("busi_cl.q3_a3")/* 1페이지 이하이거나 모른다. */}}</label>
                  </div>
                </div>
              </div>
              <div class="quest_cont">
                <p class="t_purple">Q4</p>
                <p class="question">{{$t("busi_cl.q4_title")/* 번역할 내용이 특정 분야/유형 또는 언어쌍에 국한되나요? (예: 영어 의료 문서나 계약서를 한국어로 번역하는 경우가 대부분이다.) */}}</p>
                <div class="answer_wrap">
                  <div class="answer">
                    <input type="radio" id="q4_a1" name="q4" value="2" v-model="calc.question4"/>
                    <label class="box" for="q4_a1"><label class="check"></label>{{$t("busi_cl.q4_a1")/* 그렇다 */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q4_a2" name="q4" value="1" v-model="calc.question4"/>
                    <label class="box" for="q4_a2"><label class="check"></label>{{$t("busi_cl.q4_a2")/* 다소 존재한다. */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q4_a3" name="q4" value="0.5" v-model="calc.question4"/>
                    <label class="box" for="q4_a3"><label class="check"></label>{{$t("busi_cl.q4_a3")/* 그렇지 않다. */}}</label>
                  </div>
                </div>
              </div>
              <div class="quest_cont">
                <p class="t_purple">Q5</p>
                <p class="question">{{$t("busi_cl.q5_title")/* 자동번역 모델의 유무가 소속된 산업군 내 경쟁력 확보를 위해 중요한가요? */}}</p>
                <div class="answer_wrap">
                  <div class="answer">
                    <input type="radio" id="q5_a1" name="q5" value="10" v-model="calc.question5"/>
                    <label class="box" for="q5_a1"><label class="check"></label>{{$t("busi_cl.q5_a1")/* 매우 중요하다. */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q5_a2" name="q5" value="5" v-model="calc.question5"/>
                    <label class="box" for="q5_a2"><label class="check"></label>{{$t("busi_cl.q5_a2")/* 어느정도 중요하다. */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q5_a3" name="q5" value="0" v-model="calc.question5"/>
                    <label class="box" for="q5_a3"><label class="check"></label>{{$t("busi_cl.q5_a3")/* 전혀 중요하지 않다. */}}</label>
                  </div>
                </div>
              </div>
              <div class="btn_wrap flex">
                <p class="cancle">{{$t("busi_cl.q_btn1")/* 자동번역 모델을 구축하려는 가장 큰 이유는 무엇인가요? */}}</p>
                <p class="next" @click="step2 = true, step1 = false, scrollToTop()">{{$t("busi_cl.q_btn2")/* 번역 속도의 단축을 기대한다. */}}</p>
              </div>
          </template>
          <template v-else-if="step2 == true">
              <div class="quest_cont">
                  <p class="t_purple">Q6</p>
                  <p class="question">{{$t("busi_cl.q6_title")/* 번역 비용의 절감을 기대한다. */}}</p>
                  <div class="answer_wrap">
                    <div class="answer">
                      <input type="radio" id="q6_a1" name="q6" value="10" v-model="calc.question6"/>
                      <label class="box" for="q6_a1"><label class="check"></label>{{$t("busi_cl.q6_a1")/* 번역 정확도의 향상을기대한다. */}}</label>
                    </div>
                    <div class="answer">
                      <input type="radio" id="q6_a2" name="q6" value="5" v-model="calc.question6"/>
                      <label class="box" for="q6_a2"><label class="check"></label>{{$t("busi_cl.q6_a2")/* 자동번역 모델을 어떤 용도로 사용할 예정인가요? */}}</label>
                    </div>
                    <div class="answer">
                      <input type="radio" id="q6_a3" name="q6" value="0" v-model="calc.question6"/>
                      <label class="box" for="q6_a3"><label class="check"></label>{{$t("busi_cl.q6_a3")/* 외부에 서비스 또는 다방면으로 활용할 것이다. */}}</label>
                    </div>
                  </div>
              </div>
              <div class="quest_cont">
                <p class="t_purple">Q7</p>
                <p class="question">{{$t("busi_cl.q7_title")/* 내부에서 정보 수집용으로만 사용할 것이다. */}}</p>
                <div class="answer_wrap">
                  <div class="answer">
                    <input type="radio" id="q7_a1" name="q7" value="10" v-model="calc.question7"/>
                    <label class="box" for="q7_a1"><label class="check"></label>{{$t("busi_cl.q7_a1")/* 용도가 아직 정해지지 않았다. */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q7_a2" name="q7" value="5" v-model="calc.question7"/>
                    <label class="box" for="q7_a2"><label class="check"></label>{{$t("busi_cl.q7_a2")/* 번역할 문서에 어느정도 수준의 보안이 필요하신가요? */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q7_a3" name="q7" value="0" v-model="calc.question7"/>
                    <label class="box" for="q7_a3"><label class="check"></label>{{$t("busi_cl.q7_a3")/* 높은보안 - 외부로 유출할 수 없없는 내용이다. */}}</label>
                  </div>
                </div>
              </div>
              <div class="quest_cont">
                <p class="t_purple">Q8</p>
                <p class="question">{{$t("busi_cl.q8_title")/* 보통 수준 - 보안에 민감한 내용과 민감하지 않은 내용이 섞여있다. */}}</p>
                <div class="answer_wrap">
                  <div class="answer">
                    <input type="radio" id="q8_a1" name="q8" value="20" v-model="calc.question8"/>
                    <label class="box" for="q8_a1"><label class="check"></label>{{$t("busi_cl.q8_a1")/* 공개 가능 - 이미 공개되었거나 특별히 보안이 필요하지 않은 내용이다. */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q8_a2" name="q8" value="10" v-model="calc.question8"/>
                    <label class="box" for="q8_a2"><label class="check"></label>{{$t("busi_cl.q8_a2")/* 번역 언어쌍 데이터를 축적할 필요가 있으신가요? */}}</label>
                  </div>
                  <div class="answer special">
                    <input type="radio" id="q8_a3" name="q8" value="0" v-model="calc.question8"/>
                    <label class="box" for="q8_a3"><label class="check"></label><p>{{$t("busi_cl.q8_a3")/* 매우 필요하다. */}}</p></label>
                  </div>
                </div>
              </div>
              <div class="quest_cont">
                <p class="t_purple">Q9</p>
                <p class="question">{{$t("busi_cl.q9_title")/* 모르겠지만 필요할 것 같다. */}}</p>
                <div class="answer_wrap">
                  <div class="answer">
                    <input type="radio" id="q9_a1" name="q9" value="10" v-model="calc.question9"/>
                    <label class="box" for="q9_a1"><label class="check"></label>{{$t("busi_cl.q9_a1")/* 잘 모르겠다, 필요하지 않다. */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q9_a2" name="q9" value="5" v-model="calc.question9"/>
                    <label class="box" for="q9_a2"><label class="check"></label>{{$t("busi_cl.q9_a2")/* 전문 용어 검색, Chat-GPT 등 지식 기반 기능이 필요하신가요? */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q9_a3" name="q9" value="0" v-model="calc.question9"/>
                    <label class="box" for="q9_a3"><label class="check"></label>{{$t("busi_cl.q9_a3")/* 매우 필요하다. */}}</label>

                  </div>
                </div>
              </div>
              <div class="quest_cont">
                <p class="t_purple">Q10</p>
                <p class="question">{{$t("busi_cl.q10_title")/* 가끔 필요하다. */}}</p>
                <div class="answer_wrap">
                  <div class="answer">
                    <input type="radio" id="q10_a1" name="q10" value="10" v-model="calc.question10"/>
                    <label class="box" for="q10_a1"><label class="check"></label>{{$t("busi_cl.q10_a1")/* 필요하지 않다. */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q10_a2" name="q10" value="5" v-model="calc.question10"/>
                    <label class="box" for="q10_a2"><label class="check"></label>{{$t("busi_cl.q10_a2")/* 취소 */}}</label>
                  </div>
                  <div class="answer">
                    <input type="radio" id="q10_a3" name="q10" value="0" v-model="calc.question10"/>
                    <label class="box" for="q10_a3"><label class="check"></label>{{$t("busi_cl.q10_a3")/* 다음 */}}</label>
                  </div>
                </div>
              </div>
              <div class="btn_wrap flex">
                <p class="cancle" @click="step1 = true, step2 = false, scrollToTop()">{{$t("busi_cl.q_btn3")/* 이전 */}}</p>
                <p class="next" @click="step3 = true, step2 = false, scrollToTop()">{{$t("busi_cl.q_btn4")/* 결과 확인하기 */}}</p>
              </div>
          </template>
          <template v-else-if="step3 == true">
            <div class="result_wrap">
              <div class="text_wrap">
                <template v-if="total >= 0 && total < 45">
                  <img src="../assets/img/business_cmtx_checklist_resultCheck_1.png"/>
                  <p class="cont_title">{{$t("busi_cl.result1_title2")/* 아직은 괜찮아요! */}}</p>
                  <p class="cont_description">{{$t("busi_cl.result1_desc")/* 아직은 커스텀 자동번역 모델을 구매할 필요성이 없어 보여요. 지금처럼 필요에 따라 전문 번역 회사나 자동 번역기를 적절하게 활용하시면 좋을 것 같습니다. 렉스클라우드에 접속해서 자동 견적과 ATPE를 활용해 보시는 건 어떨까요? */}}</p>
                </template>
                <template v-else-if="total >= 45 && total < 65">
                  <img src="../assets/img/business_cmtx_checklist_resultCheck_2.png"/>
                  <p class="cont_title">{{$t("busi_cl.result2_title2")/* 조금씩 준비해볼까요? */}}</p>
                  <p class="cont_description">{{$t("busi_cl.result2_desc")/* 지금 당장은 자동번역 모델을 도입하지 않아도 괜찮겠지만, 앞으로 1~2년 내에 자동번역 모델의 필요성이 높아질 것으로 예상됩니다. 지금부터 언어 데이터를 축적해 간다면 추후 더욱 정확한 자동번역 모델을 만들 수 있어요. */}}</p>
                </template>
                <template v-else-if="total >= 65 && total < 80">
                  <img src="../assets/img/business_cmtx_checklist_resultCheck_3.png"/>
                  <p class="cont_title">{{$t("busi_cl.result3_title2")/* 지금이 딱이네요! */}}</p>
                  <p class="cont_description">{{$t("busi_cl.result3_desc")/* 자동번역 모델을 도입하기에 가장 이상적인 시기예요. 비용 절감, 효율 증가, 보안 강화, 정보 자산 축적 등 자동번역 모델을 통해 얻게 될 다양한 가치를 만나보세요! */}}</p>
                </template>
                <template v-else-if="total >= 80">
                  <img src="../assets/img/business_cmtx_checklist_resultCheck_4.png"/>
                  <p class="cont_title">{{$t("busi_cl.result4_title2")/* 반드시 검토하세요! */}}</p>
                  <p class="cont_description">{{$t("busi_cl.result4_desc")/* 자동번역 모델 도입이 매우 시급한 상태예요. 만약 아직 자동번역 모델을 도입하지 않았다면 반드시 검토해 보세요. 자동번역 모델을 통해 비용, 효율성, 경쟁력 등 모든 영역에서 혜택을 얻을 수 있습니다. */}}</p>
                </template>
              </div>
            </div>
            <div class="btn_wrap flex">
              <div @click="allResultToggle()" class="all_result_btn">
                <span>{{$t("busi_cl.result_btn1")/* 모든 테스트 결과보기 */}}</span>
                <img src="../assets/img/sort_arrow.png" :class="{ rotate : allResult }"/>
              </div>
              <div class="right_btn">
                <p class="cancle" @click="reset()">{{$t("busi_cl.result_btn2")/* 진단 테스트 다시하기 */}}</p>
                <p class="next" @click="checklistClose()">{{$t("busi_cl.result_btn3")/* 확인 */}}</p>
              </div>
            </div>
            <div class="all_result_wrap" v-if="allResult == true">
              <div class="all_result_cont">
                <div class="left">
                  <img src="../assets/img/business_cmtx_checklist_resultCheck_1.png"/>
                  <p class="cont_title">{{$t("busi_cl.result1_title")/* 아직은 괜찮아요! */}}</p>
                  <p class="score">{{$t("busi_cl.result_score1")/* 45점 미만 */}}</p>
                </div>
                <div class="text_wrap">{{$t("busi_cl.result1_desc")/* 아직은 커스텀 자동번역 모델을 구매할 필요성이 없어 보여요. 지금처럼 필요에 따라 전문 번역 회사나 자동 번역기를 적절하게 활용하시면 좋을 것 같습니다. 렉스클라우드에 접속해서 자동 견적과 ATPE를 활용해 보시는 건 어떨까요? */}}<br>{{$t("busi_cl.result1_desc2") }}</div>
              </div>
              <div class="all_result_cont">
                <div class="left">
                  <img src="../assets/img/business_cmtx_checklist_resultCheck_2.png"/>
                  <p class="cont_title">{{$t("busi_cl.result2_title")/* 조금씩 준비해볼까요? */}}</p>
                  <p class="score">{{$t("busi_cl.result_score2")/* 45-65점 미만 */}}</p>
                </div>
                <div class="text_wrap">{{$t("busi_cl.result2_desc")/* 지금 당장은 자동번역 모델을 도입하지 않아도 괜찮겠지만, 앞으로 1~2년 내에 자동번역 모델의 필요성이 높아질 것으로 예상됩니다. 지금부터 언어 데이터를 축적해 간다면 추후 더욱 정확한 자동번역 모델을 만들 수 있어요. */}}<br>{{$t("busi_cl.result2_desc2") }}</div>
              </div>
              <div class="all_result_cont">
                <div class="left">
                  <img src="../assets/img/business_cmtx_checklist_resultCheck_3.png"/>
                  <p class="cont_title">{{$t("busi_cl.result3_title")/* 지금이 딱이네요! */}}</p>
                  <p class="score">{{$t("busi_cl.result_score3")/* 65-80점 미만 */}}</p>
                </div>
                <div class="text_wrap">{{$t("busi_cl.result3_desc")/* 자동번역 모델을 도입하기에 가장 이상적인 시기예요. 비용 절감, 효율 증가, 보안 강화, 정보 자산 축적 등 자동번역 모델을 통해 얻게 될 다양한 가치를 만나보세요! */}}<br>{{$t("busi_cl.result3_desc2") }}</div>
              </div>
              <div class="all_result_cont">
                <div class="left">
                  <img src="../assets/img/business_cmtx_checklist_resultCheck_4.png"/>
                  <p class="cont_title">{{$t("busi_cl.result4_title")/* 반드시 검토하세요! */}}</p>
                  <p class="score">{{$t("busi_cl.result_score4")/* 80점 이 */}}</p>
                </div>
                <div class="text_wrap">{{$t("busi_cl.result4_desc")/* 자동번역 모델 도입이 매우 시급한 상태예요. 만약 아직 자동번역 모델을 도입하지 않았다면 반드시 검토해 보세요. 자동번역 모델을 통해 비용, 효율성, 경쟁력 등 모든 영역에서 혜택을 얻을 수 있습니다. */}}<br>{{$t("busi_cl.result4_desc2") }}</div>
              </div>
            </div>
          </template>
      </transition-group>
    </div>
  </div>
</template>
