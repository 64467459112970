<script>
import { ref, reactive, inject, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'businessTC',
  props: {
    window: Object
  },
  setup(props) {
    const { t } = useI18n() // `t` 함수를 `setup`에서 가져옵니다.
    // inject $axios
    const $axios = inject('$axios')
    const { locale } = useI18n()

    // reactive object for form data
    const formData = reactive({
      client_name: null,
      client_contact: null,
      client_email: null,
      details: null,
      selectedIndustries: []
    })

    // industries array
    const industries = [
      { id: 'manufacturing', name: 'busi_gas.industries1_name' /* 제조 */ },
      { id: 'service', name: 'busi_gas.industries2_name' /* 서비스 */ },
      { id: 'finance', name: 'busi_gas.industries3_name' /* 금융 */ },
      { id: 'it', name: 'busi_gas.industries4_name' /* IT */ },
      { id: 'laws', name: 'busi_gas.industries5_name' /* 법률 */ },
      { id: 'etc', name: 'busi_gas.industries6_name' /* 기타 */ }
    ]

    // Email validation method
    const validateEmail = (email) => {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      return emailRegex.test(email)
    }

    // Alert method (assumed to be defined in global context)
    // const alert = (message) => {
    //   // replace with actual alert implementation
    //   console.log(message)
    // }

    // Method to handle form submission
    const sendRequest = () => {
      if (!formData.client_name) {
        alert(t('busi_gas.alert1')) // 성함을 입력해주세요.
      } else if (!formData.client_contact) {
        alert(t('busi_gas.alert2')) // 연락처를 입력해주세요.
      } else if (!formData.client_email) {
        alert(t('busi_gas.alert3')) // 이메일을 입력해주세요.
      } else if (!validateEmail(formData.client_email)) {
        alert(t('busi_gas.alert4')) // 유효한 이메일 주소를 입력해주세요.
      } else {
        const requestData = new FormData()
        requestData.append('client_name', formData.client_name)
        requestData.append('client_contact', formData.client_contact)
        requestData.append('client_email', formData.client_email)
        requestData.append('industry', formData.selectedIndustries)
        requestData.append('details', formData.details)

        $axios.post('https://lexcode.co.kr/app/SMTP_send_email', requestData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            console.log(response)
            alert(t('busi_gas.alert5')) // 문의가 완료되었습니다. 담당자가 확인 후 연락드릴 예정입니다.
            window.location.reload()
          })
          .catch(error => {
            console.error('failed:', error)
          })
      }
    }

    // SEC5
    const firstImageOpacity = ref(1)
    const secondImageOpacity = ref(0)

    const firstText = ref(null)
    const secondText = ref(null)

    const handleScroll = () => {
      if (props.window.width > 768) {
        const secondTextTop = secondText.value.getBoundingClientRect().top
        if (secondTextTop <= 300) {
          firstImageOpacity.value = 0
          secondImageOpacity.value = 1
        } else {
          firstImageOpacity.value = 1
          secondImageOpacity.value = 0
        }
      }
    }

    // SEC6
    const tabList = [
      {
        title: 'busi_gas.sec6_tab_cont1_title', // 프레젠테이션 · IR
        title_img: require('../assets/img/business_tc_section6_title_icon1.png'),
        title_img_on: require('../assets/img/business_tc_section6_title_icon1_on.png'),
        title_img_mob: require('../assets/img/business_tc_section6_title_icon1_mob.png'),
        title_img_mob_on: require('../assets/img/business_tc_section6_title_icon1_mob_on.png'),
        step2_ko: require('../assets/img/business_tc_section6_cont1_2_ko.png'),
        step3_ko: require('../assets/img/business_tc_section6_cont1_3_ko.png'),
        step2_en: require('../assets/img/business_tc_section6_cont1_2_en.png'),
        step3_en: require('../assets/img/business_tc_section6_cont1_3_en.png'),
        description: 'busi_gas.sec6_tab_cont1_description'
      },
      {
        title: 'busi_gas.sec6_tab_cont2_title', // 교육 · HR
        title_img: require('../assets/img/business_tc_section6_title_icon2.png'),
        title_img_on: require('../assets/img/business_tc_section6_title_icon1_on.png'),
        title_img_mob: require('../assets/img/business_tc_section6_title_icon2_mob.png'),
        title_img_mob_on: require('../assets/img/business_tc_section6_title_icon2_mob_on.png'),
        step2_ko: require('../assets/img/business_tc_section6_cont2_2_ko.png'),
        step3_ko: require('../assets/img/business_tc_section6_cont2_3_ko.png'),
        step2_en: require('../assets/img/business_tc_section6_cont2_2_en.png'),
        step3_en: require('../assets/img/business_tc_section6_cont2_3_en.png'),
        description: 'busi_gas.sec6_tab_cont2_description'
      },
      {
        title: 'busi_gas.sec6_tab_cont3_title', // 마케팅
        title_img: require('../assets/img/business_tc_section6_title_icon3.png'),
        title_img_on: require('../assets/img/business_tc_section6_title_icon1_on.png'),
        title_img_mob: require('../assets/img/business_tc_section6_title_icon3_mob.png'),
        title_img_mob_on: require('../assets/img/business_tc_section6_title_icon3_mob_on.png'),
        step2_ko: require('../assets/img/business_tc_section6_cont3_2_ko.png'),
        step3_ko: require('../assets/img/business_tc_section6_cont3_3_ko.png'),
        step2_en: require('../assets/img/business_tc_section6_cont3_2_en.png'),
        step3_en: require('../assets/img/business_tc_section6_cont3_3_en.png'),
        description: 'busi_gas.sec6_tab_cont3_description'
      },
      {
        title: 'busi_gas.sec6_tab_cont4_title', // 언론 · 보도자료
        title_img: require('../assets/img/business_tc_section6_title_icon4.png'),
        title_img_on: require('../assets/img/business_tc_section6_title_icon1_on.png'),
        title_img_mob: require('../assets/img/business_tc_section6_title_icon4_mob.png'),
        title_img_mob_on: require('../assets/img/business_tc_section6_title_icon4_mob_on.png'),
        step2_ko: require('../assets/img/business_tc_section6_cont4_2_ko.png'),
        step3_ko: require('../assets/img/business_tc_section6_cont4_3_ko.png'),
        step2_en: require('../assets/img/business_tc_section6_cont4_2_en.png'),
        step3_en: require('../assets/img/business_tc_section6_cont4_3_en.png'),
        description: 'busi_gas.sec6_tab_cont4_description'
      },
      {
        title: 'busi_gas.sec6_tab_cont5_title', // SNS 콘텐츠
        title_img: require('../assets/img/business_tc_section6_title_icon5.png'),
        title_img_on: require('../assets/img/business_tc_section6_title_icon1_on.png'),
        title_img_mob: require('../assets/img/business_tc_section6_title_icon5_mob.png'),
        title_img_mob_on: require('../assets/img/business_tc_section6_title_icon5_mob_on.png'),
        step2_ko: require('../assets/img/business_tc_section6_cont5_2_ko.png'),
        step3_ko: require('../assets/img/business_tc_section6_cont5_3_ko.png'),
        step2_en: require('../assets/img/business_tc_section6_cont5_2_en.png'),
        step3_en: require('../assets/img/business_tc_section6_cont5_3_en.png'),
        description: 'busi_gas.sec6_tab_cont5_description'
      }
    ]
    const tabListMobile = [
      {
        step_ko_mob: require('../assets/img/business_tc_section6_cont1_2_mo_ko.png'),
        step_en_mob: require('../assets/img/business_tc_section6_cont1_2_mo_en.png'),
        cont_ko_title: require('../assets/img/business_tc_section6_cont1_2_title.png'),
        cont_en_title: require('../assets/img/business_tc_section6_cont1_2_title_en.png'),
        cont_des1: 'busi_gas.sec6_mob_tab_cont1_des1',
        cont_des2: 'busi_gas.sec6_mob_tab_cont1_des2',
      },
      {
        step_ko_mob: require('../assets/img/business_tc_section6_cont2_2_mo_ko.png'),
        step_en_mob: require('../assets/img/business_tc_section6_cont2_2_mo_en.png'),
        cont_ko_title: require('../assets/img/business_tc_section6_cont2_2_title.png'),
        cont_en_title: require('../assets/img/business_tc_section6_cont2_2_title_en.png'),
        cont_des1: 'busi_gas.sec6_mob_tab_cont2_des1',
        cont_des2: 'busi_gas.sec6_mob_tab_cont2_des2',
      },
      {
        step_ko_mob: require('../assets/img/business_tc_section6_cont3_2_mo_ko.png'),
        step_en_mob: require('../assets/img/business_tc_section6_cont3_2_mo_en.png'),
        cont_ko_title: require('../assets/img/business_tc_section6_cont3_2_title.png'),
        cont_en_title: require('../assets/img/business_tc_section6_cont3_2_title_en.png'),
        cont_des1: 'busi_gas.sec6_mob_tab_cont3_des1',
        cont_des2: 'busi_gas.sec6_mob_tab_cont3_des2',
      },
      {
        step_ko_mob: require('../assets/img/business_tc_section6_cont4_2_mo_ko.png'),
        step_en_mob: require('../assets/img/business_tc_section6_cont4_2_mo_en.png'),
        cont_ko_title: require('../assets/img/business_tc_section6_cont4_2_title.png'),
        cont_en_title: require('../assets/img/business_tc_section6_cont4_2_title_en.png'),
        cont_des1: 'busi_gas.sec6_mob_tab_cont4_des1',
        cont_des2: 'busi_gas.sec6_mob_tab_cont4_des2',
      },
      {
        step_ko_mob: require('../assets/img/business_tc_section6_cont5_2_mo_ko.png'),
        step_en_mob: require('../assets/img/business_tc_section6_cont5_2_mo_en.png'),
        cont_ko_title: require('../assets/img/business_tc_section6_cont5_2_title.png'),
        cont_en_title: require('../assets/img/business_tc_section6_cont5_2_title_en.png'),
        cont_des1: 'busi_gas.sec6_mob_tab_cont5_des1',
        cont_des2: 'busi_gas.sec6_mob_tab_cont5_des2',
      }
    ]

    const tabList2 = [
      {
        title: 'busi_gas.sec6_cont2_title1', // <span>정확도</span>를 어떻게 높였을까요?
        tab: 'busi_gas.sec6_cont2_tab1', // 번역 엔진 최적화
        cont_title: 'busi_gas.sec6_cont2_tab_title1', // 번역 엔진 최적화
        cont_description: 'busi_gas.sec6_cont2_tab_description1', // 번역 엔진 최적화
        img_ko: require('../assets/img/business_tc_section6_2_cont1_ko2.png'),
        img_en: require('../assets/img/business_tc_section6_2_cont1_en2.png'),
        img_ko_mob: require('../assets/img/business_tc_section6_2_cont1_mob_ko.png'),
        class: 'tab1'
      },
      {
        title: 'busi_gas.sec6_cont2_title2', // <span>정확도</span>를 어떻게 높였을까요?
        tab: 'busi_gas.sec6_cont2_tab2', // 번역 엔진 최적화
        cont_title: 'busi_gas.sec6_cont2_tab_title2', // 번역 엔진 최적화
        cont_description: 'busi_gas.sec6_cont2_tab_description2', // 번역 엔진 최적화
        img_ko: require('../assets/img/business_tc_section6_2_cont2_ko2.png'),
        img_en: require('../assets/img/business_tc_section6_2_cont2_en2.png'),
        img_ko_mob: require('../assets/img/business_tc_section6_2_cont2_mob_ko.png'),
        class: 'tab2'
      },
      {
        title: 'busi_gas.sec6_cont2_title3', // <span>정확도</span>를 어떻게 높였을까요?
        tab: 'busi_gas.sec6_cont2_tab3', // 번역 엔진 최적화
        cont_title: 'busi_gas.sec6_cont2_tab_title3', // 번역 엔진 최적화
        cont_description: 'busi_gas.sec6_cont2_tab_description3', // 번역 엔진 최적화
        img_ko: require('../assets/img/business_tc_section6_2_cont3_ko2.png'),
        img_en: require('../assets/img/business_tc_section6_2_cont3_en2.png'),
        img_ko_mob: require('../assets/img/business_tc_section6_2_cont3_mob_ko.png'),
        class: 'tab3'
      }
    ]
    const activeTab = ref(0)
    const activeTab2 = ref(0)

    // Refs for DOM elements and state management
    const scrollContainer = ref(null);
    const floatBtnArea = ref(null);
    const showPrev = ref(false);
    const showNext = ref(true);

    // Function to check scroll position and update button visibility
    const checkScroll = () => {
      const container = scrollContainer.value;
      const floatArea = floatBtnArea.value;
      if (!container || !floatArea) return; // Ensure both refs are not null

      const containerRect = floatArea.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const isFullyInView =
        containerRect.top >= 0 &&
        containerRect.bottom <= viewportHeight;

      const isInSection =
        containerRect.top <= window.innerHeight && containerRect.bottom >= 0;

      showPrev.value = container.scrollLeft > 0 && isInSection && isFullyInView;
      showNext.value =
        container.scrollLeft < container.scrollWidth - container.clientWidth &&
        isInSection && isFullyInView;
    };

    const scroll = (direction) => {
      const container = scrollContainer.value;
      const scrollAmount = container.clientWidth * 0.73;

      container.scrollLeft += direction * scrollAmount;
      setTimeout(checkScroll, 300); // 스크롤 후 위치 체크
    };


    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
      window.addEventListener('scroll', checkScroll);
      if (props.window.width < 769) {
        showNext.value = false;
        window.addEventListener('scroll', checkScroll);
      }
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('scroll', checkScroll)

    })

    // return reactive properties and methods
    return {
      formData,
      industries,
      validateEmail,
      sendRequest,
      locale,
      firstImageOpacity,
      secondImageOpacity,
      firstText,
      secondText,
      tabList,
      tabList2,
      activeTab,
      activeTab2,
      tabListMobile,
      scrollContainer,
      showPrev,
      showNext,
      scroll,
      checkScroll,
      floatBtnArea
    }
  }
}
</script>

<template>
  <div id="businessTC" class="business tc">
    <section class="section1">
      <div class="container flex">
        <div class="left">
          <div class="left_cont">
            <img src="../assets/img/business_tc_section1_img1.png" />
          </div>
        </div>
        <div class="right">
          <div class="right_cont">
            <div class="flex">
              <p class="business_title">Business</p>
              <p class="business_num"><span>02 </span>— 05</p>
            </div>
            <p class="subtitle">{{ $t("busi_gas.sec1_subTitle")/* 나만의 생성형 AI. */ }}</p>
            <h1>TransCreation<br>{{ $t("busi_gas.sec1_title")/* 생성형 AI & NLP 모델 */ }}</h1>
            <a href="https://tc.lexcode.com" class="main_btn">{{ $t("busi_lc.sec1_btn")/* 생성형 AI & NLP 모델 */ }}</a>
          </div>
        </div>
      </div>
    </section>
    <section class="section2">
      <div class="container">
        <div class="text_wrap t_center">
          <p class="cont_subtitle">Multilingual Generative AI Solution</p>
          <p class="cont_title">{{ $t("busi_gas.sec2_contTitle")/* 언어 전문가와 인공지능의 협업으로 완성한 */ }}</p>
          <p class="cont_title">{{ $t("busi_gas.sec2_contTitle2")/* 다국어 생성형 인공지능 */ }}</p>
          <p class="cont_description">{{ $t("busi_gas.sec2_desc")/* 트랜스크리에이션의 생성형 AI는 다양한 ...
            */}}<br />{{ $t("busi_gas.sec2_desc2")/* 미래의 글로벌 고객을 위한 다국어 콘텐츠 ... */ }}</p>
        </div>
      </div>
    </section>
    <section class="section3">
      <template v-if="window.width > 768">
        <img v-if="locale === 'ko'" src="../assets/img/business_tc_section3_img1_ko.png" />
        <img v-if="locale === 'en'" src="../assets/img/business_tc_section3_img1_en.png" />
      </template>
      <template v-else>
        <img v-if="locale === 'ko'" src="../assets/img/business_tc_section3_img1_mo_ko.png" />
        <img v-if="locale === 'en'" src="../assets/img/business_tc_section3_img1_mo_en.png" />
      </template>
    </section>
    <section class="section4">
      <div class="container">
        <div class="text_wrap t_center">
          <p class="cont_title t_purple">TransCreation</p>
          <p class="cont_title">{{ $t("busi_gas.sec4_title1")/* 번역과 다른 점은 무엇일까요? */ }}</p>
        </div>
        <div class="sec4_cont_wrap">
          <div class="sec4_cont cont1" data-aos="fade-down" data-aos-duration="500">
            <div class="text_wrap">
              <p class="cont_subtitle t_purple">{{ $t("busi_gas.sec4_cont1_subtitle")/* 자동번역 및 에디팅 */ }}</p>
              <p class="cont_title">{{ $t("busi_gas.sec4_cont1_title")/* AI 자동번역과 용어집을 */ }}<br />{{
                $t("busi_gas.sec4_cont1_title2")/* 직접 검토하고, 편집하세요. */ }}</p>
            </div>
            <p class="cont_description">{{ $t("busi_gas.sec4_cont1_des") /* 간편하게 웹에서 파일을 올리고... */ }}<br />{{
              $t("busi_gas.sec4_cont1_des2") /* 언제든 편집하고, 다운로드하여... */ }}<br />{{ $t("busi_gas.sec4_cont1_des3") /* 언제든
              편집하고, 다운로드하여... */ }}</p>
            <template v-if="window.width > 768">
              <video v-if="locale === 'ko'" autoplay="autoplay" muted="muted" loop="loop" loading="lazy">
                <source src="../assets/img/business_tc_section4_img1-1_ko.mp4" type="video/mp4">
              </video>
              <video v-if="locale === 'en'" autoplay="autoplay" muted="muted" loop="loop" loading="lazy">
                <source src="../assets/img/business_tc_section4_img1-1_en.mp4" type="video/mp4">
              </video>
            </template>
            <template v-else>
              <video v-if="locale === 'ko'" autoplay="autoplay" muted="muted" loop="loop" loading="lazy">
                <source src="../assets/img/business_tc_section4_img1_mo_ko.mp4" type="video/mp4">
              </video>
              <video v-if="locale === 'en'" autoplay="autoplay" muted="muted" loop="loop" loading="lazy">
                <source src="../assets/img/business_tc_section4_img1_mo_en.mp4" type="video/mp4">
              </video>
            </template>
          </div>
          <div class="sec4_cont_flex">
            <div class="sec4_cont cont2" data-aos="fade-down" data-aos-duration="500">
              <div class="text_wrap">
                <p class="cont_subtitle t_blue">{{ $t("busi_gas.sec4_cont2_subtitle")/* 문서 서식 유지 */ }}</p>
                <p class="cont_title">{{ $t("busi_gas.sec4_cont2_title")/* 복잡한 서식을 유지하면서 */ }}<br />{{
                  $t("busi_gas.sec4_cont2_title2")/* 원본과 같은 포맷으로 번역하세요. */ }}</p>
              </div>
              <p class="cont_description">{{ $t("busi_gas.sec4_cont2_des") /* .docx, .xlsx, .pptx, .pdf 등 다양한 형식... */
                }}<br />{{
                  $t("busi_gas.sec4_cont2_des2") /* 그대로 번역할 수 있습니다. */ }}</p>
              <video v-if="locale === 'ko'" autoplay="autoplay" muted="muted" loop="loop" loading="lazy">
                <source src="../assets/img/business_tc_section4_img2_ko.mp4" type="video/mp4">
              </video>
              <video v-if="locale === 'en'" autoplay="autoplay" muted="muted" loop="loop" loading="lazy">
                <source src="../assets/img/business_tc_section4_img2_en.mp4" type="video/mp4">
              </video>
            </div>
            <div class="sec4_cont cont3" data-aos="fade-down" data-aos-duration="500" data-aos-delay="400">
              <div class="text_wrap">
                <p class="cont_subtitle t_white">{{ $t("busi_gas.sec4_cont3_subtitle")/* 크리에이션 */ }}</p>
                <p class="cont_title t_white">{{ $t("busi_gas.sec4_cont3_title")/* 번역의 다음 단계인 최종 콘텐츠까지, */ }}<br />{{
                  $t("busi_gas.sec4_cont3_title2")/* 다양한 스타일을 자동 생성하세요. */ }}</p>
              </div>
              <p class="cont_description t_white">{{ $t("busi_gas.sec4_cont3_des") /* 하나의 문서를 10가지 스타일... */ }}<br />{{
                $t("busi_gas.sec4_cont3_des2") /* 맞춤형 콘텐츠를 창작할 수 있습니다. */ }}</p>
              <template v-if="window.width > 768">
                <img v-if="locale === 'ko'" src="../assets/img/business_tc_section4_img3_ko.png" />
                <img v-if="locale === 'en'" src="../assets/img/business_tc_section4_img3_en.png" />
              </template>
              <template v-else>
                <img v-if="locale === 'ko'" src="../assets/img/business_tc_section4_img3_mo_ko.png" />
                <img v-if="locale === 'en'" src="../assets/img/business_tc_section4_img3_mo_en.png" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section5 t_white">
      <div class="container">
        <p class="cont_title t_center">{{ $t("busi_gas.sec5_cont1_title") /* AI를 활용해 효율을 더하고, */ }}<br />{{
          $t("busi_gas.sec5_cont1_title2") /* 비용은 줄어드는 번역 프로세스 */ }}</p>
        <div class="sec5_cont_wrap" v-if="window.width > 768">
          <div class="text_wrap">
            <div class="text_cont" ref="firstText">
              <img src="../assets/img/business_tc_section5_img1.png" />
              <p class="cont_title">{{ $t("busi_gas.sec5_cont2_subtitle") /* 바로 확인하는 실시간 문서 번역 */ }}<br />{{
                $t("busi_gas.sec5_cont2_subtitle2") /* 바로 확인하는 실시간 문서 번역 */ }}</p>
              <p class="cont_description">{{ $t("busi_gas.sec5_cont2_description") /* 누구나 손쉽게 파일을 올리고... */ }}<br />{{
                $t("busi_gas.sec5_cont2_description2") /* 누구나 손쉽게 파일을 올리고... */ }}</p>
            </div>
            <div class="text_cont" ref="secondText">
              <img src="../assets/img/business_tc_section5_img2.png" />
              <p class="cont_title">{{ $t("busi_gas.sec5_cont3_subtitle") /* 바로 확인하는 실시간 문서 번역 */ }}<br />{{
                $t("busi_gas.sec5_cont3_subtitle2") /* 바로 확인하는 실시간 문서 번역 */ }}</p>
              <p class="cont_description">{{ $t("busi_gas.sec5_cont3_description") /* 누구나 손쉽게 파일을 올리고... */ }}<br />{{
                $t("busi_gas.sec5_cont3_description2") /* 바로 확인하는 실시간 문서 번역 */ }}</p>
            </div>
          </div>
          <div class="img_wrap">
            <div :style="{ opacity: firstImageOpacity }">
              <img v-if="locale === 'ko'" src="../assets/img/business_tc_section5_img3_ko.png" />
              <img v-if="locale === 'en'" src="../assets/img/business_tc_section5_img3_en.png" />
            </div>
            <div :style="{ opacity: secondImageOpacity }">
              <img v-if="locale === 'ko'" src="../assets/img/business_tc_section5_img4_ko.png" />
              <img v-if="locale === 'en'" src="../assets/img/business_tc_section5_img4_en.png" />
            </div>
          </div>
        </div>
        <div class="sec5_cont_wrap" v-else>
          <div class="text_wrap">
            <div class="text_cont">
              <img v-if="locale === 'ko'" src="../assets/img/business_tc_section5_img3_ko.png" />
              <img v-if="locale === 'en'" src="../assets/img/business_tc_section5_img3_en.png" />
              <img src="../assets/img/business_tc_section5_img1.png" />
              <p class="cont_title">{{ $t("busi_gas.sec5_cont2_subtitle") /* 바로 확인하는 실시간 문서 번역 */ }} {{
                $t("busi_gas.sec5_cont2_subtitle2") /* 바로 확인하는 실시간 문서 번역 */ }}</p>
              <p class="cont_description">{{ $t("busi_gas.sec5_cont2_description") /* 누구나 손쉽게 파일을 올리고... */ }}<br />{{
                $t("busi_gas.sec5_cont2_description2") /* 누구나 손쉽게 파일을 올리고... */ }}</p>
            </div>
            <div class="text_cont">
              <img v-if="locale === 'ko'" src="../assets/img/business_tc_section5_img4_ko.png" />
              <img v-if="locale === 'en'" src="../assets/img/business_tc_section5_img4_en.png" />
              <img src="../assets/img/business_tc_section5_img2.png" />
              <p class="cont_title">{{ $t("busi_gas.sec5_cont3_subtitle") /* 바로 확인하는 실시간 문서 번역 */ }} {{
                $t("busi_gas.sec5_cont3_subtitle2") /* 바로 확인하는 실시간 문서 번역 */ }}</p>
              <p class="cont_description">{{ $t("busi_gas.sec5_cont3_description") /* 누구나 손쉽게 파일을 올리고... */ }}<br />{{
                $t("busi_gas.sec5_cont3_description2") /* 바로 확인하는 실시간 문서 번역 */ }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section6 t_center">
      <div class="container">
        <p class="cont_title">{{ $t("busi_gas.sec6_cont1_title") /* 간편하고, 빠르게. */ }}<br />{{
          $t("busi_gas.sec6_cont1_title2") /* 여러분의 업무에 마음껏 활용해 보세요. */ }}</p>
        <div class="sec6_cont_wrap">
          <div class="mobile_tab_wrap">
            <div class="sec6_tab_wrap">
              <div v-for="(tab, index) in tabList" :key="index" @click="activeTab = index"
                :class="{ 'active': activeTab === index }" class="tab">
                <img v-if="window.width > 768" :src="activeTab === index ? tab.title_img_on : tab.title_img"
                  alt="tab icon">
                <img v-else :src="activeTab === index ? tab.title_img_mob_on : tab.title_img_mob" alt="tab icon">
                <p>{{ $t(tab.title) }}</p>
              </div>
            </div>
          </div>
          <div v-if="window.width > 768">
            <div class="tab-content">
              <div class="step1">
                <img v-if="locale === 'ko'" src="../assets/img/business_tc_section6_cont_ko.png" />
                <img v-if="locale === 'en'" src="../assets/img/business_tc_section6_cont_en.png" />
                <p><span>1</span>{{ $t("busi_gas.sec6_tab_cont_step1") /* 연설문 원본 */ }}</p>
              </div>
              <img class="arrow" src="../assets/img/business_tc_section6_arrow.png" />
              <div class="step2">
                <img v-if="locale === 'ko'" :src="tabList[activeTab].step2_ko" alt="step 2 image ko">
                <img v-if="locale === 'en'" :src="tabList[activeTab].step2_en" alt="step 2 image en">
                <p><span>2</span>{{ $t("busi_gas.sec6_tab_cont_step2") /* 크리에이션 생성본 */ }} - {{
                  $t(tabList[activeTab].description) }}</p>
              </div>
              <img class="arrow" src="../assets/img/business_tc_section6_arrow.png" />
              <div class="step3">
                <img v-if="locale === 'ko'" :src="tabList[activeTab].step3_ko" alt="step 3 image ko">
                <img v-if="locale === 'en'" :src="tabList[activeTab].step3_en" alt="step 3 image en">
                <p><span>3</span>{{ $t("busi_gas.sec6_tab_cont_step3") /* 활용 사례 (예시) */ }}</p>
              </div>
            </div>
            <p class="t_right">{{ $t("busi_gas.sec6_tab_cont_notice") /* *위 내용은 AI로 생성한 콘텐츠이며... */ }}</p>
          </div>
        </div>
      </div>
      <template v-if="window.width < 769">
        <div class="sec6_mob_cont1_wrap" ref="scrollContainer" @scroll="checkScroll">
          <p v-if="showPrev" class="float prev" @click="scroll(-1)">
            <img src="../assets/img/chevron-right.png"/>
          </p>
          <div class="tab-content" ref="floatBtnArea">
            <img v-if="locale === 'ko'" :src="tabListMobile[activeTab].step_ko_mob" />
            <img v-if="locale === 'en'" :src="tabListMobile[activeTab].step_en_mob" />
          </div>
          <p v-if="showNext" class="float next" @click="scroll(1)"><img src="../assets/img/chevron-right.png"></p>
        </div>
        <div class="tab-content_description">
          <p class=" cont_title">
            <img v-if="locale === 'ko'" :src="tabListMobile[activeTab].cont_ko_title" />
            <img v-if="locale === 'en'" :src="tabListMobile[activeTab].cont_en_title" />
          </p>
          <div class="flex">
            <img src="../assets/img/check.png" />
            <p class="cont_description">{{ $t(tabListMobile[activeTab].cont_des1) }}</p>
          </div>
          <div class="flex">
            <img src="../assets/img/check.png" />
            <p class="cont_description">{{ $t(tabListMobile[activeTab].cont_des2) }}</p>
          </div>
        </div>
        <p class="tab-content_description_2">{{ $t("busi_gas.sec6_tab_cont_notice") /* *위 내용은 AI로 생성한 콘텐츠이며... */ }}</p>
      </template>
      <!-- SEC 6-2 -->
      <div class="container">
        <p class="cont_title">{{ $t("busi_gas.sec6_cont2_title") /* 간편하고, 빠르게. */ }}<br />
          <span v-html='$t(tabList2[activeTab2].title)'></span>
        </p>
        <div class="sec6_cont_wrap sec6_2">
          <div class="sec6_tab_wrap">
            <div v-for="(tab, index) in tabList2" :key="index" @click="activeTab2 = index"
              :class="{ 'active': activeTab2 === index }" class="tab">
              <p>{{ $t(tab.tab) }}</p>
            </div>
          </div>
          <div class="tab-content">
            <div class="left">
              <div class="text_wrap">
                <p>{{ $t(tabList2[activeTab2].cont_title) }}</p>
                <p>{{ $t(tabList2[activeTab2].cont_description) }}</p>
                <p class="footnote" v-if="activeTab2 === 2">
                  {{ $t("busi_gas.sec6_cont2_tab_footnote1")/* ¹ CoT(Chain of Thoughts... */ }}<br />
                  {{ $t("busi_gas.sec6_cont2_tab_footnote2")/* ² Zero-shot(제로샷).... */ }}<br />
                  {{ $t("busi_gas.sec6_cont2_tab_footnote3")/* ³ Few-shot(퓨샷)... */ }}
                </p>
              </div>
            </div>
            <div class="right" :class="tabList2[activeTab2].class">
              <template v-if="window.width > 768">
                <img v-if="locale === 'ko'" :src="tabList2[activeTab2].img_ko" alt="step 2 image ko">
                <img v-if="locale === 'en'" :src="tabList2[activeTab2].img_en" alt="step 2 image en">
              </template>
              <template v-else>
                <img v-if="locale === 'ko'" :src="tabList2[activeTab2].img_ko" alt="step 2 image ko">
                <img v-if="locale === 'en'" :src="tabList2[activeTab2].img_en" alt="step 2 image ko">
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section7">
      <div class="container">
        <div class="text_wrap t_center">
          <p class="cont_subtitle">Postediting & Copyediting</p>
          <img src="../assets/img/business_tc_section7_cont1.png" />
          <p class="cont_title">{{ $t("busi_gas.sec7_cont_title")/* 전문가 감수 */ }}</p>
          <p class="cont_description">{{ $t("busi_gas.sec7_cont_subtitle")/* 더욱 완성도 높은 번역문이 필요하다면, .. */ }}</p>
        </div>
        <div class="sec7_cont_wrap">
          <div class="sec7_cont" data-aos="fade-down" data-aos-duration="500">
            <p class="cont_subtitle">✏️ {{ $t("busi_gas.sec7_cont1_title") }}</p>
            <p class="cont_description"> {{ $t("busi_gas.sec7_cont1_description") }}</p>
            <img v-if="locale === 'ko'" src="../assets/img/business_tc_section7_cont2_ko.png" alt="step 2 image ko">
            <img v-if="locale === 'en'" src="../assets/img/business_tc_section7_cont2_en.png" alt="step 2 image en">
          </div>
          <div class="sec7_cont" data-aos="fade-down" data-aos-duration="500" data-aos-delay="400">
            <p class="cont_subtitle">📋 {{ $t("busi_gas.sec7_cont2_title") }}</p>
            <p class="cont_description"> {{ $t("busi_gas.sec7_cont2_description") }}</p>
            <img v-if="locale === 'ko'" src="../assets/img/business_tc_section7_cont3_ko.png" alt="step 2 image ko">
            <img v-if="locale === 'en'" src="../assets/img/business_tc_section7_cont3_en.png" alt="step 2 image en">
          </div>
        </div>
      </div>
    </section>
    <section class="section8" data-aos="fade-down" data-aos-duration="500">
      <div class="container t_center">
        <p class="cont_title">{{ $t("busi_gas.sec8_cont_title") }}</p>
        <div class="sec8_cont_wrap">
          <div class="sec8_cont">
            <div class="img_wrap"><img src="../assets/img/business_tc_section8_cont1.png" /></div>
            <p class="cont_subtitle">{{ $t("busi_gas.sec8_cont1_title") }}</p>
            <p class="cont_description">{{ $t("busi_gas.sec8_cont1_description") }}</p>
          </div>
          <div class="sec8_cont">
            <div class="img_wrap"><img src="../assets/img/business_tc_section8_cont2.png" /></div>
            <p class="cont_subtitle">{{ $t("busi_gas.sec8_cont2_title") }}</p>
            <p class="cont_description">{{ $t("busi_gas.sec8_cont2_description") }}</p>
          </div>
          <div class="sec8_cont">
            <div class="img_wrap"><img src="../assets/img/business_tc_section8_cont3.png" /></div>
            <p class="cont_subtitle">{{ $t("busi_gas.sec8_cont3_title") }}</p>
            <p class="cont_description">{{ $t("busi_gas.sec8_cont3_description") }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section9">
      <template v-if="window.width > 768">
        <div class="container">
          <div class="text_wrap">
            <p class="cont_title" v-html='$t("busi_gas.sec9_title")'></p>
            <!-- <a href="#" class="cont_btn">{{ $t("busi_gas.sec9_btn") }}</a> -->
          </div>
          <div class="sec9_cont_wrap">
            <div class="sec9_left_cont">
              <p class="cont_title t_white">{{ $t("busi_gas.sec9_contTitle") }}</p>
              <p class="cont_description">{{ $t("busi_gas.sec9_desc") }}</p>
            </div>
            <div class="sec9_right_cont">
              <p class="cont_title">{{ $t("busi_gas.sec9_contactTtl")/* 서비스 문의하기 */ }}</p>
              <div class="input_wrap">
                <div class="half">
                  <fieldset>
                    <label>{{ $t("busi_gas.sec9_contactName")/* 성함 */ }} *</label>
                    <input type="text" required v-model="formData.client_name" :placeholder='$t("busi_gas.alert1")' />
                  </fieldset>
                  <fieldset>
                    <label>{{ $t("busi_gas.sec9_contactNum")/* 연락처 */ }} *</label>
                    <input type="text" v-model="formData.client_contact" :placeholder='$t("busi_gas.alert2")'
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                  </fieldset>
                </div>
                <fieldset>
                  <label>{{ $t("busi_gas.sec9_contactEmail")/* 이메일 */ }} *</label>
                  <input type="email" required v-model="formData.client_email" :placeholder='$t("busi_gas.alert3")' />
                </fieldset>
                <fieldset>
                  <label>{{ $t("busi_gas.sec9_contactInd")/* 업종 */ }}</label>
                  <div class="checkbox_wrap">
                    <div class="checkbox" v-for="industry in industries" :key="industry.id">
                      <input type="checkbox" :value="$t(industry.name)" :id="industry.id" :name="$t(industry.name)"
                        v-model="formData.selectedIndustries">
                      <label :for="industry.id">{{ $t(industry.name) }}</label>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <label>{{ $t("busi_gas.sec9_contactDesc")/* 문의사항 */ }}</label>
                  <textarea rows="5" v-model="formData.details"
                    :placeholder="`${$t('busi_gas.placeholder1')}\n${$t('busi_gas.placeholder2')}\n${$t('busi_gas.placeholder3')}`"></textarea>
                </fieldset>
              </div>
              <p class="main_btn" @click="sendRequest()">{{ $t("busi_gas.sec9_contactSend")/* 문의하기 */ }}<span>→</span>
              </p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text_wrap">
          <p class="cont_title" v-html='$t("busi_gas.sec9_title")'></p>
          <!-- <a href="#" class="cont_btn">{{ $t("busi_gas.sec9_btn") }}</a> -->
        </div>
        <div class="sec9_cont_wrap">
          <div class="sec9_left_cont">
            <p class="cont_title t_white">{{ $t("busi_gas.sec9_contTitle") }}</p>
            <p class="cont_description">{{ $t("busi_gas.sec9_desc") }}</p>
          </div>
          <div class="sec9_right_cont">
            <p class="cont_title">{{ $t("busi_gas.sec9_contactTtl")/* 서비스 문의하기 */ }}</p>
            <div class="input_wrap">
              <div class="half">
                <fieldset>
                  <label>{{ $t("busi_gas.sec9_contactName")/* 성함 */ }} *</label>
                  <input type="text" required v-model="formData.client_name" :placeholder='$t("busi_gas.alert1")' />
                </fieldset>
                <fieldset>
                  <label>{{ $t("busi_gas.sec9_contactNum")/* 연락처 */ }} *</label>
                  <input type="text" v-model="formData.client_contact"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    :placeholder='$t("busi_gas.alert2")' />
                </fieldset>
              </div>
              <fieldset>
                <label>{{ $t("busi_gas.sec9_contactEmail")/* 이메일 */ }} *</label>
                <input type="email" required v-model="formData.client_email" :placeholder='$t("busi_gas.alert3")' />
              </fieldset>
              <fieldset>
                <label>{{ $t("busi_gas.sec9_contactInd")/* 업종 */ }}</label>
                <div class="checkbox_wrap">
                  <div class="checkbox" v-for="industry in industries" :key="industry.id">
                    <input type="checkbox" :value="$t(industry.name)" :id="industry.id" :name="$t(industry.name)"
                      v-model="formData.selectedIndustries">
                    <label :for="industry.id">{{ $t(industry.name) }}</label>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <label>{{ $t("busi_gas.sec9_contactDesc")/* 문의사항 */ }}</label>
                <textarea rows="5" v-model="formData.details" :placeholder='$t("busi_gas.placeholder")'></textarea>
              </fieldset>
            </div>
            <p class="main_btn" @click="sendRequest()">{{ $t("busi_gas.sec9_contactSend")/* 문의하기 */ }}<span>→</span></p>
          </div>
        </div>
      </template>
    </section>
    <section class="section10">
      <div class="text_wrap t_center t_white">
        <p class="cont_title">{{ $t("busi_gas.sec10_title") }}</p>
        <p class="cont_description">{{ $t("busi_gas.sec10_subtitle") }}</p>
        <a href="https://tc.lexcode.com" target="_blank" class="main_btn">{{
          $t("busi_gas.sec10_btn") }}<span>→</span></a>
      </div>
    </section>
    <div class="header_bg"></div>
  </div>
</template>
